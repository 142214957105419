import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ClassesApi } from 'src/apis/classes'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IStudentClasstList } from 'src/type/students'
import TransferStudentTable from './TransferStudentTable'

interface IProps {
  open: boolean
  setOpen: any
  type: 'STUDENT'
  getClassStudents: () => void
  transferFromId?: string
}
const ModalTransfer = ({ open, setOpen, type, getClassStudents, transferFromId }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [studentsState, setStudentsState] = useState<IStudentClasstList>()
  const [transferToId, setTransferToId] = useState<string | null>(null)
  const { id: classId } = useParams()
  const getParams = (
    text: string,
    status: string,
    sortType: string,
    fromDate?: any,
    toDate?: any
  ) => ({
    text,
    status,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
  })

  const queryParams = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchListStudents = (
    type: 'STUDENT',
    page_index?: number,
    page_size?: number,
    params?: any
  ) => {
    if (typeof type === 'string') {
      ;(async () => {
        try {
          const response = await ClassesApi.getClassStudent({
            class_id: classId,
            page_index: page_index || 1,
            page_size: page_size || 10,
            params: {
              ...params,
              for_transferring: transferFromId,
            },
          })
          setStudentsState(response?.data)
          setLoading(true)
        } catch (error) {
        } finally {
          setLoading(false)
        }
      })()
    }
  }

  const transferStudent = async (data: { transferFromId: string; transferToId: string }) => {
    const res =
      classId &&
      (await ClassesApi.transferStudentInclass(classId, data.transferFromId, data.transferToId))

    return res
  }

  const { confirm, contextHolder } = useConfirm()

  const handleClose = () => {
    setOpen(false)
    setTransferToId(null)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (transferFromId && transferToId) {
        await transferStudent({
          transferFromId: transferFromId,
          transferToId: transferToId,
        })
        await getClassStudents()
        handleClose()
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setTransferToId(null)
  }, [])

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Transfer to</div>
          <div className='d-flex justify-content-between align-items-center'>
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Confirm'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              // Disable when both are falsy
              disabled={!(!!transferFromId && !!transferToId)}
              loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <TransferStudentTable
            studentsState={studentsState}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchListUsers={fetchListStudents}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            type={type}
            currentId={transferToId}
            setCurrentId={setTransferToId}
          />
        </div>
      </SappModal>
    </>
  )
}
export default ModalTransfer
