import React, { useEffect, useMemo, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { StoryAPI } from 'src/apis/story'
import SappTable from 'src/components/base/SappTable'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import { PageLink } from 'src/constants'
import { ITopic } from 'src/type/question-bank'
import { IStory } from 'src/type/story'
import { htmlToRaw } from 'src/utils'
import { normalizeLikeParam } from 'src/utils/string'
import { VALIDATE_MAX_NUMBER } from 'src/constants'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'

const headers = [
  {
    label: 'List Item Set',
    key: 'topics',
    className: 'min-w-250px',
  },
  {
    label: 'Number of Questions',
    key: 'questions',
    className: 'min-w-90px fw-bold',
  },
  {
    label: 'Action',
    key: 'actions',
    className: 'min-w-90px fw-bold text-end',
  },
]

type Props = {
  title: string
  story?: IStory
  setStory: (value: React.SetStateAction<IStory | undefined>) => void
  confirm: (option: any) => void
  handleChooseStories: () => void
  error: FieldError | undefined
  disabled?: boolean
}

const StoriesTable = ({ story, handleChooseStories, error, disabled }: Props) => {
  const [filter, setFilter] = useState({
    search: '',
    currentPage: 1,
    pageSize: 10,
  })

  const [filteredTopics, setFilteredTopics] = useState<Partial<ITopic>[]>([])
  const [storyState, setStoryState] = useState<IStory & { topics: any[] }>()

  useEffect(() => {
    if (filter.search !== undefined && typeof filter.search === 'string') {
      const filtered =
        storyState?.topics?.filter((t) =>
          normalizeLikeParam(t.question_topic?.name)
            ?.toLowerCase()
            .trim()
            .includes(normalizeLikeParam(filter.search))
        ) || []
      setFilteredTopics(filtered)
    } else {
      setFilteredTopics(storyState?.topics || [])
    }
  }, [storyState, filter.search])

  useEffect(() => {
    if (story?.id) {
      ;(async () => {
        try {
          const response = await StoryAPI.detail(story.id, 1, VALIDATE_MAX_NUMBER)
          setStoryState(response.data.data as any)
        } catch (error) {
          setStoryState(undefined)
        }
      })()
    }
  }, [story])
  const currentTopics = useMemo(() => {
    if (filteredTopics && filteredTopics.length > 0) {
      return filteredTopics.slice(
        (filter.currentPage - 1) * filter.pageSize,
        filter.currentPage * filter.pageSize
      )
    }
    return []
  }, [filteredTopics, filter.currentPage, filter.pageSize])

  const handleChangePage = (page: number, pageSize: number) => {
    setFilter({ ...filter, currentPage: page, pageSize })
  }

  const handleSearchChange = (e: any) => {
    const newValue = e.target.value
    setSearchParams(newValue)
    if (!newValue.trim()) {
      setFilter({ ...filter, currentPage: 1, search: '' })
    }
  }
  const initialValues: any = {
    text: '',
    currentPage: 1,
    pageSize: 10,
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)
  const handleSearchActive = () => {
    setFilter({ ...filter, currentPage: 1, search: searchParams })
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <div
          className={`d-flex justify-content-between align-items-start gap-5 mb-6
          `}
        >
          <div className='d-flex'>
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder='Search Topic'
                className='sapp-h-40px bg-gray-100 border-0 sapp-search-custom'
                smallSearch={true}
                isListScreen={false}
                onSubmit={handleSearchActive}
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={handleSearchActive}
                className='ms-4'
                size='small'
              />
            </div>
          </div>

          <ButtonPrimary
            disabled={disabled}
            title='Choose Case Study'
            onClick={() => handleChooseStories()}
            size='small'
          />
        </div>
        {story?.name && (
          <div className='border border-dashed rounded pt-4 pb-4 ps-4 pe-4'>
            <div className='sapp-text-truncate-1 sapp-text-primary fw-semibold fs-6'>
              {htmlToRaw(story?.name || '')}
            </div>
          </div>
        )}
        <div>
          <SappTable
            headers={headers}
            loading={false}
            data={currentTopics}
            isCheckedAll={false}
            onChange={() => {}}
            hasCheck={false}
            showHeader={true}
            showHashtag
            classTable='table align-middle fs-6 gy-5 dataTable no-footer'
          >
            {currentTopics?.map((topic: any, i: number) => {
              return (
                <tr key={topic.id}>
                  <td>{i + 1 + (filter.currentPage - 1) * filter.pageSize}</td>
                  <td className='fs-6 fw-semibold sapp-text-primary sapp-ellipsis-text w-750px'>
                    {topic?.question_topic?.name}
                  </td>
                  <td>
                    <div className='text-gray-600'>{topic.number_of_questions}</div>
                  </td>
                  <td className='d-flex justify-content-end '>
                    <a
                      href={`${PageLink.TOPIC}/${topic.question_topic?.id}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <ButtonIconOnly
                        iconName={'eye'}
                        activeColor='primary'
                        onClick={() => {}}
                        bg='gray-100'
                      />
                    </a>
                  </td>
                </tr>
              )
            })}
          </SappTable>
        </div>
        <PagiantionSAPP
          currentPage={filter.currentPage}
          handlePaginationChange={(page: number, pageSize: number) => {
            handleChangePage(page, pageSize)
          }}
          pageSize={filter.pageSize}
          totalItems={filteredTopics.length}
        />
      </div>
    </div>
  )
}

export default StoriesTable
