import { useForm } from 'react-hook-form'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import SappModal from 'src/components/base/SappModal'
import { IAccessTime } from './TableTestQuizDetail'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

interface IProp {
  open: IAccessTime
  onClose: () => void
  onSaveTime: (data: {
    start_time: Date
    end_time: Date
    quiz_id: string | undefined
    class_id?: string | undefined
    class_user_id?: string | undefined
    staff_id?: string | undefined
  }) => void
  startDate?: string
  endDate?: string
  defaultAccessTime: { showTestQuiz: boolean }
  quiz_id?: string | undefined
  quizManual?: boolean
  loading?: boolean
  dataQuiz: Record<string, any> | undefined
}

const AccessTimeModal = ({
  open,
  onClose,
  onSaveTime,
  startDate,
  endDate,
  defaultAccessTime,
  dataQuiz,
  quiz_id,
  quizManual,
  loading,
}: IProp) => {
  const { id } = useParams()
  const { control, handleSubmit, setValue } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      openRegistry: {
        fromDate: open?.defaultValue?.start_time ?? Date.now(),
        toDate: open?.defaultValue?.end_time ?? Date.now(),
      },
    },
  })

  useEffect(() => {
    setValue('openRegistry', {
      fromDate: open?.defaultValue?.start_time ?? Date.now(),
      toDate: open?.defaultValue?.end_time ?? Date.now(),
    })
  }, [open])

  const handleDisableDate = (current: any) => {
    return (
      current &&
      startDate &&
      endDate &&
      (current > new Date(endDate).getTime() || current < new Date(startDate).getTime())
    )
  }

  const onSubmit = async (data: any) => {
    defaultAccessTime?.showTestQuiz
      ? onSaveTime({
          start_time: data.openRegistry.fromDate,
          end_time: data.openRegistry.toDate,
          quiz_id: dataQuiz?.quiz?.id,
          class_id: id,
        })
      : onSaveTime({
          start_time: data.openRegistry.fromDate,
          end_time: data.openRegistry.toDate,
          quiz_id: quiz_id,
          class_user_id: dataQuiz?.class_user?.id,
          staff_id: quizManual ? dataQuiz?.staff?.id : undefined,
        })
  }

  return (
    <SappModal
      dialogClassName='modal-dialog'
      open={open.isOpen}
      enforceFocus={false}
      title={'Access Time'}
      showFooter={false}
      handleClose={onClose}
      centered
    >
      <div className='mb-7'>
        <RangeDateTimePicker
          control={control}
          name='openRegistry'
          allowClear={true}
          placeholder={['From', 'To']}
          defaultValue={null}
          showTime
          format='DD/MM/YYYY HH:mm'
          disabledDate={handleDisableDate}
        />
      </div>

      <SAPPDialogButtonsCancelSubmit
        type='button'
        cancelClick={onClose}
        cancelButtonCaption='Cancel'
        okButtonCaption='Save'
        okOnClick={handleSubmit(onSubmit)}
        className='d-flex justify-content-end gap-5'
        classNameCancel='fw-bold me-0 sapp-fs-16px'
        classNameSubmit='fw-bold me-5 sapp-fs-16px'
        loading={loading}
      />
    </SappModal>
  )
}

export default AccessTimeModal
