import { Select, Skeleton } from 'antd'
import { debounce, isUndefined } from 'lodash'
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Col, Dropdown, Modal, Row } from 'react-bootstrap'
import { UseFormReturn } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import oneColumnDropHovered from 'src/_metronic/assets/images/question/one-column-d.svg'
import oneColumn from 'src/_metronic/assets/images/question/one-column.svg'
import twoColumnDropHovered from 'src/_metronic/assets/images/question/two-column-d.svg'
import twoColumn from 'src/_metronic/assets/images/question/two-column.svg'
import { CommonAPI } from 'src/apis'
import { QuestionBankAPI } from 'src/apis/question-bank'
import ButtonDropdown from 'src/components/base/button/ButtonDropdown'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormRadioImageGroup from 'src/components/base/radiobutton/HookFormRadioImageGroup'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTag from 'src/components/base/tag/HookFormTag'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import { INDEPENDENT_QUESTION, PageLink, VALIDATION_FIELD } from 'src/constants'
import { LIST_SECTION_OPTION } from 'src/constants/entrancetest'
import { useQuestionContext } from 'src/context/QuestionProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import useCourseFilter from 'src/hooks/use-course-filter'
import { mergeImageToEditor } from 'src/utils/upload'
import { v4 as uuid } from 'uuid'
import PreviewQuestion from '../preview-question/PreviewQuestion'
import { IInputQuestionProps, IQuestion, QUESTION_LEVELS } from '../shared/interfaces'
import { useQuestionProviderContext } from '../shared/providers/QuestionProvider'
import WorkingGuide from '../shared/working-guide/Index'
import './LayoutQuestion.scss'

type ILayoutQuestionProp = {
  note?: string | string[]
  open: boolean
  onClose: () => void
  type: string
  useForm: UseFormReturn<any>
  children?: ReactNode
  hasQuestion?: boolean
  buildRequest?: (data: IInputQuestionProps) => {
    partialQuestion: Partial<IQuestion>
    listPayload?: any[]
  }
  buildRequestAsync?: (
    data: IInputQuestionProps
  ) => Promise<{ partialQuestion: Partial<IQuestion>; listPayload?: any[] }>
  id?: string
  actionType?: 'edit' | 'duplicate'
  fetchData?: (data: IQuestion) => any
  defaultValues: Partial<IInputQuestionProps>
  setDefaultQuestEditor?: any
  onClearForm?: () => void
  textEditor?: any
  data?: IQuestion
}
const { Option } = Select

export const defaultBelongSelect = [
  {
    name: 'course',
    placeHolder: 'Course',
    option: [],
  },
  {
    name: 'part',
    placeHolder: 'Part',
    option: [],
  },
  {
    name: 'chapter',
    placeHolder: 'Chapter',
    option: [],
  },
  {
    name: 'unit',
    placeHolder: 'Unit',
    option: [],
  },
  {
    name: 'activity',
    placeHolder: 'Activity',
    option: [],
  },
]

const LayoutQuestion = ({
  note,
  open,
  onClose,
  type,
  children,
  useForm,
  hasQuestion = true,
  buildRequest,
  buildRequestAsync,
  id,
  actionType,
  fetchData,
  defaultValues,
  setDefaultQuestEditor,
  onClearForm,
  textEditor,
  data,
}: ILayoutQuestionProp) => {
  const location = useLocation()
  const [topicId, setTopicId] = useState<string>('')

  useEffect(() => {
    const match = location.pathname.match(/\/topic\/([a-f0-9-]+)(?:\/|$)/)
    if (match) {
      setTopicId(match[1])
    } else {
      setTopicId('')
    }
  }, [location.pathname])

  const topicIdFromPath: string = window.location.pathname.split('/').pop() || ''
  let topic_id: string = topicId
  if (topic_id === '') {
    topic_id = topicIdFromPath
  }

  const { confirm, contextHolder } = useConfirm()
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm
  const { isSingle } = useQuestionContext()
  const {
    listCourse,
    setListCourse,
    getListSort,
    debouncedGetListSort,
    clearChildren,
    getCourses,
    debouncedGetCourses,
    handleNextPage,
    handleNextListSort,
  } = useCourseFilter(setValue)

  const params = useParams()

  const [defaultEditor1, setDefaultEditor1] = useState<any>()
  const [defaultEditorSolution, setDefaultEditorSolution] = useState<any>()
  const [listQuestionCategory, setlistQuestionCategory] = useState<any>([])
  const [openPreview, setOpenPreview] = useState<{ value: boolean; data: any }>({
    value: false,
    data: undefined,
  })
  const modalContentRef = useRef<HTMLDivElement>(null)
  const [loadingFetchData, setLoadingFetchData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [editorKey, setEditorKey] = useState<{
    question: string
    solution: string
  }>({
    question: 'question_' + uuid(),
    solution: 'solution' + uuid(),
  })
  const [qId, setQId] = useState<string | undefined>(id)
  const {
    state: { multiple, constructed },
    refreshListQuestion,
  } = useQuestionProviderContext()

  const [belongToQuiz, setBelongToQuiz] = useState<any>()

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  const fetchQuiz = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await QuestionBankAPI.getBelongToQuiz(page_index, page_size, params)
      return res
    } catch (error) {}
  }
  const requirements = watch('requirements')

  const [questionDetail, setquestionDetail] = useState<any>()
  const [isBlocked, setIsBlocked] = useState(false)
  const [courseId, setCourseId] = useState('')
  const navigate = useNavigate()

  useLayoutEffect(() => {
    if (id) {
      setQId(id)
      ;(async () => {
        setLoadingFetchData(true)
        try {
          setquestionDetail(data)
          if (!data) {
            throw new Error('No data found')
          }
          if (
            data?.status === 'BLOCK' &&
            (data?.qType === 'DRAG_DROP' ||
              data?.qType === 'FILL_WORD' ||
              data?.qType === 'SELECT_WORD')
          ) {
            setIsBlocked(true)
          }
          if (data && data?.status === 'BLOCK') {
            setlistQuestionCategory(data.question_category)
          }
          let defaultData: Partial<IInputQuestionProps> = {
            level: data.level,
            is_self_reflection: data.is_self_reflection === true ? 'true' : 'false',
            course: data.question_filter?.course_id || '',
            part: data.question_filter?.part_id || '',
            chapter: data.question_filter?.chapter_id || '',
            unit: data.question_filter?.unit_id || '',
            activity: data.question_filter?.activity_id || '',
            category: data.question_category?.map((e) => ({
              id: e.id,
              name: e.name,
            })),
            quiz_id: data.question_filter?.quiz_id || '',
            display_type: data.display_type as 'VERTICAL' | 'HORIZONTAL' | undefined,
            question_content: data.question_content,
            solution: data.solution,
            hint: data.hint,
            tags: data.tags,
            is_independent_question: data.is_independent_question,
          }

          if (!data.is_independent_question) {
            // Fill data in Belong To when first load to edit
            const seedCourses = [
              {
                id: data.question_filter?.course_id || '',
                name: data.question_filter?.course?.name || '',
              },
            ]
            setCourseId(data.question_filter?.course_id || '')

            getCourses(
              undefined,
              undefined,
              undefined,
              undefined,
              data.question_filter?.course_id,
              seedCourses
            )

            getListSort({
              parentId: data.question_filter?.course_id || '',
              type: 'part',
              parentType: 'course',
              isSelect: true,
            })
            getListSort({
              parentId: data.question_filter?.part_id || '',
              type: 'chapter',
              parentType: 'part',
              isSelect: true,
            })

            if (data.question_filter?.chapter_id) {
              getListSort({
                parentId: data.question_filter?.course_id || '',
                type: 'part',
                parentType: 'course',
                isSelect: true,
              })
              getListSort({
                parentId: data.question_filter?.part_id || '',
                type: 'chapter',
                parentType: 'part',
                isSelect: true,
              })

              if (data.question_filter?.chapter_id) {
                getListSort({
                  parentId: data.question_filter?.chapter_id || '',
                  type: 'unit',
                  parentType: 'chapter',
                  isSelect: true,
                })
              }

              if (data.question_filter?.unit_id) {
                getListSort({
                  parentId: data.question_filter?.unit_id || '',
                  type: 'activity',
                  parentType: 'unit',
                  isSelect: true,
                })
              }
            }
          }

          if (fetchData) {
            const fetchresult = await fetchData(data)
            defaultData = { ...defaultData, ...fetchresult }
          }
          defaultData.question_content = await mergeImageToEditor(
            defaultData.question_content || '',
            data.files || []
          )
          defaultData.solution = await mergeImageToEditor(
            defaultData.solution || '',
            data.files || []
          )
          Object.entries(defaultData || {}).forEach(([key, value]: [string, any]) => {
            setValue(key, value)
          })
          setDefaultEditor1(defaultData.question_content)
          setDefaultQuestEditor && setDefaultQuestEditor(defaultData.question_content)
          setDefaultEditorSolution(defaultData.solution)
        } catch (error: any) {
          handleClose()
        } finally {
          setLoadingFetchData(false)
        }
      })()
    }
  }, [id])

  const handleClose = () => {
    onClose()
    reset()
  }
  const handleClosePreview = () => {
    setOpenPreview({ value: false, data: undefined })
  }
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Nếu bạn điều hướng khỏi popup này mà không lưu trước thì câu hỏi sẽ bị mất.'],
      onClick: handleClose,
    })
  }
  const onSubmit = async (data: IInputQuestionProps, submitType?: 'new' | 'preview' | 'close') => {
    if (data.is_self_reflection === 'false' && !data.solution?.length && type !== 'Constructed') {
      setError('solution', { message: VALIDATION_FIELD })
    }

    if (!data?.is_independent_question && !data.course?.trim()) {
      setError('course', { message: VALIDATION_FIELD })
      return
    }
    if (!data?.is_independent_question && !data.part?.trim()) {
      setError('part', { message: VALIDATION_FIELD })
      return
    }

    if (submitType !== 'preview') {
      setLoading(true)
    }

    let request: IQuestion = {
      assignment_type: 'TEXT',
      level: data.level,
      is_self_reflection: data.is_self_reflection === 'true' ? true : false,
      display_type: data.display_type,
      question_content: (data.question_content ?? '').trim(),
      solution: (data.solution ?? '').trim(),
      hint: (data.hint ?? '').trim(),
      tags: data.tags?.map((e) => {
        if (e.id) {
          return { id: e.id }
        } else {
          return { name: e.name }
        }
      }),
      question_filter: {
        course_id: data.course,
        part_id: data.part,
        chapter_id: data.chapter || undefined,
        unit_id: data.unit || undefined,
        activity_id: data.activity || undefined,
        quiz_id: data.quiz_id || undefined,
      },
      question_category_id: data.category?.map((e) => e.id || '') || [],
      question_topic_id: topic_id,
      text_editor_files: textEditorFiles?.concat(textEditor),
      is_independent_question: data?.is_independent_question ?? false,
    }

    request.text_editor_files = request.text_editor_files?.filter((file) => file?.id)

    if (request.is_independent_question) {
      delete request.question_filter?.course_id
      delete request.question_filter?.part_id
      delete request.question_filter?.chapter_id
    }

    if (Object.values(request.question_filter || {}).every((e) => e === null || e === undefined)) {
      request.question_filter = undefined
    }

    let additionalRequest
    if (buildRequest) {
      additionalRequest = buildRequest(data)
    }
    if (buildRequestAsync) {
      const requestAsync = await buildRequestAsync(data)
      additionalRequest = { ...additionalRequest, ...requestAsync }
    }
    request = { ...request, ...(additionalRequest?.partialQuestion || {}) }

    if (submitType === 'preview') {
      setOpenPreview({
        value: true,
        data: {
          ...request,
          question_topic_id: questionDetail?.is_single_question
            ? questionDetail?.question_topic_id
            : topic_id,
        },
      })
      return
    }

    try {
      if (qId && actionType !== 'duplicate') {
        await QuestionBankAPI.updateQuestion({
          id: qId,
          data: { ...request },
        })
      } else {
        if (isSingle) {
          const res = await QuestionBankAPI.createSingleQuestion({
            data: { ...request },
          })
          navigate(`${PageLink.TOPICS}`, { state: { reload: res.data.id } })
        } else {
          await QuestionBankAPI.createQuestion({
            data: { ...request },
          })
          navigate(`${PageLink.TOPIC}/${topic_id}`, {
            replace: true,
          })
        }
      }

      toast.success('Question saved successfully!', { id: 'question_save' })

      switch (submitType) {
        case 'new':
          setTimeout(() => {
            reset(defaultValues, {
              keepErrors: false,
              keepIsSubmitted: false,
              keepDefaultValues: true,
              keepValues: false,
              keepDirty: false,
              keepDirtyValues: false,
              keepTouched: false,
              keepSubmitCount: false,
              keepIsValid: true,
            })
            setQId(undefined)
            setDefaultEditorSolution('')
            setDefaultQuestEditor && setDefaultQuestEditor('')
            setDefaultEditor1('')
            setEditorKey({
              question: 'question_' + uuid(),
              solution: 'solution' + uuid(),
            })
            onClearForm && onClearForm()
            modalContentRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
          }, 300)
          break
        case 'close':
          setTimeout(() => {
            handleClose()
          }, 300)
          break
        default:
          break
      }
      refreshListQuestion(['multiple', 'constructed'])
    } finally {
      setLoading(false)
    }
  }

  // Xử lý chuyển sang trang kế tiếp của danh sách câu hỏi
  const handlNextPageQuiz = async (params: Object) => {
    const totalPages = belongToQuiz?.meta?.total_pages
    const pageIndex = belongToQuiz?.meta?.page_index as number
    const pageSize = belongToQuiz?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchQuiz(pageIndex + 1, pageSize, params)
      const results = belongToQuiz.quizzes.concat(res?.data?.groups)
      setBelongToQuiz({
        meta: res?.data?.meta,
        quizzes: results,
      })
    }
  }

  // Lấy danh sách câu hỏi
  const getQuiz = async ({ params }: { params: Object }) => {
    const resMentor = await fetchQuiz(1, 20, params)
    setBelongToQuiz(resMentor?.data)
  }

  // Tạo hàm debounce để tìm kiếm câu hỏi
  const debounceSearchQuiz = debounce((e) => {
    getQuiz({ params: { name: e, courseId: watch('course') } })
  }, 500)

  // Tạo mảng chứa danh sách câu hỏi và bài kiểm tra
  const belongToQuizAndTest =
    belongToQuiz?.quizzes &&
    belongToQuiz?.quizzes?.map((quizzes: any) => ({ label: quizzes?.name, value: quizzes?.id }))

  // useEffect để xử lý khi giá trị của 'course' thay đổi
  useEffect(() => {
    if (watch('course') === undefined) {
      setBelongToQuiz(undefined)
      setValue('quiz_id', null)
    }
  }, [watch('course')])

  // useEffect để lấy danh sách câu hỏi khi 'course' thay đổi
  useEffect(() => {
    if (!isUndefined(watch('course'))) {
      getQuiz({ params: { courseId: watch('course') } })
    }
  }, [watch('course')])

  useEffect(() => {
    if (getValues('is_self_reflection') === 'true') {
      clearErrors('solution')
    }
  }, [watch('is_self_reflection')])

  // useEffect để đặt giá trị 'quiz' thành null khi 'id' không tồn tại
  useEffect(() => {
    if (!id) {
      setValue('quiz_id', null)
    }
  }, [watch('course')])

  useEffect(() => {
    if (id) {
      if (questionDetail?.question_filter?.course_id !== watch('course')) {
        setValue('quiz_id', null)
      }
    }
  }, [watch('course'), id])

  const [quizId, setQuizId] = useState(false)

  useEffect(() => {
    if (quizId) {
      setValue('quiz_id', null)
    }
  }, [quizId])

  return (
    <>
      <Modal
        scrollable={true}
        dialogClassName='modal-dialog  m-0 '
        fullscreen
        show={open}
        onHide={handleCancel}
        enforceFocus={false}
      >
        <CreateEditLayout
          pageTitle={`${
            qId ? (actionType === 'edit' ? 'Edit ' : 'Duplicate ') : 'Create '
          } ${type} Question`}
          onCancel={handleCancel}
          customPrimaryButton={
            <ButtonDropdown
              type='button'
              title='Save'
              isListScreen
              onClick={handleSubmit((data: any) => onSubmit(data, 'close'))}
              loading={loading || multiple.loading || constructed.loading || loadingFetchData}
            >
              <Dropdown.Item
                as='button'
                onClick={handleSubmit((data: any) => onSubmit(data, 'new'))}
              >
                Save & New
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={handleSubmit((data: any) => onSubmit(data, 'preview'))}
              >
                Preview
              </Dropdown.Item>
            </ButtonDropdown>
          }
        >
          <div ref={modalContentRef}>
            <div className='card-body sapp-question_custom-container w-100 mb-2'>
              <div className='position-relative w-100'>
                <form className='w-100'>
                  {/* start:: level */}
                  <div className='sapp-mb-32px'>
                    <SappLabel label='Question Level' required />
                    {!loadingFetchData ? (
                      <HookFormRadioGroup
                        direction='horizontal'
                        separator={false}
                        name='level'
                        control={control}
                        justify='start'
                        gap={16}
                        labelClass='fw-semibold fs-6'
                        options={[
                          {
                            label: 'Fundamental',
                            value: QUESTION_LEVELS.FUNDAMENTAL,
                          },
                          {
                            label: 'Advanced',
                            value: QUESTION_LEVELS.ADVANCED,
                          },
                        ]}
                      />
                    ) : (
                      <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                    )}
                  </div>
                  {/* end:: level */}

                  {/* start:: self-reflection */}
                  {questionDetail?.status !== 'BLOCK' && (
                    <div className='sapp-mb-32px'>
                      <SappLabel label='Self Reflection' required />
                      <HookFormRadioGroup
                        loading={loadingFetchData}
                        direction='horizontal'
                        separator={false}
                        name='is_self_reflection'
                        control={control}
                        justify='start'
                        gap={16}
                        labelClass='fw-semibold fs-6'
                        options={[
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                          {
                            label: 'No',
                            value: 'false',
                          },
                        ]}
                        defaultValue={'false'}
                      />
                    </div>
                  )}
                  {/* end:: self-reflection */}

                  {/* start:: Question template*/}
                  <div className='sapp-mb-32px'>
                    <SappLabel label='Question Layout' required />
                    <div className='d-flex flex-wrap'>
                      <div className='me-3'>
                        <div className='mr-4'>
                          <HookFormRadioImageGroup
                            direction='horizontal'
                            separator={false}
                            name='display_type'
                            control={control}
                            skeleton={loadingFetchData}
                            labelClass='fw-semibold fs-6'
                            options={[
                              {
                                value: 'HORIZONTAL',
                                label: {
                                  normal: oneColumn,
                                  selected: oneColumnDropHovered,
                                  text: 'One column',
                                },
                              },
                              {
                                value: 'VERTICAL',
                                label: {
                                  normal: twoColumn,
                                  selected: twoColumnDropHovered,
                                  text: 'Two columns',
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end:: Question template*/}

                  {/* start:: Category belong to*/}
                  <Row>
                    <Col md={12} className='sapp-mb-32px'>
                      <HookFormTag
                        name='category'
                        control={control}
                        skeleton={loadingFetchData}
                        isCanAddNew={false}
                        isEditTopic={false}
                        listQuestionCategory={listQuestionCategory}
                        fetch={async ({
                          text,
                          page_index,
                          page_size,
                        }: {
                          text: string
                          page_index: string
                          page_size: string
                        }) => {
                          return await CommonAPI.getTags({
                            apiEndpoint: 'question-categories',
                            params: { name: text, page_index, page_size },
                          })
                        }}
                        dataName='data'
                        label={LIST_SECTION_OPTION.question_category_id}
                        required
                      />
                    </Col>
                    <Col md={12} className='sapp-mb-32px'>
                      <HookFormCheckBox
                        control={control}
                        name='is_independent_question'
                        title={INDEPENDENT_QUESTION}
                        classNameTitle='ms-3 sapp-form-check-label'
                        defaultValue={getValues('is_independent_question') ?? false}
                        disabled={questionDetail?.quiz_question_instances?.length > 0}
                      />
                    </Col>
                    {!watch('is_independent_question') && (
                      <Col md={12} className='sapp-mb-32px'>
                        <SappLabel label='Belong To' required />

                        <HookFormSelectAntd
                          classNameHeight='sapp-h-45px'
                          name='course'
                          placeholder='Course'
                          control={control}
                          className='w-100 fs-6 sapp-mb-10px belong-to__course'
                          showSearch
                          handleNextPage={() => handleNextPage(courseId)}
                          onFocus={async () => {
                            if (listCourse?.course?.data.length <= 0) {
                              await getCourses()
                              return
                            }
                          }}
                          onSearch={async (e) => {
                            debouncedGetCourses(e)
                            return
                          }}
                          onChange={(e: any) => {
                            if (e === undefined) {
                              return
                            }
                            getListSort({
                              parentId: e,
                              type: 'part',
                              parentType: 'course',
                              isSelect: true,
                            })
                          }}
                          loading={listCourse.course.loading}
                          allowClear
                          onClear={() => {
                            setListCourse(clearChildren('course', true))
                          }}
                        >
                          {listCourse.course.data?.map((e, index) => {
                            return (
                              <Option key={e.id + index} value={e.id}>
                                {e.name}
                              </Option>
                            )
                          })}
                        </HookFormSelectAntd>

                        <Row>
                          <Col xs={12} md={3}>
                            <HookFormSelectAntd
                              classNameHeight='sapp-h-45px'
                              name='part'
                              placeholder='Section'
                              control={control}
                              showSearch
                              className='fs-6 belong-to__items'
                              loading={listCourse.part.loading}
                              onSearch={async (e) => {
                                debouncedGetListSort({
                                  type: 'part',
                                  name: e,
                                  parentId: listCourse.course.id,
                                  parentType: 'course',
                                })
                                return
                              }}
                              onChange={(e: any) => {
                                if (e === undefined) {
                                  return
                                }
                                getListSort({
                                  parentId: e,
                                  type: 'chapter',
                                  parentType: 'part',
                                  isSelect: true,
                                })
                              }}
                              handleNextPage={() => handleNextListSort('part', 'course')}
                              allowClear
                              onClear={() => {
                                setListCourse(clearChildren('part', true, getValues('course')))
                              }}
                            >
                              {listCourse.part.data?.map((e, index) => {
                                return (
                                  <Option key={e.id + index} value={e.id}>
                                    {e.name}
                                  </Option>
                                )
                              })}
                            </HookFormSelectAntd>
                          </Col>
                          <Col xs={12} md={3}>
                            <HookFormSelectAntd
                              classNameHeight='sapp-h-45px'
                              name='chapter'
                              placeholder='Subsection'
                              control={control}
                              className='fs-6 belong-to__items'
                              showSearch
                              loading={listCourse.chapter.loading}
                              onSearch={async (e) => {
                                debouncedGetListSort({
                                  type: 'chapter',
                                  name: e,
                                  parentId: listCourse.part.id,
                                  parentType: 'part',
                                })
                                return
                              }}
                              onChange={(e: any) => {
                                if (e === undefined) {
                                  return
                                }
                                getListSort({
                                  parentId: e,
                                  type: 'unit',
                                  parentType: 'chapter',
                                  isSelect: true,
                                })
                              }}
                              allowClear
                              onClear={() => {
                                setListCourse(clearChildren('chapter', true, getValues('part')))
                              }}
                              handleNextPage={() => handleNextListSort('chapter', 'part')}
                            >
                              {listCourse.chapter.data?.map((e, index) => {
                                return (
                                  <Option key={e.id + index} value={e.id}>
                                    {e.name}
                                  </Option>
                                )
                              })}
                            </HookFormSelectAntd>
                          </Col>
                          <Col xs={12} md={3}>
                            <HookFormSelectAntd
                              classNameHeight='sapp-h-45px'
                              name='unit'
                              placeholder='Unit'
                              control={control}
                              className='fs-6 belong-to__items'
                              showSearch
                              loading={listCourse.unit.loading}
                              onSearch={async (e) => {
                                debouncedGetListSort({
                                  type: 'unit',
                                  name: e,
                                  parentId: listCourse.chapter.id,
                                  parentType: 'chapter',
                                })
                                return
                              }}
                              onChange={(e: any) => {
                                if (e === undefined) {
                                  return
                                }
                                getListSort({
                                  parentId: e,
                                  type: 'activity',
                                  parentType: 'unit',
                                  isSelect: true,
                                })
                              }}
                              allowClear
                              onClear={() => {
                                setListCourse(clearChildren('unit', true, getValues('chapter')))
                              }}
                              handleNextPage={() => handleNextListSort('unit', 'chapter')}
                            >
                              {listCourse.unit.data?.map((e, index) => {
                                return (
                                  <Option key={e.id + index} value={e.id}>
                                    {e.name}
                                  </Option>
                                )
                              })}
                            </HookFormSelectAntd>
                          </Col>
                          <Col xs={12} md={3}>
                            <HookFormSelectAntd
                              classNameHeight='sapp-h-45px'
                              name='activity'
                              placeholder='Activity'
                              control={control}
                              className='fs-6 belong-to__items'
                              showSearch
                              loading={listCourse.activity.loading}
                              onSearch={async (e) => {
                                debouncedGetListSort({
                                  type: 'activity',
                                  name: e,
                                  parentId: listCourse.unit.id,
                                  parentType: 'unit',
                                })
                                return
                              }}
                              onChange={(e: any) => {
                                if (e === undefined) {
                                  return
                                }
                                getListSort({
                                  parentId: e,
                                  type: 'unit',
                                  parentType: 'activity',
                                  isSelect: true,
                                })
                              }}
                              allowClear
                              onClear={() => {
                                setListCourse(clearChildren('activity', true, getValues('unit')))
                              }}
                              handleNextPage={() => handleNextListSort('activity', 'unit')}
                            >
                              {listCourse.activity.data?.map((e, index) => {
                                return (
                                  <Option key={e.id + index} value={e.id}>
                                    {e.name}
                                  </Option>
                                )
                              })}
                            </HookFormSelectAntd>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {/* end:: Category belong to*/}

                  <Row>
                    {watch('course') && !watch('is_independent_question') && (
                      <Col md={12} className='sapp-mb-32px'>
                        <div>
                          <SappLabel label='Belong To Quiz/Test' />
                          <Row className='gap-3'>
                            <Col md={2}>
                              <HookFormSelectAntd
                                classNameHeight='sapp-h-45px'
                                name='quiz_id'
                                placeholder='Quiz/Test'
                                control={control}
                                className='w-100 fs-6 sapp-field_question-select'
                                loading={loading}
                                onSearch={(e: any) => {
                                  if (e === undefined) {
                                    return
                                  }
                                  if (!isUndefined(watch('course'))) {
                                    debounceSearchQuiz(e)
                                  }
                                }}
                                onClear={() => setQuizId(true)}
                                handleNextPage={(e: any) =>
                                  handlNextPageQuiz({ params: { name: e } })
                                }
                                showSearch
                                allowClear
                              >
                                {belongToQuizAndTest &&
                                  belongToQuizAndTest?.map((e: any, index: any) => {
                                    return (
                                      <Option key={e.id + index} value={e.value}>
                                        {e.label}
                                      </Option>
                                    )
                                  })}
                              </HookFormSelectAntd>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                  </Row>

                  {/* start:: note */}
                  {note && <WorkingGuide note={note}></WorkingGuide>}
                  {/* end:: note */}

                  {/* start:: question */}
                  {hasQuestion && (
                    <div className='d-flex flex-wrap sapp-mb-32px'>
                      <HookFormEditor
                        editorKey={editorKey.question}
                        label='Nội Dung Câu Hỏi'
                        disabled={isBlocked}
                        labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
                        required
                        placeholder=' '
                        name='question_content'
                        control={control}
                        math={true}
                        className='w-100 fs-6'
                        defaultValue={defaultEditor1}
                        resourceLocation={RESOURCE_LOCATION.QUESTION}
                        object_id={qId && actionType === 'edit' ? params?.id : undefined}
                        setDataFile={setFileEditor}
                      />
                    </div>
                  )}
                  {/* end:: question*/}

                  {/* start:: feedback*/}
                  {!loadingFetchData ? (
                    <div className='mb-3'>{children}</div>
                  ) : (
                    <Skeleton active className='w-100 mb-8'></Skeleton>
                  )}
                  {/* end:: feedback*/}

                  {/* start:: explain*/}
                  <div className='sapp-mb-32px'>
                    {!requirements?.length && (
                      <div className='d-flex flex-wrap'>
                        <SappLabel
                          required={
                            type !== 'Constructed' && watch('is_self_reflection') === 'false'
                          }
                          label={type === 'Constructed' ? 'Solution' : 'Explanation'}
                        />
                        <HookFormEditor
                          editorKey={editorKey.solution}
                          placeholder=' '
                          skeleton={loadingFetchData}
                          height={400}
                          name='solution'
                          control={control}
                          className='w-100 fs-6'
                          math={true}
                          required={
                            type !== 'Constructed' && watch('is_self_reflection') === 'false'
                          }
                          // guideline={[
                          //   'Cho phép nhập chữ hoa, thường, chữ số, ký tự đặc biệt, nhập text, tạo table  theo các format trong texteditor',
                          //   'Cho phép chèn và upload link, ảnh, video, file',
                          // ]}
                          defaultValue={defaultEditorSolution}
                          resourceLocation={RESOURCE_LOCATION.QUESTION}
                          object_id={qId && actionType === 'edit' ? params?.id : undefined}
                          setDataFile={setFileEditor}
                        />
                      </div>
                    )}
                  </div>
                  {/* end:: explain*/}

                  {/* start:: hint*/}
                  <div className='d-flex flex-wrap sapp-mb-32px'>
                    <HookFormTextField
                      label='Gợi ý'
                      labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
                      control={control}
                      name='hint'
                      placeholder=' '
                      skeleton={loadingFetchData}
                      // guideline={[
                      //   'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, không giới hạn ký tự.',
                      // ]}
                    ></HookFormTextField>
                  </div>
                  {/* end:: hint*/}

                  {/* start:: tags*/}
                  <div className='d-flex flex-wrap'>
                    <SappLabel label='Tag' />
                    <HookFormTag
                      name='tags'
                      placeholder=' '
                      control={control}
                      skeleton={loadingFetchData}
                      fetch={async ({
                        text,
                        page_index,
                        page_size,
                      }: {
                        text: string
                        page_index: string
                        page_size: string
                      }) => {
                        return await CommonAPI.getTags({
                          apiEndpoint: 'question-tags',
                          params: { name: text, page_index, page_size },
                        })
                      }}
                      dataName='question_tags'
                    />
                  </div>
                  {/* end:: tag*/}
                  {contextHolder}
                </form>
              </div>
            </div>
          </div>
        </CreateEditLayout>
      </Modal>
      <PreviewQuestion
        open={openPreview.value}
        onClose={handleClosePreview}
        data={openPreview.data}
        enableLoading={!(questionDetail?.is_single_question || isSingle)}
      />
    </>
  )
}

export default LayoutQuestion
