import { zodResolver } from '@hookform/resolvers/zod'
import { Select, Skeleton } from 'antd'
import { debounce, isUndefined } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import thumnail_default from 'src/_metronic/assets/images/course/thumnail_default.png'
import { CommonAPI } from 'src/apis'
import { CoursesAPI } from 'src/apis/courses'
import { SubjectAPI } from 'src/apis/subject'
import ErrorMessage from 'src/common/ErrorMessage'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import LoadingInput from 'src/components/base/LoadingInput'
import HookFormSelect from 'src/components/base/select/HookFormSelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTag from 'src/components/base/tag/HookFormTag'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  COURSE_STATUS_FORM,
  COURSE_TYPES,
  DEFAULT_MAX_FILE_SIZE,
  PageLink,
  VALIDATE_CODE_MAX,
  VALIDATE_CODE_MIN,
  VALIDATE_NAME_MAX,
  VALIDATE_NAME_MIN,
  VALIDATE_NUMBER,
  VALIDATE_PASS_POINT,
  VALIDATE_SETTING,
  VALIDATION_FIELD,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'
import { useCourseContext } from 'src/context/CourseProvider'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { IErrorCourse, Role } from 'src/type'
import {
  CourseCondition,
  ECourseLevel,
  ICourseCategoies,
  ICourseInfo,
  ICourseLevel,
  ICourseTags,
  ICourseType,
} from 'src/type/courses'
import { ISubject, ISubjectList } from 'src/type/subject'
import { getCourseFullLevel, sizeInBytes } from 'src/utils'
import { mergeImageToEditor } from 'src/utils/upload'
import { z } from 'zod'
import './CreateAndUpdateCourseInfo.scss'

const { Option } = Select

const CreateAndUpdateCourseInfo = ({ setStep, step }: { setStep?: any; step?: any }) => {
  const { getCourse, course: courseDetail, setCourse, idCourse } = useCourseContext()
  const [typeFoundation, setTypeFoundation] = useState(null)
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [tagCategory, setTagCategory] = useState<ICourseTags[]>()
  // const [courseDetail, setCourseDetail] = useState<any>()
  const [courseLevel, setCourseLevel] = useState<ICourseLevel[]>([])
  const [courseFoundationIA, setCourseFoundationIA] = useState<any>(null)
  const [courseFoundationIIA, setCourseFoundationIIA] = useState<any>(null)
  const [courseFoundationIB, setCourseFoundationIB] = useState<any>(null)
  const [courseFoundationIIIB, setCourseFoundationIIIB] = useState<any>(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [showCourseDefault, setShowCourseDefault] = useState(false)
  const [disableTextfield, setDisableTextfield] = useState<CourseCondition>(new CourseCondition())
  const [defaultEditor, setDefaultEditor] = useState<any>()

  // validate các trường là số min là 0, max là 100
  const validateNumber = z
    .string()
    .regex(/^[0-9]*$/, VALIDATE_NUMBER)
    .transform((val) => parseInt(val))
    .refine((val) => (!val ? true : val >= 0), { message: VALIDATE_SETTING })
    .refine((val) => (!val ? true : val <= 100), { message: VALIDATE_SETTING })
    .or(z.number().int(VALIDATE_NUMBER).min(0).max(100))
    .optional()

  // Validate for input
  const validationSchema = z.object({
    progress: validateNumber,
    activity_points: validateNumber,
    chapter_points: validateNumber,
    topic_points: validateNumber,
    mid_term_points: validateNumber,
    final_test_points: validateNumber,
    course_info: z.any(),
    code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATE_CODE_MIN })
      .max(255, { message: VALIDATE_CODE_MAX }),
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATE_NAME_MIN })
      .max(1000, { message: VALIDATE_NAME_MAX }),
    course_category_ids: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    subject_id: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    course_type: z.string().min(1, { message: VALIDATION_FIELD }),
    course_connect_ia: z.boolean().optional(),
    course_connect_ib: z.boolean().optional(),
    course_connect_iia: z.boolean().optional(),
    course_connect_iib: z.boolean().optional(),
    connectIA: z.any(),
    connectIIA: z.any(),
    connectIB: z.any(),
    connectIIB: z.any(),
    pass_point: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(/^[0-9]*$/, VALIDATE_NUMBER)
      .transform((val) => parseInt(val))
      .refine((val) => val >= 1, { message: VALIDATE_PASS_POINT })
      .refine((val) => val <= 100, { message: VALIDATE_PASS_POINT })
      .or(z.number().int(VALIDATE_NUMBER).min(1).max(10)),
    description: z.string({ required_error: VALIDATION_FIELD }).optional(),
    tags: z.any(),
    status: z.string().optional(),
    course_tags: z.array(z.any()).max(3, { message: 'Maximum 3 course tags' }).optional(),
    course_image: z.any(),
  })

  // Using validate for input
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<any>({ resolver: zodResolver(validationSchema), mode: 'onSubmit' })

  // tìm name category từ id
  const findCategoryIdByName = (categoryName: string, courseLevel: any) => {
    return courseLevel?.find((category: any) => category.name === categoryName)?.id ?? null
  }

  // biến tìm các type khác foundation
  const typeOtherFoundation = typeFoundation !== COURSE_TYPES[0].value

  const handleGotoStep = (index: number) => {
    const _new = step.map((e: any, idx: number) => {
      if (index > idx) {
        return { ...e, status: 'active' }
      }
      if (index === idx) {
        return { ...e, status: 'current' }
      }
      if (index < idx) {
        return { ...e, status: '' }
      }
    })
    setStep(_new)
  }

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  // hàm để create course
  const onSubmitCreate = async (data: ICourseInfo) => {
    const {
      course_category_ids,
      course_image,
      activity_points,
      code,
      subject_id,
      description,
      final_test_points,
      mid_term_points,
      name,
      pass_point,
      topic_points,
      course_tags,
      progress,
      chapter_points,
      course_connect_ia,
      course_connect_ib,
      course_connect_iia,
      course_connect_iib,
      connectIA,
      connectIB,
      connectIIA,
      connectIIB,
    } = data

    // get id của tags
    const tagIds = course_tags?.map((e: any) => (e.id ? e.id : e.name))

    const courseLevels = [
      course_connect_ia || connectIA ? findCategoryIdByName('IA', courseLevel) : null,
      course_connect_iia || connectIIA ? findCategoryIdByName('IIA', courseLevel) : null,
      course_connect_ib ? findCategoryIdByName('IB', courseLevel) : null,
      course_connect_iib ? findCategoryIdByName('IIB', courseLevel) : null,
    ].filter((item) => item !== null)

    const connectOtherFoundationIA = typeOtherFoundation ? connectIA : null
    const connectOtherFoundationIIA = typeOtherFoundation ? connectIIA : null
    const connectOtherFoundationIB = typeOtherFoundation ? connectIB : null
    const connectOtherFoundationIIB = typeOtherFoundation ? connectIIB : null

    // Create an object with named properties
    const convertName = name.replace(/\s+/g, ' ').trim()
    const courseInfo: Object = {
      name: convertName,
      code,
      subject_id,
      pass_point,
      course_tags: tagIds,
      description,
      status: 'DRAFT',
      course_type: typeFoundation,
      progress,
      activity_points,
      chapter_points,
      topic_points,
      mid_term_points,
      final_test_points,
      template: getCourseFullLevel() ? 4 : 2,
      course_category_ids: [course_category_ids],
      course_levels: typeOtherFoundation ? null : courseLevels ?? [],
      course_connect_ia: courseNotConnect
        ? null
        : connectOtherFoundationIA
        ? connectOtherFoundationIA
        : null,
      course_connect_ib: courseNotConnect
        ? null
        : connectOtherFoundationIB
        ? connectOtherFoundationIB
        : null,
      course_connect_iia: courseNotConnect
        ? null
        : connectOtherFoundationIIA
        ? connectOtherFoundationIIA
        : null,
      course_connect_iib: courseNotConnect
        ? null
        : connectOtherFoundationIIB
        ? connectOtherFoundationIIB
        : null,
      text_editor_files: textEditorFiles,
    }

    setLoading(true)

    // call API create course
    try {
      const res = await CoursesAPI.createCourseInfo(courseInfo)
      const courseId = res?.data?.id

      if (!isUndefined(course_image)) {
        await CoursesAPI.uploadImageCourse({ course_id: courseId, course_image: course_image })
      }

      if (res?.success) {
        getCourse(courseId)
      }

      navigate(`${PageLink.COURSE_FULL}/${courseId}`)
      handleGotoStep(1)
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IErrorCourse) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})

      if (error?.response?.data?.error.code === '400|072005') {
        toast.error('Program of foundation course does not match the selected one.')
      }
    } finally {
      setLoading(false)
    }
  }

  const courseNotConnect = [ICourseType.TRIAL, ICourseType.PRACTICE].includes(
    getValues('course_type') as any
  )

  const onSubmitUpdate = async (data: ICourseInfo) => {
    const { code, subject_id, pass_point } = data

    const tagIds = data.course_tags?.map((e: any) => (e.id ? e.id : e.name))

    const courseLevels = [
      data.course_connect_ia ? findCategoryIdByName('IA', courseLevel) : null,
      data.course_connect_iia ? findCategoryIdByName('IIA', courseLevel) : null,
      data.course_connect_ib ? findCategoryIdByName('IB', courseLevel) : null,
      data.course_connect_iib ? findCategoryIdByName('IIB', courseLevel) : null,
    ].filter((item) => item !== null)

    const connectOtherFoundationIA = typeOtherFoundation ? data.connectIA : null
    const connectOtherFoundationIIA = typeOtherFoundation ? data.connectIIA : null
    const connectOtherFoundationIB = typeOtherFoundation ? data.connectIB : null
    const connectOtherFoundationIIB = typeOtherFoundation ? data.connectIIB : null

    // Create an object with named properties
    const convertName = data.name.replace(/\s+/g, ' ').trim()
    const courseInfo: ICourseInfo = {
      ...data,
      id,
      name: convertName,
      code,
      subject_id,
      pass_point,
      course_tags: tagIds,
      course_category_ids: [data.course_category_ids as any],
      course_type: data.course_type || (typeFoundation as any),
      template: courseDetail?.template,
      course_levels: courseNotConnect
        ? null
        : getValues('course_type') !== ICourseType.FOUNDATION
        ? null
        : (courseLevels as any),
      course_connect_ia: courseNotConnect
        ? null
        : connectOtherFoundationIA
        ? connectOtherFoundationIA
        : isUndefined(clearFoundationIA)
        ? defaultValueConnectWith('IA')
        : null,
      course_connect_ib: courseNotConnect
        ? null
        : connectOtherFoundationIB
        ? connectOtherFoundationIB
        : isUndefined(clearFoundationIB)
        ? defaultValueConnectWith('IB')
        : null,
      course_connect_iia: courseNotConnect
        ? null
        : connectOtherFoundationIIA
        ? connectOtherFoundationIIA
        : isUndefined(clearFoundationIIA)
        ? defaultValueConnectWith('IIA')
        : null,
      course_connect_iib: courseNotConnect
        ? null
        : connectOtherFoundationIIB
        ? connectOtherFoundationIIB
        : isUndefined(clearFoundationIIB)
        ? defaultValueConnectWith('IIB')
        : null,
      text_editor_files: textEditorFiles,
    }

    // TODO: nếu ảnh khác undefined và khác value imageDefault mới call API upload image
    if (!isUndefined(data.course_image) && getValues('course_image') !== 'imageDefault') {
      await CoursesAPI.uploadImageCourse({
        course_id: courseDetail?.id,
        course_image: data.course_image,
      })
    }

    if (showCourseDefault && isUndefined(getValues('course_image'))) {
      await CoursesAPI.removeImageCourse(id)
    }

    setLoading(true)
    try {
      const res = await CoursesAPI.updateCourseInfo(courseInfo)

      if (res?.success) {
        getCourse(idCourse)
      }

      handleGotoStep(1)
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IErrorCourse) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})

      if (error?.response?.data?.error.code === '400|072005') {
        toast.error('Program of foundation course does not match the selected one.')
      }
    } finally {
      setLoading(false)
    }
  }

  // Todo: hàm call API get course category
  const fetchCourseCategory = async () => {
    try {
      const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 50 })
      setCourseCategory(res?.data)
    } catch (error) {}
  }

  // TODO: hàm submit form khi create or update
  const onSubmit: SubmitHandler<ICourseInfo> = async (data) => {
    // Prevent form submission
    if (
      !isUndefined(data.course_image) &&
      (data?.course_image?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    const newData = checkTotalPoint(data)
    if (!newData) return
    data = newData

    if (id !== 'undefined') {
      onSubmitUpdate(data)
      return
    }
    onSubmitCreate(data)
  }

  const newCategory = courseCategory?.course_categories?.map((category: any) => ({
    label: category.name,
    value: category.id,
  }))

  const checkTotalPoint = (data: any): ICourseInfo | null => {
    for (const key in data) {
      if (
        [
          'final_test_points',
          'mid_term_points',
          'chapter_points',
          'topic_points',
          'activity_points',
          'progress',
        ].includes(key) &&
        !data[key]
      ) {
        data[key] = 0
      }
    }
    const {
      final_test_points,
      mid_term_points,
      chapter_points,
      topic_points,
      activity_points,
      progress,
    } = data
    const total =
      final_test_points +
      mid_term_points +
      chapter_points +
      topic_points +
      activity_points +
      progress
    if (total !== 100) {
      setError('total_point', { message: 'Total point must be 100' })
      return null
    }
    return data
  }

  // TODO: gọi API tag category
  const fetchTagCategory = async () => {
    try {
      const res = await CoursesAPI.getTagCategory()
      setTagCategory(res?.data?.course_tags)
    } catch (error) {}
  }

  // TODO: gọi API course detail
  // const fetchcourseDetail = async () => {
  //   try {
  //     const res = await CoursesAPI.getCourseDetail(id)
  //     setCourseDetail(res?.data)
  //   } catch (error) {}
  // }

  // TODO: gọi API course detail
  const getCourseDetail = async (id: string) => {
    setLoading(true)
    try {
      const res = await CoursesAPI.getCourseDetail(id)
      setCourse(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id !== undefined && id !== 'undefined') {
      getCourseDetail(id)
    }
  }, [id])

  useEffect(() => {
    if (id === 'undefined' || !courseDetail) return

    // Define the properties you want to set in an array
    const propertiesToSet = [
      'name',
      'description',
      'code',
      'subject_id',
      'course_type',
      'pass_point',
      'status',
      'course_tags',
    ]

    const settingPassPoint = [
      'activity_points',
      'chapter_points',
      'final_test_points',
      'mid_term_points',
      'progress',
      'topic_points',
    ]

    // Loop through the properties and set their values
    propertiesToSet.forEach((property: string) => {
      setValue(property, courseDetail[property] ?? '' ?? [])
    })

    // Loop through the properties and set their values
    settingPassPoint.forEach((property: string) => {
      if (courseDetail?.setting) {
        setValue(property, courseDetail.setting[property] ?? '')
      }
    })

    setValue('course_category_ids', courseDetail?.course_categories?.[0]?.id ?? '')

    setValue(
      'course_connect_iia',
      courseDetail?.levels?.find((level: any) => level.name === ECourseLevel.IIA) ? true : false
    )
    setValue(
      'course_connect_ia',
      courseDetail?.levels?.find((level: any) => level.name === ECourseLevel.IA) ? true : false
    )
    setValue(
      'course_connect_ib',
      courseDetail?.levels?.find((level: any) => level.name === ECourseLevel.IB) ? true : false
    )
    setValue(
      'course_connect_iib',
      courseDetail?.levels?.find((level: any) => level.name === ECourseLevel.IIB) ? true : false
    )

    setValue('course_image', 'imageDefault')
  }, [courseDetail, id, setValue])

  const fetchCourseLevel = async () => {
    try {
      const res = await CoursesAPI.getCourseLevel()
      setCourseLevel(res?.data)
    } catch (error) {}
  }

  const fetchCourseFoundation = async (level: string) => {
    try {
      const res = await CoursesAPI.getCourseFoundations(level)
      return res?.data
    } catch (error) {}
  }

  useEffect(() => {
    fetchTagCategory()
    fetchCourseCategory()
    fetchCourseLevel()

    if (id) {
      fetchCourseFoundation(ECourseLevel.IA).then((res) => setCourseFoundationIA(res))
      fetchCourseFoundation(ECourseLevel.IB).then((res) => setCourseFoundationIB(res))
      fetchCourseFoundation(ECourseLevel.IIA).then((res) => setCourseFoundationIIA(res))
      fetchCourseFoundation(ECourseLevel.IIB).then((res) => setCourseFoundationIIIB(res))
    }
  }, [])

  // todo: search trên 3 kí tự mới call API course foundation
  const onSearchCourseFoundation = async (value: string, courseLevel: any) => {
    if (value.length > 2) {
      await fetchCourseFoundation(courseLevel).then((res) => {
        switch (courseLevel) {
          case ECourseLevel.IA:
            setCourseFoundationIA(res)
            break
          case ECourseLevel.IB:
            setCourseFoundationIB(res)
            break
          case ECourseLevel.IIA:
            setCourseFoundationIIA(res)
            break
          case ECourseLevel.IIB:
            setCourseFoundationIIIB(res)
            break
          default:
            break
        }
      })
    }
  }

  // todo: tính tổng total point

  const fieldsToWatch = [
    'progress',
    'activity_points',
    'chapter_points',
    'topic_points',
    'mid_term_points',
    'final_test_points',
  ]

  const totalPoint = fieldsToWatch.reduce((acc, fieldName) => {
    const fieldValue = Number(watch(fieldName)) || 0
    return acc + fieldValue
  }, 0) as number

  const arrayFoundation = [
    'course_connect_ia',
    'course_connect_ib',
    'course_connect_iia',
    'course_connect_iib',
  ]

  const newCourseLevel = courseLevel?.map((item, index: number) => ({
    ...item,
    validate: arrayFoundation[index],
  }))

  const filterCourses = (courseArray: any, watchedCourses?: any) => {
    return courseArray?.map((course: any) => ({ label: course?.name, value: course.id }))
  }

  const filterConnectIANormal = filterCourses(courseFoundationIA, [
    'connectIB',
    'connectIIA',
    'connectIIB',
  ])
  const filterConnectIBNormal = filterCourses(courseFoundationIB, [
    'connectIA',
    'connectIIA',
    'connectIIB',
  ])
  const filterConnectIIANormal = filterCourses(courseFoundationIIA, [
    'connectIB',
    'connectIA',
    'connectIIB',
  ])
  const filterConnectIIBNormal = filterCourses(courseFoundationIIIB, [
    'connectIB',
    'connectIIA',
    'connectIA',
  ])

  const { confirm, contextHolder } = useConfirm()

  // TODO: hàm cancel mở popup confirm trở về màn course list
  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.COURSES),
    })
  }

  // TODO: hàm submit mở popup confirm để call API tạo/ edit course
  const hanleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  useEffect(() => {
    setDisableTextfield(courseDetail?.state?.condition ?? new CourseCondition())
  }, [courseDetail])

  // todo: reset message error khi total_point bằng 100
  useEffect(() => {
    trigger('total_point')
  }, [totalPoint])

  const defaultValueConnectWith = (courseLevel: string) => {
    const course = courseDetail?.normal_course_connections?.find(
      (course: any) => course.level_code === courseLevel
    )

    const value = course?.foundation_course_id ?? null
    return value
  }

  useEffect(() => {
    if (errors?.course_image?.type) {
      window.scrollTo(0, 0)
    }
  }, [errors?.course_image?.type])

  useEffect(() => {
    ;(async () => {
      if (id !== 'undefined') {
        try {
          if (courseDetail) {
            const dataDes = await mergeImageToEditor(
              courseDetail.description,
              courseDetail?.files || []
            )
            setDefaultEditor(dataDes)
            setValue('description', dataDes)
          } else {
          }
        } catch (error: any) {}
      }
      if (watch('course_category_ids')) {
        await getSubjects({ params: { course_category_ids: watch('course_category_ids') } })
      }
    })()
  }, [courseDetail])
  useEffect(() => {
    if (id !== 'undefined') return

    setCourse(null)
  }, [])

  useEffect(() => {
    if (watch('course_type')) {
      setError('course_levels', { message: '' })
    }
  }, [watch('course_type')])

  const [clearFoundationIA, setClearFoundationIA] = useState<any>(undefined)
  const [clearFoundationIB, setClearFoundationIB] = useState<any>(undefined)
  const [clearFoundationIIA, setClearFoundationIIA] = useState<any>(undefined)
  const [clearFoundationIIB, setClearFoundationIIB] = useState<any>(undefined)

  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEditCreateCourse = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_COURSE_GR.CREATE_COURSE) ||
      hasPermission(role, TITLE_COURSE_GR.EDIT_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderSaveBtn =
    id === 'undefined' ||
    profileMe?.roles?.some(
      (role: Role) =>
        hasPermission(role, TITLE_COURSE_GR.EDIT_COURSE) || role.code === CODE_ADMIN.SUPER_ADMIN
    )

  /**
   * @description state lưu giá trị của subject
   */
  const [subjects, setSubjects] = useState<ISubjectList>()
  const requestOngoingRef = useRef(false)

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  const getSubjects = async ({ params }: any) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_ids')) {
      getSubjects({ params: { name: e, course_category_ids: watch('course_category_ids') } })
    }
  }, 500)

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('subject_id', '')
    if (watch('course_category_ids') !== 'all') {
      await getSubjects({ params: { course_category_ids: watch('course_category_ids') } })
    }
  }

  return (
    <>
      <Processbar
        step={step}
        setNewStep={setStep}
        onCancel={hanleCancel}
        onClick={hanleSubmitForm}
        showCancel
      />
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-8'>
          <div className='card card-flush'>
            <div className='card-header sapp-mt-32px'>
              <SappLabel label='Thumbnail' isLabel />
            </div>

            <div className='card-body text-center px-16 pb-10'>
              {loading ? (
                <Skeleton.Image active className='w-150px sapp-h-150px' />
              ) : (
                <SAPPHookUploadFile
                  name='course_image'
                  control={control}
                  setValue={setValue}
                  setError={setError}
                  imagePreview={
                    courseDetail?.course_image?.['150x150'] ??
                    courseDetail?.course_image?.ORIGIN ??
                    thumnail_default
                  }
                  accept={ACCEPT_UPLOAD_MIME_TYPE}
                  maxFileSize={DEFAULT_MAX_FILE_SIZE}
                  removeAvatar={thumnail_default}
                  setShowAvatarDefault={setShowCourseDefault}
                  disabled={!courseDetail?.state?.condition.can_edit_course_info.thumbnail}
                  guideline={[
                    'Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted',
                  ]}
                />
              )}
            </div>
          </div>

          {id !== 'undefined' && (
            <div className='card card-flush p-10'>
              <SappLabel label='Status' required isLabel />

              <div>
                {loading ? (
                  <Skeleton.Button active block size='large' />
                ) : (
                  <HookFormSelectAntd
                    name='status'
                    control={control}
                    placeholder='Status'
                    filterOption={true}
                    defaultValue={COURSE_STATUS_FORM[0].value}
                    classNameHeight='sapp-h-45px'
                    // suffixIcon={<ArrowDownIcon />}
                  >
                    {COURSE_STATUS_FORM.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active'>
              <div className='d-flex flex-column gap-7 gap-lg-10 sapp-mw-950px'>
                <div className='card card-flush'>
                  <div className='card-body pt-0'>
                    <div className='text-gray-800 fw-bold sapp-mb-24px sapp-fs-20px sapp-mt-32px sapp-line--height'>
                      General
                    </div>
                    <div className='sapp-mb-24px fv-row'>
                      {loading ? (
                        <LoadingInput />
                      ) : (
                        <HookFormTextField
                          control={control}
                          name='name'
                          placeholder=''
                          label='Course Name'
                          required
                        />
                      )}
                    </div>
                    <div className='sapp-mb-24px fv-row'>
                      <HookFormEditor
                        height={300}
                        placeholder=''
                        label='Describe'
                        name='description'
                        control={control}
                        className='w-100 fs-6'
                        math={true}
                        defaultValue={defaultEditor}
                        disabled={!disableTextfield?.can_edit_course_info?.description}
                        resourceLocation={RESOURCE_LOCATION.COURSE}
                        object_id={id ? id : undefined}
                        setDataFile={setFileEditor}
                      />
                    </div>
                    <div className='row sapp-mb-24px'>
                      <div className='col-md-6 fv-row fv-plugins-icon-container'>
                        {loading ? (
                          <LoadingInput />
                        ) : (
                          <HookFormTextField
                            control={control}
                            name='code'
                            placeholder=''
                            label='Code'
                            required
                          />
                        )}
                      </div>
                      <div className='col-md-6 fv-row fv-plugins-icon-container'>
                        {loading ? (
                          <LoadingInput />
                        ) : (
                          <HookFormTag
                            name='course_tags'
                            placeholder=''
                            control={control}
                            // selectOptions={tagCategory ?? []}
                            fetch={async ({
                              text,
                              page_index,
                              page_size,
                            }: {
                              text: string
                              page_index: string
                              page_size: string
                            }) => {
                              return await CommonAPI.getTags({
                                apiEndpoint: 'course_tags',
                                params: { text, page_index, page_size },
                              })
                            }}
                            disabled={!disableTextfield?.can_edit_course_info?.course_tags}
                            label='Tag'
                            // disabled={disableTextfield}
                            className='sapp-tag-form-custom'
                            classCustomTag='sapp-min-w--tag'
                            dataName='course_tags'
                          />
                        )}
                      </div>
                    </div>

                    <div className='row sapp-mb-24px'>
                      <div className='col-md-6 fv-row fv-plugins-icon-container'>
                        {loading ? (
                          <LoadingInput />
                        ) : (
                          <HookFormSelectAntd
                            name='course_category_ids'
                            control={control}
                            placeholder=''
                            label='Program (Category)'
                            required
                            showSearch
                            onChange={handleCourseCategoryChange}
                            disabled={!disableTextfield.can_edit_course_info.course_category_ids}
                            classNameHeight='sapp-h-45px'
                          >
                            {newCategory?.map((status: any) => (
                              <Option key={status.value} value={status.value}>
                                {status.label}
                              </Option>
                            ))}
                          </HookFormSelectAntd>
                        )}
                      </div>
                      <div className='col-md-6 fv-row fv-plugins-icon-container'>
                        {loading ? (
                          <LoadingInput />
                        ) : (
                          <HookFormSelectAntd
                            control={control}
                            name='subject_id'
                            label='Subject'
                            required
                            onSearch={(e: any) => {
                              if (e === undefined) {
                                return
                              }
                              debounceSearchSubject(e)
                            }}
                            handleNextPage={(e: any) =>
                              handleNextPageSubject({
                                name: e,
                                course_category_ids: watch('course_category_ids'),
                              })
                            }
                            showSearch
                            classNameHeight='sapp-h-45px'
                            disabled={!disableTextfield.can_edit_course_info.subject_id}
                            loading={loading}
                          >
                            {subjectCourse?.map((subject) => (
                              <Option key={subject.value} value={subject.value}>
                                {subject.label}
                              </Option>
                            ))}
                          </HookFormSelectAntd>
                        )}
                      </div>
                    </div>
                    <div className='row justify-content-between align-items-end'>
                      <div className='col-xl-6'>
                        {loading ? (
                          <LoadingInput />
                        ) : (
                          <HookFormSelectAntd
                            name='course_type'
                            control={control}
                            dropdownStyle={{ minWidth: 390 }}
                            filterOption={true}
                            label='Type'
                            placeholder=''
                            required
                            onChange={(val) => setTypeFoundation(val as any)}
                            disabled={!disableTextfield?.can_edit_course_info?.course_type}
                            // suffixIcon={<ArrowDownIcon />}
                            classNameHeight='sapp-h-45px'
                          >
                            {COURSE_TYPES.map((course) => (
                              <Option key={course.label} value={course.value}>
                                {course.label}
                              </Option>
                            ))}
                          </HookFormSelectAntd>
                        )}
                      </div>
                      {[typeFoundation, getValues('course_type')].includes(
                        COURSE_TYPES[0].value
                      ) && (
                        <div className='col-12 col-lg-6'>
                          <div className='col-12 d-flex justify-content-between'>
                            {newCourseLevel?.map((level: any) => (
                              <div className='mt-sm-8' key={level.id}>
                                <HookFormCheckBox
                                  control={control}
                                  name={level.validate}
                                  title={level.name}
                                  disabled={!disableTextfield?.can_edit_course_info?.course_levels}
                                  classNameTitle='sapp-checkbox-text-custom'
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='row'>
                      <div className='col-6' />
                      <div className='col-6'>
                        <ErrorMessage>{errors?.course_levels?.message as any}</ErrorMessage>
                      </div>
                    </div>
                    <div className='mt-10 fv-row'>
                      {loading ? (
                        <LoadingInput />
                      ) : (
                        <HookFormTextField
                          control={control}
                          name='pass_point'
                          placeholder=''
                          label='Pass Point'
                          postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                          required
                          // guideline={GUIDELINE_PASSPOINT}
                          disabled={!disableTextfield?.can_edit_course_info?.pass_point}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {[typeFoundation, getValues('course_type')].includes(COURSE_TYPES[3].value) && (
                  <div className='card card-flush'>
                    <div className='card-body pt-0'>
                      <div className='mt-0 fv-row'>
                        <SappLabel label='Foundation' isLabel required className='sapp-mt-32px' />

                        <div className='row justify-content-between'>
                          <div className='col-xl-6 col-md-12'>
                            <HookFormSelect
                              name='connectIA'
                              control={control}
                              defaultValue={defaultValueConnectWith('IA') || null}
                              allowClear
                              options={filterConnectIANormal}
                              showSearch
                              onSearch={
                                id
                                  ? undefined
                                  : (value: string) =>
                                      onSearchCourseFoundation(value, ECourseLevel.IA)
                              }
                              disabled={!disableTextfield?.can_edit_course_info?.course_connect_ia}
                              // suffixIcon={<ArrowDownIcon />}
                              onClear={() => setClearFoundationIA(null)}
                              label='IA'
                              className='sapp-h-45px'
                            />
                          </div>
                          <div className='col-xl-6 mt-xl-0 mt-md-5'>
                            <HookFormSelect
                              name='connectIIA'
                              control={control}
                              defaultValue={defaultValueConnectWith('IIA') || null}
                              allowClear
                              options={filterConnectIIANormal}
                              size='large'
                              className='sapp-h-45px'
                              showSearch
                              onSearch={
                                id
                                  ? undefined
                                  : (value: string) =>
                                      onSearchCourseFoundation(value, ECourseLevel.IIA)
                              }
                              disabled={!disableTextfield?.can_edit_course_info?.course_connect_iia}
                              // suffixIcon={<ArrowDownIcon />}
                              onClear={() => setClearFoundationIIA(null)}
                              label='IIA'
                            />
                          </div>
                        </div>

                        <div className='row justify-content-between mt-8'>
                          <div className='col-xl-6'>
                            <HookFormSelect
                              name='connectIB'
                              control={control}
                              defaultValue={defaultValueConnectWith('IB') || null}
                              allowClear
                              options={filterConnectIBNormal}
                              size='large'
                              className='sapp-h-45px'
                              showSearch
                              onSearch={
                                id
                                  ? undefined
                                  : (value: string) =>
                                      onSearchCourseFoundation(value, ECourseLevel.IB)
                              }
                              disabled={!disableTextfield?.can_edit_course_info?.course_connect_ib}
                              // suffixIcon={<ArrowDownIcon />}
                              onClear={() => setClearFoundationIB(null)}
                              label='IB'
                            />
                          </div>
                          <div className='col-xl-6 mt-xl-0 mt-md-5'>
                            <HookFormSelect
                              name='connectIIB'
                              control={control}
                              defaultValue={defaultValueConnectWith('IIB') || null}
                              allowClear
                              options={filterConnectIIBNormal}
                              size='large'
                              className='sapp-h-45px'
                              showSearch
                              onSearch={
                                id
                                  ? undefined
                                  : (value: string) =>
                                      onSearchCourseFoundation(value, ECourseLevel.IIB)
                              }
                              disabled={!disableTextfield?.can_edit_course_info?.course_connect_iib}
                              // suffixIcon={<ArrowDownIcon />}
                              onClear={() => setClearFoundationIIB(null)}
                              label='IIB'
                            />
                          </div>
                        </div>
                      </div>
                      {errors?.course_connections?.message && (
                        <ErrorMessage>{errors?.course_connections?.message as string}</ErrorMessage>
                      )}
                    </div>
                  </div>
                )}

                <div className='card card-flush'>
                  <div className='card-body pt-0'>
                    <SappLabel
                      label={`Điểm thành phần: ${totalPoint}%`}
                      isLabel
                      required
                      className='sapp-mt-32px'
                    />
                    <div className='row row-cols-2 g-8'>
                      <div className='col fv-row fv-plugins-icon-container '>
                        <div className='d-flex'>
                          <div className='w-100'>
                            {loading ? (
                              <LoadingInput />
                            ) : (
                              <HookFormTextField
                                control={control}
                                name='progress'
                                disabled={!disableTextfield?.can_edit_course_info?.setting}
                                postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                                type='number'
                                label='(1) Tiến độ học'
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='col fv-row fv-plugins-icon-container '>
                        <div className='d-md-flex'>
                          <div className='w-100'>
                            {loading ? (
                              <LoadingInput />
                            ) : (
                              <HookFormTextField
                                control={control}
                                name='activity_points'
                                disabled={!disableTextfield?.can_edit_course_info?.setting}
                                postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                                type='number'
                                label='(2) Graded Quiz'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col fv-row fv-plugins-icon-container '>
                        <div className='d-md-flex'>
                          <div className='w-100'>
                            {loading ? (
                              <LoadingInput />
                            ) : (
                              <HookFormTextField
                                control={control}
                                name='chapter_points'
                                disabled={!disableTextfield?.can_edit_course_info?.setting}
                                postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                                type='number'
                                label='(3) Chapter Test'
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='col fv-row fv-plugins-icon-container '>
                        <div className='d-md-flex'>
                          <div className='w-100'>
                            {loading ? (
                              <LoadingInput />
                            ) : (
                              <HookFormTextField
                                control={control}
                                name='topic_points'
                                disabled={!disableTextfield?.can_edit_course_info?.setting}
                                postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                                type='number'
                                label='(4) Topic Test'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* </div> */}

                      {/* <div className='row'> */}
                      <div className='col fv-row fv-plugins-icon-container '>
                        <div className='d-flex'>
                          <div className='w-100'>
                            {loading ? (
                              <LoadingInput />
                            ) : (
                              <HookFormTextField
                                control={control}
                                name='mid_term_points'
                                disabled={!disableTextfield?.can_edit_course_info?.setting}
                                postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                                type='number'
                                label='(5) Mid Test'
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='col fv-row fv-plugins-icon-container '>
                        <div className='d-flex'>
                          <div className='w-100'>
                            {loading ? (
                              <LoadingInput />
                            ) : (
                              <HookFormTextField
                                control={control}
                                name='final_test_points'
                                disabled={!disableTextfield?.can_edit_course_info?.setting}
                                postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                                type='number'
                                label='(6) Final Test'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {errors?.total_point?.message && (
                        <ErrorMessage>{errors?.total_point?.message as string}</ErrorMessage>
                      )}
                    </div>
                    <div className='alert alert-dismissible bg-light-alert-primary border border-alert-primary border-dashed d-flex flex-column flex-sm-row p-5 mb-0 align-items-center sapp-mt-32px'>
                      <i className='ki-duotone ki-information-5 fs-2hx text-success me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>

                      <div className='d-flex flex-column pe-0 pe-sm-10'>
                        <h5 className='mb-1 sapp-title-alert'>Chú ý về điểm các thành phần</h5>

                        <span className='sapp-content-alert'>
                          Tổng các điểm thành phần phải bằng 100% (tổng các mục 1+2+3+4+5+6=100%)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateAndUpdateCourseInfo
