import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FaqsAPI } from 'src/apis/short-course/faqs'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import PageLayouts from 'src/components/layout/PageLayouts'
import CreateAndUpdateFAQ from 'src/components/shop/faqs/CreateAndUpdateFAQ'
import QuestionTable from 'src/components/shop/faqs/QuestionTable'
import { IFAQDetail } from 'src/type/shop/faqs'

export interface IStep {
  title: string
  status: string
  link: string
}

const FaqDetail = () => {
  const [data, setData] = useState<IFAQDetail>()
  const { id } = useParams()
  const [newStep, setNewStep] = useState<IStep[]>([
    {
      title: 'Ticket',
      status: 'current',
      link: '',
    },
    {
      title: 'Questions',
      status: '',
      link: '',
    },
  ])

  const loadData = async (id?: string) => {
    if (id === 'undefined' || !id) return
    const res = await FaqsAPI.getFaq(id)
    if (res.success) {
      setData(res.data)
    }
  }

  useEffect(() => {
    loadData(id).catch((error) => console.error)
  }, [id])

  return (
    <PageLayouts
      titleTab='New Post'
      pageTitle=''
      breadcrumbs={[]}
      showSideBar={false}
      classNameLayout='pt-0'
      showFooter={false}
      backgroudColor='bg-white'
    >
      <div className='sapp-mw-1200px mx-auto px-2'>
        <div className='pb-0'>
          {newStep[0].status === 'current' && (
            <CreateAndUpdateFAQ setStep={setNewStep} faq={data} reload={loadData} step={newStep} />
          )}
          {newStep[1].status === 'current' && (
            <QuestionTable faq={data} step={newStep} setStep={setNewStep} />
          )}
        </div>
      </div>
    </PageLayouts>
  )
}

export default FaqDetail
