import React from 'react'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  ktCheck?: boolean
  checkTarget?: string
  checked?: boolean | undefined
  onChange?: ((e: React.ChangeEvent<any>) => void | undefined) | undefined
  disabled?: boolean
}

const SAPPCheckbox = ({
  ktCheck,
  checkTarget,
  checked,
  onChange,
  className,
  disabled,
  id,
  defaultChecked,
}: IProps) => {
  return (
    <div className={`sapp-checkbox form-check form-check-custom form-check-solid ${className}`}>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={ktCheck}
        data-kt-check-target={checkTarget}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
        id={id}
      />
    </div>
  )
}

export default SAPPCheckbox
