import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SappHeaderDetail from 'src/common/SappHeaderDetail'
import { useForm } from 'react-hook-form'
import TableTestQuizDetail from './TableTestQuizDetail'
import { ClassesApi } from 'src/apis/classes'
import HeaderTestQuizDetail from './HeaderTestQuizDetail'
import { IQuizDetail } from 'src/type/quiz'
import { useConfirm } from 'src/hooks/use-confirm'
import { Toaster } from 'react-hot-toast'
import { useParams } from 'react-router-dom'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  quiz_id: { id: string; name: string; grading_method: string }
  startDate?: string
  endDate?: string
  isDuration: boolean
}

const ModalTestQuizDetail = ({
  open,
  setOpen,
  quiz_id,
  startDate,
  endDate,
  isDuration,
}: IProps) => {
  const [quizDetail, setQuizDetail] = useState<IQuizDetail>()
  const [loading, setLoading] = useState(false)
  const { control, reset, setValue, watch } = useForm()
  const filterTestQuiz = {
    status: watch('status') || undefined,
    staff_id: watch('staff_id') || undefined,
  }

  const { id } = useParams()

  const { confirm, contextHolder } = useConfirm()

  const fetchTestQuizDetail = useCallback(
    async (pageIndex = 1, pageSize = 10, params = {}) => {
      setLoading(true)
      try {
        const res = await ClassesApi.getTestQuizDetail(quiz_id?.id, pageIndex, pageSize, {
          class_id: id,
        })
        setQuizDetail(res?.data)
      } finally {
        setLoading(false)
      }
    },
    [quiz_id?.id] // Dependencies array
  )

  useEffect(() => {
    if (!quiz_id?.id) return

    fetchTestQuizDetail(1, 10) // Fetch data with default pageIndex and pageSize
  }, [quiz_id?.id])

  const handleReset = () => {
    reset()
    fetchTestQuizDetail(1, 10)
    setValue('staff_id', null)
    setValue('status', null)
  }

  const handleCancelModal = () => {
    setOpen(false)
    reset()
    setValue('staff_id', null)
    setValue('status', null)
  }

  return (
    <>
      {contextHolder}
      <Modal
        scrollable={true}
        dialogClassName={`modal-dialog modal-dialog-scrollable modal-fullscreen modal-fullscreen-form`}
        fullscreen
        show={open}
        onHide={() => {}}
        enforceFocus={false}
      >
        <Toaster
          toastOptions={{ className: 'sapp-mw-1000px custom-toast', style: { zIndex: 1000 } }}
        />
        <div className='card'>
          <SappHeaderDetail
            title={quiz_id?.name}
            titleBreadCrumbs={`List Test/ Quiz (${quizDetail?.metadata?.total_records})`}
            showIcon
            handleCancel={handleCancelModal}
          />
        </div>
        <div className='py-8 px-10 overflow-auto'>
          <HeaderTestQuizDetail
            control={control}
            fetchTestQuiz={fetchTestQuizDetail}
            loading={loading}
            handleReset={handleReset}
            filterTestQuiz={filterTestQuiz}
            quiz_id={quiz_id}
          />
          <TableTestQuizDetail
            quizDetail={quizDetail}
            loading={loading}
            fetchTestQuiz={fetchTestQuizDetail}
            filterTestQuiz={filterTestQuiz}
            quiz_id={quiz_id}
            startDate={startDate}
            endDate={endDate}
            isDuration={isDuration}
          />
        </div>
      </Modal>
    </>
  )
}

export default ModalTestQuizDetail
