import { useEffect, useState } from 'react'
import SappModal from 'src/components/base/SappModal'
import { useConfirm } from 'src/hooks/use-confirm'
import { toast } from 'react-hot-toast'
import { RolesAPI } from 'src/apis/roles'
import { z } from 'zod'
import { VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormCheckBoxGroup from 'src/components/base/checkbox/HookFormCheckBoxGroup'
import { isEmpty } from 'lodash'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IProps {
  open: boolean
  setOpen: any
  id?: string | undefined
  roleData: any
  functionCallBack: () => void
}
type Role = {
  code: string
}
const AddRole = ({ open, setOpen, id, roleData, functionCallBack }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [permissionData, setPermissionData] = useState<Array<any>>([])

  const getPermissions = async () => {
    setLoading(true)
    try {
      const res = await RolesAPI.permissionsDetail()
      setPermissionData(Object.entries(res?.data))

      // Set default values for permissions
      if (id && id !== '') {
        const groupedByGroup: any = {}
        if (roleData?.permissions) {
          roleData?.permissions.forEach((item: any) => {
            const group = item.group

            if (!groupedByGroup[group]) {
              groupedByGroup[group] = []
            }

            groupedByGroup[group].push(item?.key)
          })
        }

        Object.entries(res?.data).map((group, index) => {
          const keyGroup = group?.[0]
          if (groupedByGroup?.[keyGroup]?.length > 0 && id) {
            setValue(`groups.${index}`, groupedByGroup?.[keyGroup])
          }
        })
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const createNewRole = async (params?: Object) => {
    setLoading(true)
    try {
      const res = await RolesAPI.addRole(params)
      if (res) {
        toast.success('Saved successfully!')
        functionCallBack()
        handleClose()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const editRole = async (params?: Object) => {
    setLoading(true)
    try {
      const roleId = {
        role_id: id,
      }
      Object.assign(params, roleId)
      const res = await RolesAPI.editRole(params)
      if (res) {
        toast.success('Update Successfully!')
        functionCallBack()
        handleClose()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  //validate for input
  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
    code: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Code', 1000)),
    groups: z.array(z.any()),
  })

  const { handleSubmit, control, setValue, reset } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const { confirm, contextHolder } = useConfirm()

  const handleClose = () => {
    setOpen(() => {
      reset()
      setValue('groups', undefined)
      setValue('name', undefined)
      setValue('code', undefined)
      return false
    })
    setPermissionData([])
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn hủy không?',
      onClick: handleClose,
    })
  }

  useEffect(() => {
    if (open) {
      if (id) {
        setValue('name', roleData?.name || '')
        setValue('code', roleData?.code || '')
      }
      getPermissions()
    }
  }, [open])

  // function submit form
  const onSubmit = (data: any) => {
    const mappedArray = data.groups.flat().filter((element: any) => element !== undefined)
    const paramsUpdate = {
      name: data.name,
      code: data.code,
      description: '',
      permissions: mappedArray,
    }
    if (id) {
      editRole(paramsUpdate)
    } else {
      createNewRole(paramsUpdate)
    }
    if (!roleData?.roles?.some((role: Role) => role?.code === data?.code)) {
      return
    } else {
      handleClose()
    }
  }
  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        classBody={'p-0 modal-body'}
        dialogClassName={'m-0 modal-dialog-scrollable modal-fullscreen'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between flex-wrap align-items-center py-6 px-10 border-gray-300 border-bottom'>
          <div className='sapp-title-modal-create-class text-gray-800'>Add Role</div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex'>
              <ButtonSecondary
                title='Cancel'
                onClick={handleCancel}
                loading={loading}
                size='small'
                className='me-3'
              />
            </div>
            <div className='d-flex'>
              <ButtonPrimary
                title='Save'
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                size='small'
              />
            </div>
          </div>
        </div>
        <div className='p-10'>
          <div className='row mb-8'>
            <div className='col-lg-3 fv-row sapp-add-role-label'>
              <HookFormTextField
                name='name'
                control={control}
                required
                placeholder='Role name'
                label='Role name'
              />
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col-lg-3 fv-row sapp-add-role-label'>
              <HookFormTextField
                name='code'
                control={control}
                required
                placeholder='Code'
                label='Code'
                disabled={!isEmpty(id)}
              />
            </div>
          </div>
          {permissionData.map((group, index) => {
            const keyGroup = group?.[0]
            return (
              <div className='row mb-3' key={keyGroup || index}>
                <label className='fs-6 fw-semibold form-label mb-3 text-capitalize'>
                  {group?.[0]
                    .replace(/(_management)|_/g, ' ')
                    .replace(/groups/g, 'Nhóm gửi thông báo')}
                </label>
                <div>
                  <div className='row'>
                    <HookFormCheckBoxGroup
                      control={control}
                      name={`groups.${index}` as const}
                      options={group?.[1]}
                      className={'row'}
                      classItem={'col-lg-2 mb-5'}
                      showToolTip={true}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </SappModal>
    </>
  )
}
export default AddRole
