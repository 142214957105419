import { IResponse } from 'preview-activity/dist/shared/interfaces'
import { fetcher, fetchFormPutData } from 'src/services/request'
import {
  ISupportGroupDetail,
  ISupportGroupList,
  ISupporterError,
  ISupporterInGroup,
  ISupporterList,
} from 'src/type/comment'

interface IData {
  name: string
  display_name: string
  course_category_id?: string
  subject_id?: string
}

export class CommentAPI {
  static getSupportGroupList({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<ISupportGroupList>> {
    return fetcher(`support-groups?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getSupportGroup(id: string): Promise<IResponse<ISupportGroupDetail>> {
    return fetcher(`/support-groups/${id}`)
  }

  static createSupportGroup(data: {}): Promise<IResponse<ISupportGroupDetail>> {
    return fetcher(`/support-groups`, {
      data,
      method: 'POST',
    })
  }

  static editSupportGroup(id: string, data: IData): Promise<IResponse<ISupportGroupDetail>> {
    return fetcher(`/support-groups/${id}`, {
      data,
      method: 'PUT',
    })
  }

  static deleteSupportGroup(id: string): Promise<IResponse<ISupportGroupDetail>> {
    return fetcher(`/support-groups/${id}`, {
      method: 'DELETE',
    })
  }

  static getSupporterList({
    id,
    pageIndex,
    pageSize,
    params,
  }: {
    id: string
    pageIndex: number
    pageSize: number
    params?: Object
  }): Promise<IResponse<ISupporterList>> {
    return fetcher(
      `/support-groups/${id}/supporters?page_index=${pageIndex}&page_size=${pageSize}`,
      { params }
    )
  }

  static createSupporter(
    id: string,
    data: { supporter_ids: string[] }
  ): Promise<IResponse<ISupporterError>> {
    return fetcher(`/support-groups/${id}/supporters`, {
      data,
      method: 'POST',
    })
  }

  static deleteSupporter(
    id: string,
    data: { supporter_ids: string }
  ): Promise<IResponse<ISupporterInGroup>> {
    return fetcher(`/support-groups/${id}/supporters`, {
      method: 'DELETE',
      data,
    })
  }

  static updateAvatar(
    id: string,
    data: {
      avatar: any
    }
  ) {
    const formData = new FormData()
    formData.append('avatar', data.avatar)
    formData.append('is_remove', 'false')
    return fetchFormPutData({ url: `/support-groups/${id}/avatar`, formData })
  }
}
