import { UseFormReturn } from 'react-hook-form'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { MAX_IMAGE_PREVIEW_META } from 'src/constants'
import { Select } from 'antd'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import styles from './blogs.module.scss'
const { Option } = Select

type IAdvancedProps = {
  useForm: UseFormReturn<any>
}

const Advanced = ({ useForm }: IAdvancedProps) => {
  const { control, watch, setValue } = useForm
  const activeVideo = watch('robot_meta.active_video_preview')
  const activeImage = watch('robot_meta.active_image_preview')
  const activeSnippet = watch('robot_meta.active_snippet')
  const index = watch('robot_meta.index')

  /**
   * function xử lý chọn index trong meta
   */
  const handleIndex = () => {
    setValue('robot_meta.index', !index)
  }
  return (
    <>
      <div className='mt-2'>
        <SappLabel label='Robots Meta' />

        {/* Index / No Index */}
        <div className='container'>
          <div className='row'>
            <div className='col-sm ps-0 d-flex align-items-center gap-3'>
              <SAPPRadio checked={index} onChange={handleIndex} />
              <SappLabel
                className={`${index ? 'text-gray-800' : 'sapp-text-disable'} sapp-fs-14 ${
                  styles.robotsMetaLabel
                }`}
                label='Index'
              />
            </div>
            <div className='col-sm ps-0 d-flex align-items-center gap-3'>
              <SAPPRadio className='mr-4' checked={!index} onChange={handleIndex} />
              <SappLabel
                className={`${!index ? 'text-gray-800' : 'sapp-text-disable'} sapp-fs-14 ${
                  styles.robotsMetaLabel
                }`}
                label='No Index'
              />
            </div>
          </div>
        </div>

        {/* No Follow / No Archive */}
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-sm ps-0'>
              <HookFormCheckBox
                control={control}
                name='robot_meta.no_follow'
                title='No Follow'
                classLabel={styles.robotsMetaLabel}
              />
            </div>
            <div className='col-sm ps-0'>
              <HookFormCheckBox
                control={control}
                name='robot_meta.no_archive'
                title='No Archive'
                classLabel={styles.robotsMetaLabel}
              />
            </div>
          </div>
        </div>

        {/* No Snippet / No Image Index */}
        <div className='container mt-5 mb-10'>
          <div className='row'>
            <div className='col-sm ps-0'>
              <HookFormCheckBox control={control} name='robot_meta.no_snippet' title='No Snippet' />
            </div>
            <div className='col-sm ps-0'>
              <HookFormCheckBox
                control={control}
                name='robot_meta.no_image_index'
                title='No Image Index'
              />
            </div>
          </div>
        </div>

        <SappLabel label='Advanced Robots Meta' />
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 ps-0 sapp-h-45px d-flex align-items-center'>
              <HookFormCheckBox
                control={control}
                name='robot_meta.active_snippet'
                title='Max Snippet'
              />
            </div>
            <div className='col-sm-8 pe-0'>
              <HookFormTextField
                control={control}
                name='robot_meta.max_snippet'
                className='sapp-h-45px'
                disabled={!activeSnippet}
              />
            </div>
          </div>
        </div>

        <div className='container mt-4'>
          <div className='row'>
            <div className='col-sm-4 ps-0 sapp-h-45px d-flex align-items-center'>
              <HookFormCheckBox
                control={control}
                name='robot_meta.active_video_preview'
                title='Max Video Preview'
              />
            </div>
            <div className='col-sm-8 pe-0'>
              <HookFormTextField
                control={control}
                name='robot_meta.max_video_preview'
                className='sapp-h-45px'
                disabled={!activeVideo}
              />
            </div>
          </div>
        </div>

        <div className='container mt-4 mb-10'>
          <div className='row'>
            <div className='col-sm-4 ps-0 sapp-h-45px d-flex align-items-center'>
              <HookFormCheckBox
                control={control}
                name='robot_meta.active_image_preview'
                title='Max Image Preview'
              />
            </div>
            <div className='col-sm-8 pe-0'>
              <HookFormSelectAntd
                size='large'
                name='robot_meta.max_image_preview'
                control={control}
                dropdownStyle={{ minWidth: 390 }}
                filterOption={true}
                className='sapp-h-45px'
                disabled={!activeImage}
              >
                {MAX_IMAGE_PREVIEW_META.map((data) => (
                  <Option key={data.label} value={data.value}>
                    {data.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
          </div>
        </div>

        <div className='mb-10 fv-row'>
          <HookFormTextField
            name='canonical_url'
            control={control}
            label='Canonical URL'
            placeholder='https://sapp.edu.vn/'
            className='sapp-h-45px'
          />
        </div>
      </div>
    </>
  )
}

export default Advanced
