import { useEffect, useState } from 'react'
import AddMore from 'src/common/AddMore'
import ActionCell from 'src/components/base/action/ActionCell'
import CreateAndUpdateQuestion from './CreateAndUpdateQuestion'
import { IFAQDetail, IQuestionDetail, IQuestionFAQList } from 'src/type/shop/faqs'
import { FaqsAPI } from 'src/apis/short-course/faqs'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { PageLink } from 'src/constants'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { useConfirm } from 'src/hooks/use-confirm'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { IStepProgress } from 'src/type'
import Processbar from 'src/components/courses/course-detail/progress-bar'

interface IProps extends IStepProgress {
  faq?: IFAQDetail
}

interface NewAndUPdate {
  data: IQuestionDetail | null
  isOpen: boolean
  type: 'create' | 'edit'
}

const QuestionFAQ = ({ faq, setStep, step }: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const [openNewAndUpdate, setOpenNewAndUpdate] = useState<NewAndUPdate>({
    data: null,
    isOpen: false,
    type: 'create',
  })
  const [questionList, setQuestionList] = useState<IQuestionFAQList>()
  const navigate = useNavigate()

  /**
   * Handle confirm before closing
   */
  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => setOpenNewAndUpdate({ isOpen: false, data: null, type: 'create' }),
    })
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.SHOP_FAQS),
    })
  }

  const handleBackPage = () => {
    navigate(PageLink.SHOP_FAQS)
  }

  /**
   * Handle get data to edit question
   */
  const handleEdit = (data: IQuestionDetail) => {
    setOpenNewAndUpdate({ isOpen: true, data, type: 'edit' })
  }

  /**
   * Handle set state to add new question
   */
  const handleAddNew = () => {
    setOpenNewAndUpdate({ isOpen: true, data: null, type: 'create' })
  }

  /**
   * Handle delete question of faq
   */
  const handleDelete = async (data: IQuestionDetail) => {
    try {
      if (!faq?.id) return
      await FaqsAPI.deleteQuestionFAQ(faq?.id, data.id)
      toast.success('Question updated successfully!')
      await loadData(faq.id, 1, 10)
    } catch (error) {
      // do nothing
    }
  }

  /**
   * Get question for faq
   * @param {string} id: id of faq
   * @param {number} page_index: current page
   * @param {number} page_size: size of page
   * @param {Object} params: filter variable
   */
  const loadData = async (
    faqId: string,
    page_index: number = 1,
    page_size: number = 10,
    params?: Object
  ) => {
    const res = await FaqsAPI.getQuestionFaqList({
      faqId,
      page_index,
      page_size,
      params,
    })
    setQuestionList(res.data)
  }
  /**
   * Handle pagination
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    faq?.id && loadData(faq?.id, page_index, page_size)
  }

  useEffect(() => {
    faq?.id && loadData(faq.id, 1, 10)
  }, [])

  return (
    <>
      {contextHolder}
      <div className='card card-flush'>
        <Processbar
          step={step}
          setNewStep={setStep}
          onCancel={handleCancel}
          onClick={handleBackPage}
          okButtonCaption='Finish'
          cancelButtonCaption='FAQs List'
          className='mw-500px'
        />
        <div className='card-body pt-10'>
          <AddMore addNew={handleAddNew} />
          <div className='my-5' style={{ border: '1px dashed #DBDFE9' }} />
          {questionList?.questions.map((data, index) => (
            <>
              <div className='d-flex justify-content-between px-5'>
                <div className='d-flex align-items-center'>
                  <div
                    onClick={() => handleEdit(data)}
                    className='text-gray-800 fw-semibold text-hover-primary mb-1 sapp-fs-14 cursor-pointer'
                  >
                    {data.title}
                  </div>
                </div>
                <div>
                  <ActionCell className='sapp-h-50px'>
                    <div onClick={() => handleEdit(data)} className='menu-item px-3'>
                      <div className='menu-link px-3'>Edit</div>
                    </div>
                    <div onClick={() => handleDelete(data)} className='menu-item px-3'>
                      <div className='menu-link px-3'>Delete</div>
                    </div>
                  </ActionCell>
                </div>
              </div>
              <div className='my-5' style={{ border: '1px dashed #DBDFE9' }} />
            </>
          ))}
          <CreateAndUpdateQuestion
            type={openNewAndUpdate.type}
            faqId={faq?.id}
            question={openNewAndUpdate.data}
            open={openNewAndUpdate.isOpen}
            handleClose={handleClose}
            setOpenNewAndUpdate={setOpenNewAndUpdate}
            reload={loadData}
          />
          <div className='mt-10'>
            <PagiantionSAPP
              currentPage={questionList?.meta?.page_index || 1}
              pageSize={questionList?.meta?.page_size || 10}
              totalItems={questionList?.meta?.total_records}
              handlePaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionFAQ
