import React, {Dispatch, SetStateAction, useEffect} from 'react'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import SappTable from 'src/components/base/SappTable'
import {KTCardBody} from 'src/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {IAuthorList} from 'src/type/shop/author'
import {INewOrUpdatePopup} from 'src/type/shop/common'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import {AuthorAPI} from 'src/apis/short-course/author'
import toast from 'react-hot-toast'
import ShopActionBox from '../shared/ShopActionBox'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  htmlToRaw,
} from 'src/utils'

// define headers
const headers = [
  {
    label: 'name',
    className: 'min-w-175px',
  },
  {
    label: 'Descriptions',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Count',
    className: 'min-w-75px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

interface IQueryPrams {
  name: string | null
  sort_type: string | null
  fromDate: Date | string
  toDate: Date | string
}

interface IProps {
  authorList: IAuthorList | undefined
  setAuthorList: Dispatch<SetStateAction<any>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  loadData: (page_index: number, page_size: number, object?: Object) => void
  queryParam: IQueryPrams
  getParams: any
  reloadPage: () => void
  setOpenNewOrUpdate: Dispatch<SetStateAction<INewOrUpdatePopup>>
}

const TableAuthor = ({
  authorList,
  setAuthorList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  loadData,
  queryParam,
  getParams,
  reloadPage,
  setOpenNewOrUpdate,
}: IProps) => {
  const navigate = useNavigate()

  const showQueryParams =
    queryParam.name || queryParam.sort_type || queryParam.fromDate || queryParam.toDate

  /**
   * Một hàm dùng để change params trên router
   */
  const filterParamsPagination = () => {
    const dateQueryFromDate =
      queryParam?.fromDate instanceof Date ? getDateInfo(queryParam.fromDate) : null
    const dateQueryToDate =
      queryParam?.toDate instanceof Date ? getDateInfo(queryParam.toDate) : null
    return cleanParamsAPI(
      getParams(
        queryParam.name ?? '',
        queryParam.sort_type ?? '',
        queryParam.fromDate && dateQueryFromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        queryParam.toDate && dateQueryToDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showQueryParams ? 'updated_at' : 'created_at'
      )
    )
  }

  /**
   * Call API get authors
   */
  useEffect(() => {
    setLoading(true)
    loadData(1, 10, cleanedParams)
  }, [])

  /**
   * Một hàm dùng để đổi params trên router
   * @param {number} currentPage  - trang page hiện tại
   * @param {number} size  - lượng bản ghi của page
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      name: queryParam.name ?? '',
      sort_type: queryParam.sort_type ?? '',
      fromDate: queryParam.fromDate instanceof Date ? formatDate(queryParam.fromDate) : '',
      toDate: queryParam.toDate instanceof Date ? formatDate(queryParam.toDate) : '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  /**
   * Call API khi change pagination
   * @param {number} page_index  - trang page hiện tại
   * @param {number} page_size  - lượng bản ghi của page
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    loadData(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  /**
   * handle open Update popup
   * @param {string} id  - id của author muốn chỉnh sửa
   */
  const handleOpenUpdate = async (id: string) => {
    try {
      const res = await AuthorAPI.getAuthor(id)
      setOpenNewOrUpdate({type: 'edit', data: res.data, isOpen: true})
    } catch (error) {
      // do nothing
    }
  }

  /**
   * handle to delete author
   * @param {string} id  - id của author muốn xoá
   */
  const deleteAuthor = async (id: string) => {
    try {
      await AuthorAPI.deleteAuthor(id)
      toast.success('Author deleted successfully!')
      reloadPage()
    } catch (error: any) {
      // do nothing
    }
  }
  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        data={authorList?.blogAuthors}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {authorList?.blogAuthors?.map((author, index) => {
          const isChecked = checkedList.includes(author.id)
          return (
            <tr key={index}>
              <td>
                <SAPPCheckbox
                  checkTarget='#kt_table_users .form-check-input'
                  checked={isChecked}
                  onChange={() => {
                    toggleCheck(author.id)
                  }}
                />
              </td>
              <td className='text-start'>
                <div
                  onClick={() => {
                    handleOpenUpdate(author.id)
                  }}
                  className='d-flex align-items-center sapp-cursor-pointer'
                >
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3 '>
                    <div className='symbol-label'>
                      <img src={author?.avatar?.ORIGIN} alt='author' className='w-100 h-100' />
                    </div>
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='text-gray-800 fw-bold text-hover-primary mb-1'>
                      {author.name}
                    </div>
                  </div>
                </div>
              </td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>
                  {author.describe ? htmlToRaw(author.describe) : ''}
                </div>
              </td>
              <td className='text-start'>{author?.blog_count}</td>
              <td className='text-start'>
                <ShopActionBox
                  id={author.id}
                  setLoading={setLoading}
                  reloadPage={reloadPage}
                  onDelete={deleteAuthor}
                  onEdit={handleOpenUpdate}
                />
              </td>
            </tr>
          )
        })}
      </SappTable>
      <PagiantionSAPP
        currentPage={authorList?.metadata?.page_index || 1}
        pageSize={authorList?.metadata?.page_size || 10}
        totalItems={authorList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableAuthor
