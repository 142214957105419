import { Dispatch, SetStateAction, useEffect } from 'react'
import SappDrawer from 'src/components/base/SappDrawer'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { VALIDATION_FIELD } from 'src/constants'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import MenuTabsSection from 'src/pages/shop/blogs/MenuTabs'
import Advanced from 'src/pages/shop/blogs/Advanced'
import { INewOrUpdatePopup, RobotMeta } from 'src/type/shop/common'
import { ITag, ITagUpdate } from 'src/type/shop/tag'
import { z } from 'zod'
import { metaValidationSchema } from 'src/utils/shop/validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { TagAPI } from 'src/apis/short-course/tags'
import toast from 'react-hot-toast'
import { RESOURCE_LOCATION } from '../base/upload-file/ModalUploadFile/UploadFileInterface'

interface IProps {
  open: boolean
  handleClose: () => void
  reloadPage: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  setOpenNewCategory: Dispatch<SetStateAction<INewOrUpdatePopup>>
  type?: 'create' | 'edit'
  tag?: ITag
}

type ISubmittedData = {
  name: string
  slug: string
  describe?: string
  meta_title?: string
  meta_description?: string
  focus_keyword?: string
  robot_meta?: RobotMeta
  canonical_url?: string
}

const CreateAndUpdateTags = ({
  open,
  reloadPage,
  handleClose,
  setLoading,
  setOpenNewCategory,
  type = 'create',
  tag,
}: IProps) => {
  const initialValue = {
    name: '',
    slug: '',
    describe: '',
    meta_title: '',
    meta_description: '',
    focus_keyword: '',
    canonical_url: '',
  }
  /**
   * Validation cho các trường trong HookForm
   */
  const validationSchema = z.object({
    name: z.string().min(1, VALIDATION_FIELD),
    slug: z.string().optional(),
    describe: z.string().optional(),
    meta_title: z.string().min(1, VALIDATION_FIELD),
    meta_description: z.string().min(1, VALIDATION_FIELD),
    focus_keyword: z.string().min(1, VALIDATION_FIELD),
    robot_meta: metaValidationSchema,
    canonical_url: z.string().optional(),
  })

  /**
   * Hàm dùng dùng khi khởi tạo form mới hoặc cập nhật lại data của form
   */
  const handleClearData = () => {
    if (type === 'edit' && tag) {
      const robotMeta = tag?.robot_meta ? JSON.parse(tag?.robot_meta) : ''
      setValue('name', tag?.name ?? '')
      setValue('slug', tag?.slug ?? '')
      setValue('describe', tag?.describe ?? '')
      setValue('meta_title', tag?.meta_title ?? '')
      setValue('meta_description', tag?.meta_description ?? '')
      setValue('focus_keyword', tag?.focus_keyword ?? '')
      setValue('robot_meta', robotMeta)
      setValue('canonical_url', tag?.canonical_url ?? '')
      Object.keys(robotMeta).forEach((key) => {
        setValue(`robot_meta.${key as keyof RobotMeta}`, robotMeta[key])
      })
    } else {
      reset()
    }
  }

  /**
   * Handle khởi tạo Form khi mở popup
   * @param{author} - data của author nếu có
   * @param{open} - Để hanle việc clear Form khi form không có category data
   */
  useEffect(() => {
    handleClearData()
  }, [tag, open])

  const useFormProp = useForm<ITagUpdate>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValue,
  })
  const { control, handleSubmit, reset, setValue, getValues } = useFormProp

  /**
   * Handle submit action of form - xử lý tạo mới hoặc cập nhật
   * @param {ISubmittedData} data  - Data của form
   */
  const onSubmit = async (data: ISubmittedData) => {
    try {
      setLoading(true)
      if (type === 'edit' && tag) {
        await TagAPI.updateTag({ id: tag.id, data })
        toast.success('Tag updated successfully!')
      } else {
        await TagAPI.createTag({ data })
        toast.success('Tag created successfully!')
      }
      reset()
      handleClearData()
      reloadPage()
      setOpenNewCategory({ isOpen: false, data: null, type: 'create' })
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  return (
    <SappDrawer
      open={open}
      title='Add New Tag'
      handleSubmit={handleSubmit(onSubmit)}
      width='50%'
      handleClose={handleClose}
      okButtonCaption='Save'
      rootClassName='create-shop-root'
      classNameHeader='create-shop-header'
    >
      <HookFormTextField control={control} name='name' label='Name' required />
      <div className='my-10'>
        <HookFormTextField control={control} name='slug' label='Slug' />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Describe'
          control={control}
          name='describe'
          math={true}
          height={500}
          defaultValue={getValues('describe')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
          required
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='meta_title' label='Meta Title' required />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Meta Description'
          control={control}
          name='meta_description'
          math={true}
          height={500}
          defaultValue={getValues('meta_description')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
          required
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='focus_keyword' label='Focus Keyword' required />
      </div>
      <MenuTabsSection
        typeofSection={'Advanced'}
        SectionName={'Advanced'}
        tabs={[
          {
            id: 'tab1',
            title: 'Advanced',
            content: <Advanced useForm={useFormProp} />,
          },
        ]}
      />
    </SappDrawer>
  )
}

export default CreateAndUpdateTags
