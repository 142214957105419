import React, { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import { PageLink } from 'src/constants'
import OverviewProfile from './overview-profile'
import SettingProfile from './setting-profile'
import { IStudentDetail } from 'src/type/students'
import MyRole from './my-role'

interface IProps {
  userDetail: IStudentDetail | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setProfile: Dispatch<SetStateAction<any>>
  getProfileDetail: Dispatch<SetStateAction<any>>
}

const RenderProfile = ({ userDetail, loading, setProfile, getProfileDetail }: IProps) => {
  const location = useLocation()

  const renderProfile = () => {
    switch (location.pathname) {
      case PageLink.PROFILE_OVERVIEW:
        return <OverviewProfile userDetail={userDetail} loading={loading} setProfile={setProfile} />
      case PageLink.PROFILE_SETTING:
        return (
          <SettingProfile
            userDetail={userDetail}
            setProfile={setProfile}
            getProfileDetail={getProfileDetail}
          />
        )
      case PageLink.PROFILE_MY_ROLE:
        return <MyRole />
      default:
        return <OverviewProfile userDetail={userDetail} loading={loading} setProfile={setProfile} />
    }
  }
  return <>{renderProfile()}</>
}

export default RenderProfile
