import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { EntranceApi } from 'src/apis/entrance-test'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { PageLink, VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import { TITLE_OPTIONS_ENTRANCE } from 'src/constants/entrancetest'
import { useConfirm } from 'src/hooks/use-confirm'
import { z } from 'zod'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { debounce, isUndefined } from 'lodash'
import { ISubject } from 'src/type/subject'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import { EventAPI } from 'src/apis/events'
import { EventTestAPI } from 'src/apis/event-test'

const TestInfo = ({
  handleGotoStep,
  listInfo,
  setStep,
  step,
  loading,
  setLoading,
}: {
  handleGotoStep: (id: number) => void
  listInfo: any
  setStep: Dispatch<SetStateAction<any>>
  step: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<any>>
}) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const { id } = useParams()

  const validationInformationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, { message: VALIDATION_FIELD })
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
    course_category_id: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, { message: VALIDATION_FIELD }),
    event_id: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, { message: VALIDATION_FIELD }),
    started_at: z.date().or(z.string()),
    finished_at: z.date().or(z.string()),
  })

  const {
    handleSubmit: handleSubmitInformation,
    control: controlInformation,
    setValue: setValueInformation,
    watch,
  } = useForm({
    resolver: zodResolver(validationInformationSchema),
  })

  const { Option } = Select
  const [categoryTest, setCategoryTest] = useState<[]>([])

  const fetchCategory = async () => {
    try {
      const response = await EntranceApi.getCategory({ page_index: 1, page_size: 50 })
      const _categoryTest = response.data
      setCategoryTest(_categoryTest.course_categories)
    } catch (error) {}
  }

  const newCategory = categoryTest?.map((category: any) => ({
    label: category.name,
    value: category.id,
  }))

  useEffect(() => {
    fetchCategory()
  }, [])

  const handleCancelInformation = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(PageLink.EVENT_TEST)
      },
    })
  }

  const onSubmit = async (data: any) => {
    setLoading(true)

    try {
      if (id === 'undefined') {
        const res = await EventTestAPI.createEventTest({
          ...data,
          grading_method: 'AUTO',
          limit_count: 1,
          quiz_type: 'EVENT_TEST',
          quiz_for: 'EVENT_TEST',
          is_graded: false,
          is_limited: true,
        })
        if (res) {
          navigate(`${PageLink.CREATE_EVENT_TEST}/${res.data.id}`)
          toast.success('Event Test created successfully!')
        }
      } else {
        const res = await EventTestAPI.editEventTest(id, data)
        if (res) {
          navigate(`${PageLink.CREATE_EVENT_TEST}/${res.data.id}`)
          toast.success('Event Test updated successfully!')
        }
      }
      handleGotoStep(1)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id !== 'undefined' && id && !isUndefined(listInfo)) {
      const fetchData = async (id: string) => {
        setLoading(true)
        try {
          setValueInformation('name', listInfo?.name)
          setValueInformation('event_id', listInfo?.event_id)
          setValueInformation('course_category_id', listInfo?.course_category_id)
          setValueInformation('started_at', listInfo?.started_at)
          setValueInformation('finished_at', listInfo?.finished_at)
          // Dùng khi vừa vào màn edit, cần get list subject theo course_category_id hiện tại
          if (watch('course_category_id')) {
            await getSubjects({ params: { course_category_id: watch('course_category_id') } })
          }
        } catch (error) {
        } finally {
          setLoading(false)
        }
      }
      fetchData(id)
    }
  }, [id, listInfo])

  /**
   * @description state lưu giá trị của subject
   */
  const [subjects, setSubjects] = useState<any>()
  const requestOngoingRef = useRef(false)

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await EventAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const subjectCourse = subjects?.events?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
    code: subject.code,
  }))

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.events.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  const getSubjects = async ({ params }: any) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_id')) {
      getSubjects({ params: { name: e, course_category_id: watch('course_category_id') } })
    }
  }, 500)

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValueInformation('subject_id', '')
    if (watch('course_category_id') !== 'all') {
      await getSubjects({ params: { course_category_id: watch('course_category_id') } })
    }
  }

  return (
    <>
      <Processbar
        step={step}
        setNewStep={setStep}
        onCancel={handleCancelInformation}
        onClick={handleSubmitInformation((e) => {
          onSubmit(e)
        })}
        showCancel
        className='sapp-mw-700px'
        loading={loading}
      />
      <div className='px-6 pb-10 pt-0'>
        {contextHolder}
        <div className='h-xl-100'>
          <HookFormTextField
            label={TITLE_OPTIONS_ENTRANCE.testname}
            required
            control={controlInformation}
            name='name'
            skeleton={loading}
            disabled={id !== 'undefined' && !listInfo?.state?.condition?.can_edit_event_test?.name}
          />
          <Row className='mt-10'>
            <Col md={6}>
              <div className='position-relative'>
                <HookFormSelectAntd
                  required
                  control={controlInformation}
                  name='course_category_id'
                  placeholder=' '
                  label={TITLE_OPTIONS_ENTRANCE.course_category_id}
                  onChange={handleCourseCategoryChange}
                  skeleton={loading}
                  disabled={
                    id !== 'undefined' &&
                    !listInfo?.state?.condition?.can_edit_event_test?.course_category_id
                  }
                >
                  {newCategory?.map((item: any) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </Col>
            <Col md={6} className='mb-10'>
              <div className='position-relative'>
                <HookFormSelectAntd
                  control={controlInformation}
                  name='event_id'
                  label='Event'
                  required
                  onSearch={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    debounceSearchSubject(e)
                  }}
                  handleNextPage={(e: any) =>
                    handleNextPageSubject({
                      name: e,
                      course_category_id: watch('course_category_id'),
                    })
                  }
                  showSearch
                  classNameHeight='sapp-h-45px'
                  loading={loading}
                  disabled={
                    id !== 'undefined' &&
                    !listInfo?.state?.condition?.can_edit_event_test?.course_category_id
                  }
                >
                  {subjectCourse?.map((subject: any) => (
                    <Option key={subject.value} value={subject.value}>
                      {subject.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-8'>
              <HookFormDateTime
                control={controlInformation}
                name='started_at'
                label='Start At'
                required
                disabled={
                  id !== 'undefined' && !listInfo?.state?.condition?.can_edit_event_test?.started_at
                }
                format='DD/MM/YYYY HH:mm'
                showTime
              />
            </Col>
            <Col md={6} className='mb-10'>
              <HookFormDateTime
                control={controlInformation}
                name='finished_at'
                label='Finished At'
                required
                disabled={
                  id !== 'undefined' &&
                  !listInfo?.state?.condition?.can_edit_event_test?.finished_at
                }
                format='DD/MM/YYYY HH:mm'
                showTime
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TestInfo
