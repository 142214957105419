import { fetchFormData, fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { IBlog, IBlogCategory, IBlogList, IBlogUpdate } from 'src/type/shop/blog'

export class ShopBlogAPI {
  static getBlogs({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IBlogList>> {
    return fetcher(`shop-blogs?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getBlog(id: string): Promise<IResponse<IBlog>> {
    return fetcher(`/shop-blogs/${id}`)
  }

  static uploadThumbnail = (data: { blog_id: string; thumbnail: File }) => {
    const formData = new FormData()
    formData.append('blog_id', data.blog_id)
    formData.append('thumbnail_url', data.thumbnail)
    return fetchFormData({ url: `/shop-blogs/${data.blog_id}/thumbnail`, formData })
  }

  static createBlog({ data }: { data: IBlogUpdate }): Promise<IResponse<IBlogCategory>> {
    return fetcher('/shop-blogs', { method: 'POST', data: data })
  }

  static updateBlog({
    id,
    data,
  }: {
    id: string
    data: IBlogUpdate
  }): Promise<IResponse<IBlogCategory>> {
    return fetcher(`/shop-blogs/${id}`, { method: 'PUT', data: data })
  }

  static updateBlogStatus(id: string, status: string): Promise<IResponse<IBlogCategory>> {
    return fetcher(`/shop-blogs/${id}/status`, { method: 'PUT', data: { status } })
  }

  static deleteBlog(id: string) {
    return fetcher(`shop-blogs/${id}`, {
      method: 'DELETE',
    })
  }

  static duplicateBlog(id: string) {
    return fetcher(`shop-blogs/duplicate`, {
      method: 'POST',
      data: {
        id,
      },
    })
  }

  static deleteBulkBlogs(shop_blog_ids: string[]) {
    return fetcher(`shop-blogs/bulk-delete`, {
      method: 'POST',
      data: {
        shop_blog_ids,
      },
    })
  }
}
