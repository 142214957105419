import { VALIDATION_FIELD } from 'src/constants'
import { PROGRAM } from 'src/type'
import { z } from 'zod'

const examSchemaCFA = z.object({
  name: z.date({ message: VALIDATION_FIELD }),
  registration_opening_date: z.date().nullable().optional(),
  early_registration_deadline: z.date().nullable().optional(),
  standard_registration_deadline: z.date().nullable().optional(),
  exam_subject: z
    .array(
      z.object({
        subject_id: z.string().min(1, VALIDATION_FIELD),
        exam_date: z.record(
          z.any().refine((val) => val !== null && val !== undefined && val !== ''),
          z.any().refine((val) => val !== null && val !== undefined && val !== '')
        ),
      })
    )
    .min(1),
})

const examSchemaCMA = z.object({
  registration_closing_date: z.date().nullable().optional(),
  exam_subject: z
    .array(
      z.object({
        exam_date: z.record(
          z.any().refine((val) => val !== null && val !== undefined && val !== ''),
          z.any().refine((val) => val !== null && val !== undefined && val !== '')
        ),
      })
    )
    .min(1),
})

const examSchemaACCA = z.object({
  name: z.date({ message: VALIDATION_FIELD }),
  exam_subject: z.array(
    z.object({
      subject_id: z.string().min(1, 'Exam name is required'),
      end_date: z.date({ message: VALIDATION_FIELD }),
    })
  ),
})

const schemaMap = {
  [PROGRAM.CFA]: examSchemaCFA,
  [PROGRAM.ACCA]: examSchemaACCA,
  [PROGRAM.CMA]: examSchemaCMA,
}

export default schemaMap
