interface IProps {
  addNew?: () => void
}

const AddMore = ({ addNew }: IProps) => {
  /**
   * handle add New
   */
  const handleAddNew = () => {
    if (addNew) {
      addNew()
    }
  }
  return (
    <div className='d-flex align-items-center cursor-pointer w-25' onClick={handleAddNew}>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-4'>
        <div className='symbol-label'>
          <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.66667 0.667033C7.66667 0.490222 7.59643 0.320653 7.4714 0.195628C7.34638 0.0706042 7.17681 0.000366211 7 0.000366211C6.82319 0.000366211 6.65362 0.0706042 6.5286 0.195628C6.40357 0.320653 6.33333 0.490222 6.33333 0.667033V6.3337H0.666667C0.489856 6.3337 0.320287 6.40394 0.195262 6.52896C0.070238 6.65399 0 6.82356 0 7.00037C0 7.17718 0.070238 7.34675 0.195262 7.47177C0.320287 7.5968 0.489856 7.66703 0.666667 7.66703H6.33333V13.3337C6.33333 13.5105 6.40357 13.6801 6.5286 13.8051C6.65362 13.9301 6.82319 14.0004 7 14.0004C7.17681 14.0004 7.34638 13.9301 7.4714 13.8051C7.59643 13.6801 7.66667 13.5105 7.66667 13.3337V7.66703H13.3333C13.5101 7.66703 13.6797 7.5968 13.8047 7.47177C13.9298 7.34675 14 7.17718 14 7.00037C14 6.82356 13.9298 6.65399 13.8047 6.52896C13.6797 6.40394 13.5101 6.3337 13.3333 6.3337H7.66667V0.667033Z'
              fill='#99A1B7'
            />
          </svg>
        </div>
      </div>
      <div className='d-flex flex-column'>
        <div className='sapp-text-disable fw-semibold sapp-fs-14'>Add more</div>
      </div>
    </div>
  )
}

export default AddMore
