import { zodResolver } from '@hookform/resolvers/zod'
import { isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { createTeacher, uploadFile } from 'src/apis/user'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import { DEFAULT_MAX_FILE_SIZE, PageLink, VALIDATE_PASSWORD, VALIDATION_FIELD } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, IError } from 'src/type'
import { ITeacher } from 'src/type/teacher'
import { sizeInBytes, validatePassword } from 'src/utils'
import { z } from 'zod'
import TeacherPostForm from '../TeacherPostForm/TeacherPostForm'
import { DEFAULT_SCHEMA } from '../schema'

const TeacherCreate = () => {
  const { id } = useParams()
  const [uploadSuccess, setUploadSuccess] = useState(false)

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const isValidHttpUrl = (url: string) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return pattern.test(url)
  }

  const schema = z.object(
    Object.assign({}, DEFAULT_SCHEMA, {
      password: z.string({ required_error: VALIDATION_FIELD }).regex(new RegExp(validatePassword), {
        message: VALIDATE_PASSWORD,
      }),
      confirmPassword: z
        .string({ required_error: VALIDATION_FIELD })
        .regex(new RegExp(validatePassword), {
          message: 'Password must contain at least 8 characters, 1 uppercase character, 1 number',
        }),
    })
  )

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<ITeacher>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  })
  const onSubmitCreate = async (data: ITeacher) => {
    const { email, username, avatar, facebook, linkedin, youtube, teacher_teachable_instances } =
      data

    if (facebook && !isValidHttpUrl(facebook)) {
      setError('facebook', { message: 'Invalid value' })
      return
    }

    if (linkedin && !isValidHttpUrl(linkedin)) {
      setError('linkedin', { message: 'Invalid value' })
      return
    }

    if (youtube && !isValidHttpUrl(youtube)) {
      setError('youtube', { message: 'Invalid value' })
      return
    }

    setLoading(true)
    try {
      const res = await createTeacher({
        ...data,
        email: email.toLowerCase(),
        username: username.toLowerCase(),
        status: EStatusUser.ACTIVE,
        teacher_teachable_instances: teacher_teachable_instances?.filter(
          (item) => item.category_id && item.category_id !== ''
        ),
      })
      const dataStudent = res?.data
      toast.success('Create Teacher Successfully!')
      setUploadSuccess(true)

      if (isUndefined(avatar)) {
        navigate(PageLink.TEACHERS)
      }

      if (!isUndefined(avatar)) {
        await uploadFile({ userId: dataStudent?.id, avatar: avatar }).then(() =>
          navigate(PageLink.TEACHERS)
        )
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setUploadSuccess(false)
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<ITeacher> = async (data) => {
    if (
      !isUndefined(data.avatar) &&
      (data?.avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    onSubmitCreate(data)
    // }
  }

  // mở popup confỉm khi submit
  const handleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.TEACHERS),
    })
  }

  const { confirm, contextHolder } = useConfirm()

  // Watch the category_primary_id field for changes
  const categoryPrimaryId = watch('category_primary_id')

  useEffect(() => {
    if (errors.category_primary_id) {
      clearErrors('category_primary_id')
    }
  }, [categoryPrimaryId])

  return (
    <CreateEditLayout
      onSave={handleSubmitForm}
      onCancel={handleCancel}
      loading={loading}
      pageTitle={`${id ? 'Update Teacher' : 'Create Teacher'}`}
    >
      {contextHolder}
      <TeacherPostForm
        control={control}
        errors={errors}
        setError={setError}
        setValue={setValue}
        watch={watch}
        uploadSuccess={uploadSuccess}
      />
    </CreateEditLayout>
  )
}

export default TeacherCreate
