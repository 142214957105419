import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Select } from 'antd'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { INameAndId } from 'src/type/shop/common'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { DEFAULT_SELECT_ALL, FACILITY_STATUS_ENUM, FILTER_SELECTALL_SORTBY } from 'src/constants'
import {
  OPTIONS_OF_CONTRUCTION_MODE,
  OPTIONS_OF_STATUS_FILTER,
  OPTIONS_OF_TYPE_CLASS_LIST,
  OPTIONS_SUBJECTS,
} from 'src/constants/classes'
import { ICourseCategoies } from 'src/type/courses'
import TableClass from './TableClass'
import { ProductAPI } from 'src/apis/short-course/product'
import { IMeta } from 'src/type'
import { debounce } from 'lodash'
import { IClassList, IClassProduct } from 'src/type/shop/classs'

const { Option } = Select

const fieldNames = [
  'class_name',
  'class_code',
  'course_category_id',
  'course_level_id',
  'area_id',
  'status',
  'class_type',
  'subject',
]
const initialValues: any = {
  class_name: '',
  class_code: '',
  course_category_id: '',
  course_level_id: '',
  area_id: '',
  status: '',
  class_type: '',
  subject: '',
}

interface IClassCode {
  meta: IMeta
  class_codes: Array<{ code: string; id: string }>
}

interface IProps {
  initValue?: INameAndId
  open: boolean
  setOpen: Dispatch<SetStateAction<{ isOpen: boolean; type: 'main' | 'trail' } | undefined>>
  allowCheckMany?: boolean
  onAddClass: (data: INameAndId | null) => void
  type?: 'main' | 'trail'
}

const AddClass = ({
  open,
  setOpen,
  onAddClass,
  initValue,
  allowCheckMany = false,
  type,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const [classList, setClassList] = useState<IClassList>()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [areaClasses, setAreaClasses] = useState<any[]>([])
  const searchParams = new URLSearchParams(search)

  // state để dùng class code
  const [classCodes, setClassCodes] = useState<IClassCode>()
  const [showCode, setShowCode] = useState(false)

  const queryParams = {
    class_name: searchParams.get('class_name') ?? '',
    class_code: searchParams.get('class_code') ?? '',
    course_category_id: searchParams.get('course_category_id') ?? '',
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    status: searchParams.get('status') as string,
    area_id: searchParams.get('area_id'),
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
    instruction_mode: searchParams.get('instruction_mode') ?? '',
    class_type: searchParams.get('class_type') ?? '',
    subject: searchParams.get('subject') ?? '',
  }

  const validationSchema = z.object({
    class_name: z.string().optional(),
    course_category_id: z.string().optional(),
    subject: z.string().optional(),
    area_id: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    instruction_mode: z.string().optional(),
    class_type: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    class_code: z.string().optional(),
  })

  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const filterParams = cleanParamsAPI({
    class_name: queryParams.class_name?.trim() ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    area_id: queryParams.area_id ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
    status: queryParams.status,
    instruction_mode: queryParams.instruction_mode,
    class_type: queryParams.class_type,
    subject: queryParams.subject,
    class_code: queryParams.class_code,
  })
  const getParams = (
    class_name: string,
    class_code: string,
    course_category_id: string,
    area_id: string,
    sortType: string,
    fromDate?: string | Date,
    toDate?: string | Date,
    status?: string,
    instruction_mode?: string,
    class_type?: string,
    subject?: string,
    dateField?: string
  ) => ({
    class_name,
    class_code,
    course_category_id,
    area_id,
    sortType,
    fromDate,
    toDate,
    status,
    instruction_mode,
    class_type,
    subject,
    dateField,
  })
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll, setDefaultChecked } =
    useChecked<IClassProduct>(classList?.classes)

  const fetchClassList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await ProductAPI.getClass({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setClassList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  // goi API class code
  const fetchClassCodes = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await ProductAPI.getClassCodes(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  // gọi tiếp codes nếu kéo hết scroll
  const handlNextPageCodes = async (params: Object) => {
    const totalPages = classCodes?.meta?.total_pages
    const pageIndex = classCodes?.meta?.page_index as number
    const pageSize = classCodes?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchClassCodes(pageIndex + 1, pageSize, params)
      const results = classCodes.class_codes.concat(res?.data?.class_codes)
      setClassCodes({
        meta: res?.data?.meta,
        class_codes: results,
      })
    }
  }

  const getCodes = async ({ params }: any) => {
    const resMentor = await fetchClassCodes(1, 20, params)
    setClassCodes(resMentor?.data)
  }

  // call search codes
  const debounceSearchCodes = debounce((e) => {
    getCodes({ params: { search_key: e } })
  }, 500)

  // gọi lại API show full class code
  useEffect(() => {
    if (queryParams.class_code && !showCode) {
      getCodes({ is_get_all: true })
    }
  }, [queryParams.class_code])

  const newCourseCodes = classCodes?.class_codes?.map((group: { code: string; id: string }) => ({
    label: group.code,
    value: group.id,
  }))

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('class_name').trimStart().trimEnd() ?? '',
        getValues('class_code'),
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('area_id')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('instruction_mode')),
        replaceValueAll(getValues('class_type')),
        replaceValueAll(getValues('subject')),
        'updated_at'
      )
    )

    setLoading(true)

    fetchClassList(1, queryParams.page_size || 10, cleanedParams)
    setShowCode(true)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    fetchClassList(1, 10)
  }

  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const res = await ProductAPI.getCourseCategory({ page_index: 1, page_size: 100 })
        setCourseCategory(res?.data)
      } catch (error) {}
    }

    const fetchAreaClasses = async ({ page_index, page_size }: any) => {
      try {
        const res = await ProductAPI.getAreaClasses({
          page_index: page_index,
          page_size: page_size,
          params: { status: FACILITY_STATUS_ENUM.ACTIVE },
        })
        setAreaClasses(res?.data?.areas)
      } catch (error) {
        // do nothing
      }
    }
    fetchCourseCategory()
    fetchAreaClasses({ page_index: 1, page_size: 100 })
  }, [])

  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )

  const newAreaClasses = useMemo(() => {
    if (areaClasses.length > 0)
      return areaClasses?.map((level) => ({ label: level.name, value: level.id }))
    else return []
  }, [areaClasses])

  useEffect(() => {
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')

    if (fromDate && toDate && moment(toDate).isBefore(fromDate)) {
      setValue('toDate', moment(fromDate).endOf('day').toDate())
    }

    if (toDate && fromDate && moment(fromDate).isAfter(toDate)) {
      setValue('fromDate', moment(toDate).startOf('day').toDate())
    }
  }, [watch('fromDate'), watch('toDate')])

  const disabledFromDate = (current: any) => {
    const toDate = watch('toDate')
    if (toDate) {
      return current > moment(toDate).endOf('day')
    }
    return false
  }

  const disabledToDate = (toDate: any) => {
    const fromDate = watch('fromDate')
    if (fromDate) {
      return toDate < moment(fromDate).startOf('day')
    }
    return false
  }

  /**
   * Handle Click Cancel
   */
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => setOpen((period: any) => ({ ...period, isOpen: false })),
    })
  }

  const handleSelect = () => {
    if (!checkedList || checkedList.length <= 0) {
      return
    }
    const selection = classList?.classes
      .filter((item: IClassProduct) => {
        if (checkedList.includes(item.id)) {
          return item
        }
      })
      .map((el: { id: string; name: string }) => ({ id: el.id, name: el.name }))
    if (selection?.length) {
      onAddClass(selection[0] ?? null)
      setOpen((period: any) => ({ ...period, isOpen: false }))
    }
  }

  return (
    <SappModal
      title=''
      hideHeader
      open={open}
      handleClose={handleCancel}
      classBody='py-10 modal-body px-lg-17 px-10'
      confirmOnclose
      showFooter={false}
      classNameBody='d-flex justify-content-center sapp-p-body-content'
      contentClassName='sapp-modal-content'
      dialogClassName=' modal-fullscreen modal-fullscreen-form-faq m-0'
      showClassHeightBody={false}
      classNameContent='sapp-p-body-content'
    >
      {contextHolder}
      <div className='d-flex justify-content-between px-7 align-items-center'>
        <div className='sapp-title-modal-create-class'>Add Class</div>
        <div className='d-flex align-items-center'>
          {checkedList.length > 0 && (
            <div className='sapp-selected-count fs-6 me-5'>{`${checkedList.length} Selected`}</div>
          )}
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={() => {
              setOpen((period: any) => ({ ...period, isOpen: false }))
            }}
            cancelButtonCaption='Cancel'
            okButtonCaption='Add'
            okOnClick={handleSelect}
            className='justify-content-between d-flex flex-row-reverse'
            classNameCancel='fw-bold me-0'
            classNameSubmit='fw-bold me-5'
            loading={loading}
          />
        </div>
      </div>
      <div className='px-7'>
        <div className='card border-0'>
          <div className='card-header border-0 pt-10'>
            <div className=''>
              <div className='row'>
                {/* begin:: Search */}
                <div className='col-xl-3 col-sm-4'>
                  <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                    <HookFormTextField
                      control={control}
                      name='class_name'
                      placeholder='Search class name'
                      defaultValue={queryParams?.class_name}
                      onSubmit={onSubmit}
                      isListScreen
                    />
                  </div>
                </div>
                {/* end:: Search */}

                <div className='col-xl-3 col-sm-4'>
                  <HookFormSelectAntd
                    size='large'
                    control={control}
                    name='class_code'
                    placeholder='Code'
                    defaultValue={queryParams.class_code ?? ''}
                    showSearch
                    classNameHeight='sapp-h-40'
                    onFocus={async () => {
                      if (!newCourseCodes) {
                        await getCodes({})
                        return
                      }
                    }}
                    onSearch={(e: any) => {
                      if (e === undefined) {
                        return
                      }
                      debounceSearchCodes(e)
                    }}
                    handleNextPage={(e: any) => handlNextPageCodes({ params: { search_key: e } })}
                  >
                    {newCourseCodes &&
                      newCourseCodes?.map(
                        (course_category_ids: { label: string; value: string }) => (
                          <Option key={course_category_ids.value} value={course_category_ids.label}>
                            {course_category_ids.label}
                          </Option>
                        )
                      )}
                  </HookFormSelectAntd>
                </div>

                <div className='col-xl-3 col-sm-4'>
                  <HookFormSelectAntd
                    size='large'
                    control={control}
                    name='course_category_id'
                    placeholder='Program'
                    defaultValue={queryParams.course_category_id ?? ''}
                    showSearch
                    classNameHeight='sapp-h-40'
                  >
                    {DEFAULT_SELECT_ALL?.concat(newCourseCategory ?? ([] as Array<any>)).map(
                      (course_category_ids) => (
                        <Option key={course_category_ids.value} value={course_category_ids.value}>
                          {course_category_ids.label}
                        </Option>
                      )
                    )}
                  </HookFormSelectAntd>
                </div>
                <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                  <HookFormSelectAntd
                    size='large'
                    control={control}
                    name='subject'
                    placeholder='Subject'
                    defaultValue={queryParams.subject ?? ''}
                    showSearch
                    classNameHeight='sapp-h-40'
                  >
                    {DEFAULT_SELECT_ALL?.concat(OPTIONS_SUBJECTS ?? ([] as Array<any>)).map(
                      (course_category_ids) => (
                        <Option key={course_category_ids.value} value={course_category_ids.value}>
                          {course_category_ids.label}
                        </Option>
                      )
                    )}
                  </HookFormSelectAntd>
                </div>
                <div className='col-xl-3 col-sm-4 mt-5'>
                  <HookFormSelectAntd
                    size='large'
                    control={control}
                    name='status'
                    placeholder='Status'
                    defaultValue={queryParams.status ?? ''}
                    classNameHeight='sapp-h-40'
                  >
                    {DEFAULT_SELECT_ALL?.concat(OPTIONS_OF_STATUS_FILTER)?.map((level) => (
                      <Option key={level.label} value={level.value}>
                        {level.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
                <div className='col-xl-3 col-sm-4 mt-5'>
                  <HookFormSelectAntd
                    size='large'
                    control={control}
                    name='area_id'
                    placeholder='Area'
                    defaultValue={queryParams.area_id ?? ''}
                    classNameHeight='sapp-h-40'
                  >
                    {DEFAULT_SELECT_ALL?.concat(newAreaClasses ?? ([] as Array<any>)).map(
                      (area) => (
                        <Option key={area.label} value={area.value}>
                          {area.label}
                        </Option>
                      )
                    )}
                  </HookFormSelectAntd>
                </div>
                <div className='col-xl-3 col-sm-4 mt-5'>
                  <HookFormSelectAntd
                    size='large'
                    control={control}
                    name='instruction_mode'
                    placeholder='Construction Mode'
                    defaultValue={queryParams.instruction_mode ?? ''}
                    classNameHeight='sapp-h-40'
                  >
                    {DEFAULT_SELECT_ALL?.concat(OPTIONS_OF_CONTRUCTION_MODE)?.map((area) => (
                      <Option key={area.label} value={area.value}>
                        {area.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
                <div className='col-xl-3 col-sm-4 mt-5'>
                  <HookFormSelectAntd
                    size='large'
                    control={control}
                    name='class_type'
                    placeholder='Type'
                    defaultValue={queryParams.class_type ?? ''}
                    classNameHeight='sapp-h-40'
                  >
                    {DEFAULT_SELECT_ALL?.concat(OPTIONS_OF_TYPE_CLASS_LIST)?.map((type) => (
                      <Option key={type.label} value={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
                <div className='col-xl-3 col-sm-4 mt-5'>
                  <HookFormSelectAntd
                    name='sortType'
                    placeholder='Sort by'
                    control={control}
                    size='large'
                    defaultValue={queryParams?.sortType ?? ''}
                    classNameHeight='sapp-h-40'
                  >
                    {FILTER_SELECTALL_SORTBY.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
                <div className='col-xl-3 col-sm-4 mt-5'>
                  <HookFormDateTime
                    control={control}
                    name='fromDate'
                    placeholder='From date'
                    defaultValue={queryParams?.fromDate}
                    disabledDate={disabledFromDate}
                    isListScreen
                  />
                </div>
                <div className='col-xl-3 col-sm-4 mt-5'>
                  <HookFormDateTime
                    control={control}
                    name='toDate'
                    placeholder='To date'
                    defaultValue={queryParams?.toDate}
                    disabledDate={disabledToDate}
                    isListScreen
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card-header border-0 pt-6'>
            <div className='d-flex'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
          <TableClass
            classList={classList}
            loading={loading}
            setLoading={setLoading}
            fetchClassList={fetchClassList}
            filterParams={filterParams}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
            allowCheckMany={allowCheckMany}
            initValue={initValue}
            setDefaultChecked={setDefaultChecked}
          />
        </div>
      </div>
    </SappModal>
  )
}
export default AddClass
