import { Dispatch, SetStateAction, useEffect } from 'react'
import SappDrawer from 'src/components/base/SappDrawer'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { ACCEPT_UPLOAD_MIME_TYPE, DEFAULT_MAX_FILE_SIZE, VALIDATION_FIELD } from 'src/constants'
import thumnail_courseinfo from 'src/_metronic/assets/images/course/thumbnail_course.png'
import SappLabel from 'src/components/base/label/SappLabel'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ShopSpeakerAPI } from 'src/apis/short-course/speaker'
import toast from 'react-hot-toast'
import { ISpeaker, ISpeakerData } from 'src/type/shop/speaker'
import { useParams } from 'react-router-dom'

interface IProps {
  open: boolean
  type: 'edit' | 'create'
  data?: ISpeaker
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  onCancel: () => void
  onClose: () => void
  fetchData: () => void
}

interface IForm {
  name: string
  job_title: string
  avatar: any
  shop_event_id: string
}

const CreateAndUpdateSpeaker = ({
  open,
  type,
  data,
  loading,
  setLoading,
  onCancel,
  onClose,
  fetchData,
}: IProps) => {
  const { id } = useParams()

  /**
   * Validation cho các trường trong HookForm
   */
  const validationSchema = z.object({
    name: z.string().min(1, VALIDATION_FIELD),
    job_title: z.string().optional(),
    avatar: z.any(),
    shop_event_id: z.string().optional(),
  })

  /**
   * Hàm dùng dùng khi khởi tạo form mới hoặc cập nhật lại data của form
   */
  const loadData = () => {
    if (type === 'edit' && data) {
      setValue('name', data?.name)
      setValue('job_title', data?.job_title)
      setValue('avatar', data?.avatar?.ORIGIN)
      setValue('shop_event_id', data?.shop_event_id)
    } else {
      reset()
    }
  }

  /**
   * Handle khởi tạo Form khi mở popup
   */
  useEffect(() => {
    loadData()
  }, [open])

  const useFormProp = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  /**
   * Khai báo các phương thức của hookform
   */
  const { control, handleSubmit, reset, setValue, setError, watch } = useFormProp

  const avatar = watch('avatar')

  /**
   * Handle submit action of form - xử lý tạo mới hoặc cập nhật
   * @param {ISubmittedData} data  - Data của form
   */
  const onSubmit = async (speakerData: ISpeakerData) => {
    try {
      setLoading(true)
      if (!id) return
      if (type === 'edit' && data) {
        await ShopSpeakerAPI.updateSpeaker({
          id: data.id,
          data: {
            name: speakerData.name,
            job_title: speakerData.job_title,
            shop_event_id: speakerData.shop_event_id,
          },
        })
        if (speakerData.avatar && typeof speakerData.avatar !== 'string') {
          await ShopSpeakerAPI.uploadAvatar({ id: data.id, file: speakerData.avatar })
        }
        toast.success('Updated Speaker Successfully!')
      } else {
        const res = await ShopSpeakerAPI.createSpeaker({
          data: { ...speakerData, shop_event_id: id },
        })
        if (speakerData.avatar && typeof speakerData.avatar !== 'string' && res.data.id) {
          await ShopSpeakerAPI.uploadAvatar({ id: res.data.id, file: speakerData.avatar })
        }
        toast.success('Created Speaker Successfully!')
      }
      reset()
      fetchData()
      loadData()
      onClose()
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  return (
    <SappDrawer
      open={open}
      title='Add Speaker'
      handleSubmit={handleSubmit(onSubmit)}
      width='50%'
      handleClose={onCancel}
      okButtonCaption='Save'
    >
      <SappLabel label='Avatar' />
      <div className='mb-10'>
        <SAPPHookUploadFile
          name='avatar'
          control={control}
          setValue={setValue}
          setError={setError}
          imagePreview={avatar ?? thumnail_courseinfo}
          accept={ACCEPT_UPLOAD_MIME_TYPE}
          maxFileSize={DEFAULT_MAX_FILE_SIZE}
          removeAvatar={thumnail_courseinfo}
          className='ms-1'
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField
          control={control}
          name='name'
          label='Name'
          required
          className='sapp-h-45px'
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField
          control={control}
          name='job_title'
          label='Job Title'
          className='sapp-h-45px'
        />
      </div>
    </SappDrawer>
  )
}

export default CreateAndUpdateSpeaker
