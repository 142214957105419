import { Control } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'

const ProductPricing = ({ control }: { control: Control }) => {
  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <div className='text-gray-800 fw-bold sapp-mb-24px sapp-fs-20px'>Pricing</div>
        <div className='sapp-mb-24px fv-row'>
          <HookFormTextField
            type='number'
            control={control}
            name='origin_price'
            label='Giá'
            required
            className='sapp-h-45px'
            postFix={<div className='ps-3 pe-3 text-gray-700'>đ</div>}
          />
        </div>
        <div className='sapp-mb-24px fv-row'>
          <HookFormTextField
            control={control}
            name='promotion_ration'
            label='Khuyến mãi'
            className='sapp-h-45px'
            postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
          />
        </div>
        <div className='sapp-mb-24px fv-row'>
          <HookFormTextField
            control={control}
            name='promotion_duration'
            label='Thời gian khuyến mãi'
            className='sapp-h-45px'
            postFix={<div className='ps-3 pe-3 text-gray-700'>days</div>}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductPricing
