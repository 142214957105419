export enum TEACHING_STATUS {
  'TRIAL' = 'Giảng thử',
  'TRAINING' = 'Đang Training',
  'SCHEDULING' = 'Đặt lịch giảng dạy',
  'TEACHING' = 'Đang dạy',
  'HIATUS' = 'Tạm ngừng dạy',
  'STOP_SCHEDULING' = 'Dừng đặt lịch',
  'END_COOPERATION' = 'Ngừng hợp tác',
}

// Convert enum to array of objects with label and value
export const teachingStatusArray = Object.entries(TEACHING_STATUS).map(([key, value]) => ({
  label: value,
  value: key,
}))
