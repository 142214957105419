import { Tooltip } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EntranceApi } from 'src/apis/entrance-test'
import { IconCopy } from 'src/common/icons'
import SappTooltip from 'src/common/SappTooltip'
import SappModal from 'src/components/base/SappModal'
import { PageLink } from 'src/constants'
import { CODE_ADMIN, TITLE_QUIZ_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { Role } from 'src/type'
import { IParticipants } from 'src/type/participants'
import ActionCellEntrance from '../base/actionEntranceTest/ActionCellEntrance'

interface IProps {
  Action: any
  data: IParticipants
  fetchParticipantList: (currentPage: number, pageSize: number, params?: Object) => void | any
  showPublishScore: boolean
}

const ParticipantAction = ({ Action, data, fetchParticipantList, showPublishScore }: IProps) => {
  const navigate = useNavigate()
  const [copyText, setCopyText] = useState('Copy')
  const [openPublishScore, setOpenPublishScore] = useState(false)

  const getParticipantsDetail = async (id: string) => {
    try {
      const res = await EntranceApi.detailAttempts(id)
      if (res) {
        Action('content', res?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderGetParticipantDetail = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_QUIZ_GR.PUBLIC_SCORE_DETAIL) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderGetParticipant = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_QUIZ_GR.VIEW_PARTICIPANT_LIST) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  /**
   * @description function này gọi API publish score
   */
  async function publishScore() {
    const res = await EntranceApi.publishScore(data?.id)
    if (res.success) {
      fetchParticipantList(1, 10)
      setOpenPublishScore(true)
    }
  }

  const { confirm, contextHolder } = useConfirm()

  /**
   * @description function này để mở popup publish score
   */
  const handlePublishScore = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc muốn hiển thị kết quả chi tiết bài kiểm tra đầu vào cho học viên?',
      onClick: publishScore,
    })
  }

  /**
   * @description function này để copy link
   */
  const handleCopy = () => {
    const urlToCopy = `${process.env.REACT_APP_WEB_LMS_URL}/entrance-test/test-result/${data?.id}`

    if (navigator.clipboard) {
      navigator.clipboard.writeText(urlToCopy).then(
        () => {
          setCopyText('Copied!')
        },
        (err) => {
          setCopyText('Failed to copy')
        }
      )
    } else {
      // Fallback for older browsers
      const tempInput = document.createElement('input')
      tempInput.value = urlToCopy
      document.body.appendChild(tempInput)
      tempInput.select()
      tempInput.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand('copy')
      setCopyText('Copied')
      document.body.removeChild(tempInput)
    }

    // Reset tooltip title after a short delay
    setTimeout(() => setCopyText('Copy'), 2000)
  }

  return (
    <>
      {contextHolder}
      {(allowRenderGetParticipantDetail || showPublishScore) && (
        <ActionCellEntrance>
          {(allowRenderGetParticipantDetail || allowRenderGetParticipant) && (
            <div className='menu-item px-3' onClick={() => getParticipantsDetail(data?.id)}>
              <div className='menu-link px-3'>Detail</div>
            </div>
          )}
          {(allowRenderGetParticipantDetail || allowRenderGetParticipant) && (
            <div
              className='menu-item px-3'
              onClick={() => navigate(`${PageLink.ENTRANCE_TEST_RESULT}/${data.id}`)}
            >
              <div className='menu-link px-3'>Test Result</div>
            </div>
          )}

          {showPublishScore && allowRenderGetParticipantDetail && (
            <div
              className='menu-item px-3'
              onClick={
                data?.is_publish_detail
                  ? () => setOpenPublishScore(true)
                  : () => handlePublishScore()
              }
            >
              <div className='menu-link px-3'>Publish Score Detail</div>
            </div>
          )}

          <SappModal
            handleClose={() => setOpenPublishScore(false)}
            open={openPublishScore}
            title='Publish Score Detail'
            showFooter={false}
            centered
            dialogClassName='sapp-min-w-650px'
            disableInnerScroll
          >
            <div className='d-flex align-items-center justify-content-between px-4 my-10 sapp-h-50px sapp-border'>
              <div className='text-gray-800 sapp-fs-16px sapp-w-90'>
                <SappTooltip
                  title={`${process.env.REACT_APP_WEB_LMS_URL}/entrance-test/result/${data?.id}`}
                  max_length={75}
                />
              </div>
              <Tooltip title={copyText} color='white'>
                <div className='cursor-pointer' onClick={handleCopy}>
                  <IconCopy />
                </div>
              </Tooltip>
            </div>
          </SappModal>
        </ActionCellEntrance>
      )}
    </>
  )
}
export default ParticipantAction
