import React, {useState} from 'react'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
// import './MenuTabsSection.scss'
import {Skeleton} from 'antd'

interface Tab {
  id: string
  title: string
  content: React.ReactNode
}

interface MenuTabsProps {
  tabs: Tab[]
  typeofSection?: any
  SectionName?: any
  loading?: boolean
}

const MenuTabsSection: React.FC<MenuTabsProps> = ({typeofSection, tabs, loading}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true)
  return (
    <>
      <div
        className={`d-flex justify-content-between border sapp-px-24px sapp-h-62px sapp-mt-24px ${
          isAccordionOpen
            ? 'sapp-border-bottom-none'
            : 'sapp-pb-24px align-items-center sapp-pt-24px rounded'
        }`}
        style={{borderBottom: '0px !important'}}
      >
        <div className='d-flex'>
          {isAccordionOpen ? (
            tabs.map((tab) => (
              <div
                key={tab.id}
                className={`${
                  isAccordionOpen
                    ? 'bg-blue-500 text-gray-800 fw-semibold'
                    : 'sapp-text--label fw-semibold hover:text-gray-600 dark:hover:text-gray-300'
                } focus:outline-none px-0 me-10 sapp-fs-14 sapp-lh-16px`}
                style={{
                  borderBottom: isAccordionOpen ? '1px solid #FFB880' : 'none',
                  borderTop: '0',
                  borderLeft: '0',
                  borderRight: '0',
                  background: 'none',
                  fontWeight: 'bold',
                  marginTop: 22,
                  cursor: 'pointer',
                }}
              >
                {tab.title}
              </div>
            ))
          ) : (
            <>
              {loading ? (
                <Skeleton.Button active />
              ) : (
                <div className='px-2 sapp-fs-14 fw-semibold text-gray-800 sapp-lh-14px'>
                  {typeofSection}
                </div>
              )}
            </>
          )}
        </div>
        <div className='d-flex gap-2 justify-content-center align-items-center'>
          <div
            style={{
              padding: !isAccordionOpen ? 0 : '',
              transform: !isAccordionOpen ? 'rotate( 90deg)' : '',
              cursor: 'pointer',
            }}
          >
            <ButtonIconOnly
              iconName='down'
              activeColor='primary'
              bg='gray-100'
              onClick={() => setIsAccordionOpen(!isAccordionOpen)}
              fs='4'
            />
          </div>
        </div>
      </div>
      {isAccordionOpen && (
        <div className='sapp-px-24px sapp-pt-24px sapp-pb-24px border'>
          {tabs.map((tab) => (
            <div key={tab.id} className={`${isAccordionOpen ? 'hidden' : 'block'}`}>
              {isAccordionOpen && tab.content}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default MenuTabsSection
