import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { PageLink } from 'src/constants'
import { ThemeModeSwitcher } from 'src/_metronic/layout/theme-mode/ThemeModeSwitcher'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import './sidebar.scss'
import { IStudentDetail } from 'src/type/students'
import UserStatusCell from 'src/components/user-management/UserStatusCell'

const PopoverProfile = ({ profile }: { profile: IStudentDetail | undefined | null }) => {
  return (
    <div className='sapp-menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px'>
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={profile?.detail?.avatar?.['50x50'] ?? profile?.detail?.avatar?.ORIGIN ?? avatar}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5 text-gray-600'>
              {profile?.detail?.full_name}
              <UserStatusCell status={profile?.status} className='px-1 py-1 ms-2' />
            </div>
            <Link to='/' className='fw-semibold text-muted text-hover-primary fs-7'>
              {profile?.detail?.email}
            </Link>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={PageLink.PROFILE_OVERVIEW} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      {/* <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} /> */}

      {/* <div className='menu-item px-5' onClick={logOut}>
        <div className='menu-link px-5'>Sign Out</div>
      </div> */}
    </div>
  )
}

export default PopoverProfile
