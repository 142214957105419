import { Select } from 'antd'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import SappBaseTable from 'src/common/SappBaseTable'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import ActionCell from 'src/components/base/action/ActionCell'
import SappActionCellV2 from 'src/components/base/action/SappActionCellV2'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { FACILITY_STATUS, FACILITY_STATUS_ENUM, PageLink } from 'src/constants'
import { CONFIRM_STATUS_CHANGE, DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IArea, IAreaList } from 'src/type/area'
import { ICommonTable } from 'src/type/common'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  mappingAddress,
} from 'src/utils'

interface IProps extends ICommonTable {
  areaList?: IAreaList
  fetchAreaList: (currentPage: number, pageSize: number, params?: Object) => void
  getParams: (
    search: string,
    province_code: string,
    status?: string,
    fromDate?: string | Date,
    toDate?: string | Date,
    dateField?: string
  ) => {
    search: string
    province_code: string
    status?: string
    fromDate?: string | Date
    toDate?: string | Date
    dateField?: string
  }
  queryParams: {
    search: string
    province_code: string
    status: string
    fromDate?: string | Date
    toDate?: string | Date
    dateField?: string
  }
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const { Option } = Select

const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Name',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Code',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Address',
    className: 'min-w-300px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Area',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Total Room',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Updated',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Action',
    className: 'pe-5 w-25px',
  },
]

const AreaTable = ({
  areaList,
  loading,
  setLoading,
  filterParams,
  fetchAreaList,
  getParams,
  queryParams,
  isCheckedAll,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()
  const dateQueryFromDate = queryParams.fromDate && getDateInfo(queryParams.fromDate as Date)
  const dateQueryToDate = queryParams.toDate && getDateInfo(queryParams.toDate as Date)
  const showQueryParams = queryParams.search || queryParams.fromDate || queryParams.toDate
  const { control, setValue } = useForm()

  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams?.search?.trimStart()?.trimEnd() ?? '',
      queryParams?.province_code ?? '',
      queryParams?.status ?? '',
      dateQueryFromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      dateQueryToDate
        ? formatISOToDate(dateQueryToDate?.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )

  const handleDelete = (id: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        try {
          await ClassroomApi.deleteAreaClass(id)
          delete filterParamsPagination.search
          const filterParam = { ...filterParamsPagination }
          if (queryParams?.search?.trimStart()?.trimEnd()) {
            filterParam['search'] = `name=${queryParams?.search
              ?.trimStart()
              ?.trimEnd()},code=${queryParams?.search?.trimStart()?.trimEnd()}`
          }
          fetchAreaList(1, 10, filterParam)
          toast.success('Delete Successfully!')
        } catch (err) {}
      },
    })
  }

  const handleChangeStatus = (status: string, id?: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [CONFIRM_STATUS_CHANGE],
      onClick: async () => {
        try {
          await ClassroomApi.editAreaClass(id, { status })
          delete filterParamsPagination.search
          const filterParam = { ...filterParamsPagination }
          if (queryParams?.search?.trimStart()?.trimEnd()) {
            filterParam['search'] = `name=${queryParams?.search
              ?.trimStart()
              ?.trimEnd()},code=${queryParams?.search?.trimStart()?.trimEnd()}`
          }
          fetchAreaList(1, 10, filterParam)
          toast.success('Update Status Successfully!')
        } catch (err) {}
      },
      onClose: () => {
        const resetValue =
          status === FACILITY_STATUS_ENUM.CLOSE
            ? FACILITY_STATUS_ENUM.ACTIVE
            : FACILITY_STATUS_ENUM.CLOSE
        setValue(`status-${id}`, resetValue)
      },
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchAreaList(page_index || 1, page_size || 10)
  }, [])

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      name: currenPage,
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchAreaList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  return (
    <>
      {contextHolder}
      <SappBaseTable
        headers={headers}
        loading={loading}
        data={areaList?.areas}
        isCheckedAll={isCheckedAll}
        hasCheck={false}
        dataResponse={areaList}
        handlePaginationChange={handlePaginationChange}
        totalItems={areaList?.metadata?.total_records}
      >
        {areaList?.areas?.map((area: IArea, index: number) => {
          return (
            <tr key={area?.id}>
              <td>
                {index + 1 + (areaList?.metadata?.page_index - 1) * areaList?.metadata?.page_size}
              </td>
              <td className='text-start text-hover-primary cursor-pointer  text-gray-800'>
                <Link
                  to={`${PageLink.CLASSROOM_AREA_DETAIL}/${area?.id}/overview`}
                  className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                >
                  {area?.name ?? '--'}
                </Link>
              </td>
              <td className='text-start'>{area?.code ?? '--'}</td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>
                  {mappingAddress([
                    area?.address,
                    area?.ward?.path_with_type,
                    area?.ward?.district?.path_with_type,
                    area?.ward?.district?.province?.name_with_type,
                  ])}
                </div>
              </td>
              <td className='text-start'>
                <HookFormSelectAntd
                  control={control}
                  name={`status-${area?.id}`}
                  required
                  showSearch
                  onChange={(value) => {
                    if (!value) return
                    handleChangeStatus(value as string, area.id)
                  }}
                  defaultValue={area.status}
                >
                  {FACILITY_STATUS?.map((item: { label: string; value: string }) => (
                    <Option key={item?.label} value={item?.value}>
                      {item?.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>
                  {area?.ward?.district?.province?.name ?? '--'}
                </div>
              </td>
              <td className='text-start'>{area?.total_room ?? '--'}</td>
              <td className='text-start'>
                <DatetimeColumn created_at={area?.created_at} updated_at={area?.updated_at} />
              </td>
              <td className='sapp-absolute-column'>
                <ActionCell>
                  <div
                    className='menu-item px-3'
                    onClick={() => {
                      navigate(`${PageLink.CLASSROOM_AREA_DETAIL}/${area?.id}/setting`)
                    }}
                  >
                    <div className='menu-link px-3'>Edit</div>
                  </div>
                  <div className='menu-item px-3' onClick={() => handleDelete(area?.id)}>
                    <div className='menu-link px-3'>Delete</div>
                  </div>
                </ActionCell>
              </td>
            </tr>
          )
        })}
      </SappBaseTable>
    </>
  )
}

export default AreaTable
