import { useParams } from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ImportLogTableDetail from 'src/components/base/upload-file/ImportLogTableDetail'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { ITabs } from 'src/type'

let defaultBreadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
]
const ImportLogDetail = () => {
  const params = useParams()
  const setBreadcrumbs = (type?: string) => {
    let breadcrumbs = defaultBreadcrumbs
    if (type === 'STAFF') {
      breadcrumbs = [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/STAFF`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'STUDENT') {
      breadcrumbs = [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/STUDENT`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }

    if (type === 'TEACHER') {
      breadcrumbs = [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/TEACHER`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }

    if (type === 'CLASS') {
      breadcrumbs = [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/CLASS`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'CLASS_STUDENT') {
      breadcrumbs = [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/CLASS_STUDENT`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'TEST_PARTICIPANT') {
      breadcrumbs = [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/TEST_PARTICIPANT`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
    if (type === 'exam') {
      breadcrumbs = [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/exam`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }

    return [
      ...breadcrumbs,
      {
        link: `${PageLink.IMPORT_LOG}`,
        title: 'Detail',
      },
    ]
  }
  return (
    <PageLayouts
      pageTitle={LANG_SIDEBAR.importLog}
      breadcrumbs={setBreadcrumbs(params.type) ?? defaultBreadcrumbs}
    >
      <div className='card'>
        {params.type &&
          (() => {
            return <ImportLogTableDetail params={params} type={params.type}></ImportLogTableDetail>
          })()}
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ImportLogDetail)
