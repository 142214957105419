import React, { Dispatch, SetStateAction, useEffect } from 'react'
import SappDrawer from 'src/components/base/SappDrawer'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { VALIDATION_FIELD } from 'src/constants'
import { Select } from 'antd'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import MenuTabsSection from 'src/pages/shop/blogs/MenuTabs'
import Advanced from 'src/pages/shop/blogs/Advanced'
import { INewOrUpdatePopup, RobotMeta } from 'src/type/shop/common'
import useShopFilter from 'src/hooks/use-shop-filter'
import { z } from 'zod'
import { metaValidationSchema } from 'src/utils/shop/validation'
import { zodResolver } from '@hookform/resolvers/zod'
import toast from 'react-hot-toast'
import { IBlogCategory } from 'src/type/shop/blog'
import { ShopCategoryAPI } from 'src/apis/short-course/blogs/category'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
const { Option } = Select
import './blog-category.scss'

interface IProps {
  open: boolean
  handleClose: () => void
  reloadPage: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  setOpenNewCategory: Dispatch<SetStateAction<INewOrUpdatePopup>>
  type?: 'create' | 'edit'
  categoryData?: IBlogCategory
}

type ISubmittedData = {
  name: string
  describe?: string
  slug?: string
  meta_title?: string
  meta_description?: string
  focus_keyword?: string
  robot_meta?: RobotMeta
  canonical_url?: string
  parent_id?: string
}

const NewCategory = ({
  open,
  reloadPage,
  handleClose,
  setLoading,
  setOpenNewCategory,
  type = 'create',
  categoryData,
}: IProps) => {
  /**
   * Khởi Tạo gía trị cho Form
   */
  const initialValue = {
    name: '',
    describe: '',
    slug: '',
    meta_title: '',
    meta_description: '',
    focus_keyword: '',
    robot_meta: {},
    canonical_url: '',
    parent_id: '',
  }

  /**
   * lấy các function trong hookCategory để filter category cha cho category hiện tại
   */
  const { dataList, getData, setDataList, debounceGetData, handleNextPage } = useShopFilter({
    callback: ShopCategoryAPI.get,
    type: 'blog',
    key: 'blogCategories',
  })

  /**
   * Validation cho các trường trong HookForm
   */
  const validationSchema = z.object({
    name: z.string().min(1, VALIDATION_FIELD),
    slug: z.string().nullable().optional(),
    describe: z.string().nullable().optional(),
    meta_title: z.string().min(1, VALIDATION_FIELD),
    meta_description: z.string().min(1, VALIDATION_FIELD),
    focus_keyword: z.string().nullable().optional(),
    robot_meta: metaValidationSchema,
    canonical_url: z.string().nullable().optional(),
    parent_id: z.string().min(1, VALIDATION_FIELD),
  })

  /**
   * Hàm dùng dùng khi khởi tạo form mới hoặc cập nhật lại data của form
   */
  const handleClearData = () => {
    if (type === 'edit' && categoryData) {
      setDataList((prev: any) => {
        return {
          ...prev,
          data: [{ id: categoryData.parent?.id, name: categoryData.parent?.name }],
        }
      })
      debounceGetData(categoryData.parent?.name)
      const robotMeta = categoryData?.robot_meta ? JSON.parse(categoryData?.robot_meta) : ''
      setValue('name', categoryData?.name ?? '')
      setValue('slug', categoryData?.slug ?? '')
      setValue('describe', categoryData?.describe ?? '')
      setValue('meta_title', categoryData?.meta_title ?? '')
      setValue('meta_description', categoryData?.meta_description ?? '')
      setValue('focus_keyword', categoryData?.focus_keyword ?? '')
      setValue('robot_meta', robotMeta)
      setValue('canonical_url', categoryData?.canonical_url ?? '')
      setValue('parent_id', categoryData?.parent_id ?? '')
      Object.keys(robotMeta).forEach((key: string) => {
        setValue(`robot_meta.${key as keyof RobotMeta}`, robotMeta[key])
      })
    } else {
      reset()
    }
  }

  /**
   * Handle khởi tạo Form khi mở popup
   * @param{categoryData} - data của category nếu có
   * @param{open} - Để hanle việc clear Form khi form không có category data
   */
  useEffect(() => {
    handleClearData()
  }, [categoryData, open])

  const useFormProp = useForm<ISubmittedData>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValue,
  })
  const { control, handleSubmit, reset, setValue, getValues } = useFormProp

  /**
   * Handle submit action of form - xử lý tạo mới hoặc cập nhật
   * @param {ISubmittedData} data  - Data của form
   */
  const onSubmit = async (data: ISubmittedData) => {
    try {
      setLoading(true)
      if (!String(data.parent_id).trim()) delete data.parent_id
      if (type === 'edit' && categoryData) {
        await ShopCategoryAPI.updateBlogCategory({ id: categoryData?.id, data })
        toast.success('Category updated successfully!')
      } else {
        await ShopCategoryAPI.createBlogCategory({ data })
        toast.success('Category created successfully!')
      }
      reset()
      handleClearData()
      reloadPage()
      setOpenNewCategory({ isOpen: false, data: null, type: 'create' })
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  return (
    <SappDrawer
      open={open}
      title='Add New Category'
      handleSubmit={handleSubmit(onSubmit)}
      width='50%'
      handleClose={handleClose}
      okButtonCaption='Save'
      rootClassName='create-category-root'
      classNameHeader='create-category-header'
    >
      <HookFormTextField control={control} name='name' label='Name' required />
      <div className='my-10'>
        <HookFormTextField control={control} name='slug' label='Slug' />
      </div>
      <div className='mb-10'>
        <HookFormSelectAntd
          name='parent_id'
          placeholder='None'
          control={control}
          size='large'
          required
          showSearch
          handleNextPage={handleNextPage}
          onSearch={async (e) => {
            debounceGetData(e)
            return
          }}
          label='Parent Category'
          onFocus={async () => {
            if (dataList?.data?.length <= 0) {
              await getData()
              return
            }
          }}
          onChange={async (e: any) => {
            if (e === undefined) {
              debounceGetData()
            }
          }}
          loading={dataList.loading}
          allowClear
          classNameHeight='sapp-h-45px'
        >
          {dataList.data?.map((e) => {
            return (
              <Option key={e.id} value={e.id}>
                {e.name}
              </Option>
            )
          })}
        </HookFormSelectAntd>
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Describe'
          control={control}
          name='describe'
          math={true}
          height={500}
          defaultValue={getValues('describe')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='meta_title' label='Meta Title' required />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Meta Description'
          control={control}
          name='meta_description'
          math={true}
          height={500}
          defaultValue={getValues('meta_description')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
          required
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='focus_keyword' label='Focus Keyword' />
      </div>
      <MenuTabsSection
        typeofSection={'Advanced'}
        SectionName={'Advanced'}
        tabs={[
          {
            id: 'tab1',
            title: 'Advanced',
            content: <Advanced useForm={useFormProp} />,
          },
        ]}
      />
    </SappDrawer>
  )
}

export default NewCategory
