import { format } from 'date-fns'
import { round } from 'lodash'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useParams } from 'react-router-dom'
import { ClassesApi } from 'src/apis/classes'
import LoadingTable from 'src/common/LoadingTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SAPPBadge from 'src/components/base/SAPPBadge'
import SappTable from 'src/components/base/SappTable'
import Search from 'src/components/base/search'
import UserListGrouping from 'src/components/user-management/UserListGrouping'
import { DURATION_TIME_TYPE, STUDENT_PROFILE, STUDENT_STATUS } from 'src/constants'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { CLASS_USER_TYPE_BADGE } from 'src/constants/student'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { Role } from 'src/type'
import { IStudents } from 'src/type/students'
import { cleanParamsAPI } from 'src/utils'
import ModalImportClassStudent from '../../ModalImportClassStudent'
import ModalProcessing from '../../modalProcessing'
import ModalTransfer from '../../transfer-class/ModalTransfer'
import ModalAddStudentsInClass from './ModalAddStudentsInClass'
import ModalUpdateUserDuration from './ModalUpdateUserDuration'
import { StudentActions } from './StudentActions'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { PROGRAM } from 'src/type/courses'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { useForm } from 'react-hook-form'
import { Select } from 'antd'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { BUTTON_TEXT } from 'src/constants/lang'
import clsx from 'clsx'

const headers = [
  {
    label: 'ID',
    key: 'hubspot_contact_id',
    className: 'min-w-175px',
  },
  {
    label: 'Student Name',
    key: 'student',
    className: 'fs-7 fw-bold min-w-150px',
  },
  {
    label: 'Email',
    key: 'email',
    className: 'min-w-130px',
  },
  {
    label: 'Phone',
    key: 'phone',
    className: 'fs-7 fw-bold min-w-100px',
  },
  {
    label: 'Level',
    key: 'level',
    className: 'min-w-80px',
  },
  {
    label: 'Duration',
    key: 'duration',
    className: 'fs-7 fw-bold min-w-150px',
  },
  {
    label: 'Progress',
    key: 'progress',
    className: 'fs-7 fw-bold min-w-100px',
  },
  {
    label: 'Type User',
    key: 'type_user',
    className: 'min-w-100px',
  },
  {
    label: 'Note',
    key: 'note',
    className: 'fs-7 fw-bold min-w-80px',
  },
]
interface IProps {
  loading: boolean
  classDetail: any | undefined
}

const getParams = (
  text?: string,
  class_flexible_status?: string,
  from_date_class_flexible_status?: string,
  to_date_class_flexible_status?: string
) => ({
  text,
  class_flexible_status,
  from_date_class_flexible_status,
  to_date_class_flexible_status,
})
const { Option } = Select

const ListStudent = ({ loading, classDetail }: IProps) => {
  const { id } = useParams()
  const { contextHolder, confirm } = useConfirm()
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const [open, setOpen] = useState<boolean>(false)
  const [user, setUser] = useState<any>()
  const [userSearch, setUserSearch] = useState<any>()
  const [totalUser, setTotalUser] = useState<number>(0)
  const [loadingStudent, setLoadingStudent] = useState(false)
  const [courseId, setCourseId] = useState()
  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<IStudents>(userSearch)

  const [normalClassConnections, setNormalClassConnections] = useState<number>(0)
  const [shouldRender, setShouldRender] = useState(false)
  const [exceptedSections, setExceptedSections] = useState([])
  const [isEnded, setIsEnded] = useState<boolean>(false)

  const [openBlocked, setOpenBlocked] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [openProcess, setOpenProcess] = useState<{
    status: boolean
    type?: string
    id?: any
    studentId?: string
  }>({ status: false })
  const [openUpdateDuration, setOpenUpdateDuration] = useState<{
    status: boolean
    studentId?: string
    data?: any
  }>({
    status: false,
  })
  const [openTransferModal, setOpenTransferModal] = useState<{
    status: boolean
    studentId?: string
  }>({
    status: false,
  })

  const { control, watch, getValues, reset, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      text: '',
      class_flexible_status: '',
      registry: {
        fromDate: '',
        toDate: '',
      },
    },
  })

  const allowRenderImportStudent = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.IMPORT_STUDENT_IN_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.CREATE_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderAction = profileMe?.roles?.some(
    (role: Role) =>
      // hasPermission(role, TITLE_CLASS_GR.EDIT_COURSE_CONTENT_CLASS) ||
      // hasPermission(role, TITLE_CLASS_GR.EDIT_COURSE_CONTENT_OF_STUDENT_IN_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
      // hasPermission(role, TITLE_CLASS_GR.EDIT_DURATION) ||
      hasPermission(role, TITLE_CLASS_GR.REMOVE_STUDENT_IN_CLASS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderAddStudent = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.CREATE_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderDeleteStudent = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.REMOVE_STUDENT_IN_CLASS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderEditCourseContentClass = profileMe?.roles?.some(
    (role: Role) =>
      // hasPermission(role, TITLE_CLASS_GR.EDIT_COURSE_CONTENT_CLASS) ||
      // hasPermission(role, TITLE_CLASS_GR.EDIT_COURSE_CONTENT_OF_STUDENT_IN_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.REMOVE_STUDENT_IN_CLASS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderEditDuration = profileMe?.roles?.some(
    (role: Role) =>
      // hasPermission(role, TITLE_CLASS_GR.EDIT_DURATION) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const getClassStudents = async (page_index?: number, page_size?: number, params?: Object) => {
    setLoadingStudent(true)
    setTimeout(async () => {
      try {
        const response = await ClassesApi.getClassStudent({
          class_id: id,
          page_index: page_index || 1,
          page_size: page_size || 10,
          params: params,
        })
        if (response) {
          const getListUser = response?.data?.students.map((item: any) => {
            return item.user
          })
          setUser(response?.data)
          setUserSearch(getListUser)
          setTotalUser(response?.data?.meta?.total_records)
          setNormalClassConnections(response?.data?.normal_class_connections?.length || 0)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingStudent(false)
      }
    }) // Delay of 150 milliseconds
  }

  const deleteClassStudents = async (id?: string | number, data?: Object) => {
    try {
      const res = await ClassesApi.deleteStudentInClass({
        user: data,
        classId: id,
      })
      const invalidStudent = res?.data?.invalid_student
      if (res) {
        if (invalidStudent.length > 0) {
          invalidStudent.forEach((errorCode: any) => {
            // Hiển thị thông báo tương ứng với mỗi mã lỗi
            toast.error(`${errorCode?.error_message}: ${errorCode?.students.join(', ')}`)
          })
        } else {
          toast.success('Delete Successfully!')
        }
        getClassStudents()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleSearchChange = (e: any) => {
    setValue('text', e.target.value.toLowerCase())
  }

  const handleSearch = () => {
    let cleanParams = cleanParamsAPI(
      getParams(
        getValues('text'),
        getValues('class_flexible_status'),
        getValues('registry.fromDate'),
        getValues('registry.toDate')
      )
    )
    if (classDetail?.duration_type !== DURATION_TIME_TYPE.FLEXIBLE) {
      delete cleanParams.class_flexible_status
      delete cleanParams.from_date_class_flexible_status
      delete cleanParams.to_date_class_flexible_status
    }

    getClassStudents(1, user?.meta?.page_size || 10, cleanParams)
  }

  const handleReset = () => {
    getClassStudents(1, user?.meta?.page_size || 10)
    reset()
  }

  const blockUser = () => {
    setOpenBlocked(false)
    toggleCheckAll(!listDataChecked)
    deleteClassStudents(id, checkedList)
  }

  const handleAction = (e: 'course' | 'duration' | 'transfer', studentId: string, data: any) => {
    if (e === 'course') {
      setOpenProcess({ status: true, type: 'course-content', id: courseId, studentId: studentId })
    }
    if (e === 'duration') {
      setOpenUpdateDuration({ status: true, studentId: studentId, data: data })
      return
    }
    if (e === 'transfer') {
      setOpenTransferModal({ status: true, studentId: studentId })
    }
  }

  // Call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text'),
        getValues('class_flexible_status'),
        getValues('registry.fromDate'),
        getValues('registry.toDate')
      )
    )
    if (classDetail?.duration_type !== DURATION_TIME_TYPE.FLEXIBLE) {
      delete cleanedParams.class_flexible_status
      delete cleanedParams.from_date_class_flexible_status
      delete cleanedParams.to_date_class_flexible_status
    }
    getClassStudents(page_index, page_size, cleanedParams)
  }

  const isShowGroupDeleteButton =
    shouldRender && allowRenderDeleteStudent && !isEnded && checkedList.length > 0

  useEffect(() => {
    getClassStudents()
      .then(() => {
        setShouldRender(true)
      })
      .catch((error) => {
        console.error('API call failed:', error)
      })
    setCourseId(classDetail?.course_id)
    setExceptedSections(classDetail?.excepted_course_section)
    setIsEnded(classDetail?.status === 'ENDED' ? true : false)
  }, [])

  const classType = user?.program

  const getLevel = (() => {
    switch (classType) {
      case PROGRAM.ACCA:
        return 'acca_level'
      case PROGRAM.CFA:
        return 'level'
      case PROGRAM.CMA:
        return 'cma_level'
      default:
        return null
    }
  })()

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10 flex sapp-min-h-100vh'>
        <div className='py-8 px-10 '>
          {/* Utilities */}
          <div className={`row ${clsx({ 'mb-5': totalUser })}`}>
            <div className='col-4'>
              <Search
                value={watch('text')}
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search student'}
                onSubmit={handleSearch}
                isListScreen={false}
              ></Search>
            </div>
            {classDetail?.duration_type === DURATION_TIME_TYPE.FLEXIBLE && (
              <div className='col-4'>
                <HookFormSelectAntd
                  name='class_flexible_status'
                  placeholder='Status'
                  control={control}
                  showSearch
                  handleNextPage={() => {}}
                  onSearch={async (e: string) => {
                    return
                  }}
                  onFocus={async () => {}}
                  onChange={async (e: any) => {}}
                  loading={loading}
                  allowClear
                >
                  {STUDENT_STATUS.map((item: { label: string; value: string }) => {
                    return (
                      <Option key={item?.label} value={item?.value}>
                        {item?.label}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>
            )}
            {!!watch('class_flexible_status') && (
              <div className='col-4'>
                <RangeDateTimePicker
                  control={control}
                  name='registry'
                  allowClear={true}
                  placeholder={['From', 'To']}
                />
              </div>
            )}
          </div>
          <div className='row mt-5'>
            <div className='col-6 d-flex justify-content-start'>
              <SAPPFIlterButton
                okClick={handleSubmit(handleSearch)}
                resetClick={handleReset}
                titleReset='Reset'
                titleSubmit={BUTTON_TEXT.SEARCH}
                disabled={loading}
              />
            </div>
            <div className='col-6 d-flex justify-content-end'>
              {/* If more than a checkbox is select, show Delete button instead of Import/Add buttons */}
              {isShowGroupDeleteButton ? (
                <UserListGrouping
                  okButtonCaption='Yes'
                  cancelButtonCaption='No'
                  body='Bạn có chắc chắn muốn xóa không?'
                  selected={checkedList}
                  blockUser={blockUser}
                  openBlocked={openBlocked}
                  setOpenBlocked={setOpenBlocked}
                  title={'Delete Selected'}
                  loading={loading}
                  className='sapp-selected-user'
                />
              ) : (
                <>
                  {!isEnded && shouldRender && (
                    <div className='d-flex'>
                      {allowRenderImportStudent && (
                        <ButtonIconPrimary
                          className='me-3'
                          iconName={'file-up'}
                          title={'Import Student'}
                          onClick={() => setOpenUpload(true)}
                          size='small'
                          iconType='outline'
                        />
                      )}
                      {allowRenderAddStudent && (
                        <ButtonIconPrimary
                          iconName='plus'
                          title='Add Student'
                          onClick={handleOnClick}
                          size='small'
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <ModalImportClassStudent
            open={openUpload}
            setOpen={setOpenUpload}
            id={id}
            getClassStudents={getClassStudents}
          />
          {/* Title */}
          <h5 className='fw-bold fs-6 mt-8'>
            Danh sách học viên ({totalUser || 0})
            {(totalUser || 0) > Number(classDetail?.capacity) && (
              <span className='ms-5 text-danger'>
                * Số học viên đã vượt capacity của lớp {totalUser}/{Number(classDetail?.capacity)}
              </span>
            )}
          </h5>
          {/* Student Table */}
          <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
            <div className='container'>
              <div className='row px-4 py-8 sapp-overflow-y-layout'>
                <SappTable
                  headers={headers}
                  loading={loadingStudent}
                  data={user?.students}
                  isCheckedAll={isCheckedAll}
                  disabled={isEnded}
                  onChange={() => {
                    toggleCheckAll(!isCheckedAll, true)
                  }}
                >
                  {loading ? (
                    <>
                      {headers.map((header, i) => (
                        <LoadingTable key={header.label} headers={headers} />
                      ))}
                    </>
                  ) : (
                    <>
                      {user?.students?.map((item: any, i: number) => {
                        const type: string = item.type
                        const hasRequiredProps = item?.started_at && item?.finished_at
                        const isChecked = checkedList.includes(item?.user_id)
                        return (
                          <tr className='border-0' key={item?.user_id ?? i}>
                            <td>
                              <SAPPCheckbox
                                checkTarget='#kt_table_users .form-check-input'
                                checked={isChecked}
                                ktCheck={isChecked}
                                onChange={() => {
                                  toggleCheck(item?.user_id!)
                                }}
                                disabled={isEnded}
                              />
                            </td>
                            <td className='fs-6 lh-1 fw-semibold sapp-item-column'>
                              {item?.user?.hubspot_contact_id || item?.user?.employee_code || '--'}
                            </td>
                            <td>
                              <Link
                                className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                                to={`${STUDENT_PROFILE}/${item?.user_id}/overview`}
                              >
                                {item?.user?.detail?.full_name}
                              </Link>
                            </td>
                            <td>{item?.user?.user_contacts?.[0]?.email}</td>
                            <td>{item?.user?.user_contacts?.[0]?.phone}</td>
                            <td>{getLevel ? item?.user?.detail?.[getLevel] || '-' : '-'}</td>
                            <td>
                              {item?.flexible_duration &&
                              item?.started_at === null &&
                              item?.finished_at === null ? (
                                <>
                                  {`${item?.flexible_duration} ${
                                    item?.flexible_duration > 1 ? 'days' : 'day'
                                  }`}
                                </>
                              ) : (
                                <>
                                  <span>
                                    {(() => {
                                      if (item?.started_at === null) {
                                        return ''
                                      }
                                      const date = new Date(item?.started_at)
                                      return `${format(date, 'dd/MM/yyyy')}`
                                    })() ?? ''}
                                  </span>
                                  <span> - </span>
                                  <span>
                                    {(() => {
                                      if (item?.finished_at === null) {
                                        return ''
                                      }
                                      const date = new Date(item?.finished_at)
                                      return `${format(date, 'dd/MM/yyyy')}`
                                    })() ?? ''}
                                  </span>
                                </>
                              )}
                            </td>
                            {item?.is_passed ? (
                              <td
                                onClick={() => {
                                  setOpenProcess({
                                    status: true,
                                    id: id,
                                    studentId: item?.user_id,
                                    type: 'process',
                                  })
                                }}
                                className='sapp-color-percent cursor-pointer'
                              >
                                {`${
                                  round(
                                    (item?.learning_progress?.total_course_sections_completed /
                                      item?.learning_progress?.total_course_sections) *
                                      100,
                                    2
                                  ) || '0'
                                }%`}
                              </td>
                            ) : (
                              <td>Foundation</td>
                            )}
                            <td>{item?.user?.type_user === 'STUDENT' ? 'Student' : 'Employee'}</td>
                            <td>
                              {item.user?.is_sapp_operator ? (
                                <SAPPBadge className={`primary`} label={`Staff Account`} />
                              ) : (
                                <SAPPBadge
                                  className={
                                    (CLASS_USER_TYPE_BADGE as any)?.[type]?.color || 'light'
                                  }
                                  label={(CLASS_USER_TYPE_BADGE as any)?.[type]?.label}
                                />
                              )}
                            </td>
                            <td className='text-end'>
                              {((hasRequiredProps && allowRenderEditDuration) ||
                                allowRenderEditCourseContentClass) && (
                                <StudentActions
                                  id={item?.user_id}
                                  listUsers={user?.students}
                                  setUser={setUser}
                                  action={handleAction}
                                  classId={id}
                                  getClassStudents={getClassStudents}
                                  user={item}
                                  confirm={confirm}
                                  isPassed={item?.is_passed}
                                  isEnded={isEnded}
                                  normalClassConnections={normalClassConnections}
                                  type={item?.type}
                                  classType={classDetail.type}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                </SappTable>
                <PagiantionSAPP
                  currentPage={user?.meta?.page_index || 1}
                  pageSize={user?.meta?.page_size || 10}
                  totalItems={totalUser}
                  handlePaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
          <ModalAddStudentsInClass
            open={open}
            setOpen={setOpen}
            type='STUDENT'
            getClassStudents={getClassStudents}
          />
          <ModalUpdateUserDuration
            open={openUpdateDuration.status}
            setOpen={setOpenUpdateDuration}
            data={openUpdateDuration?.data}
            studentId={openUpdateDuration?.studentId}
            getClassStudents={() => getClassStudents()}
          />
          <ModalProcessing
            open={openProcess.status}
            setOpen={setOpenProcess}
            type={openProcess.type}
            title={openProcess.type === 'process' ? 'Learning Progress' : 'Edit Course Content'}
            id={openProcess.id}
            exceptedSections={exceptedSections}
            refetch={() => getClassStudents()}
            studentId={openProcess.studentId}
            classId={id}
            isEnded={isEnded}
          />
          <ModalTransfer
            open={openTransferModal.status}
            setOpen={setOpenTransferModal}
            type='STUDENT'
            getClassStudents={getClassStudents}
            transferFromId={openTransferModal.studentId}
          />
        </div>
      </div>
    </>
  )
}
export default ListStudent
