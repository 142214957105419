import React from 'react'
import { Link } from 'react-router-dom'
import { IButtonProps } from 'src/type'

const ButtonSecondary = ({
  title,
  onClick,
  className = 'me-5',
  link,
  disabled,
  type,
  isListScreen,
}: IButtonProps) => {
  if (link)
    return (
      <Link to={link} className={` btn btn-light me-3 ${className ?? ''}`}>
        <i className='ki-outline ki-check fs-3 d-none'></i>
        <span className='indicator-label'>{title}</span>
        <span className='indicator-progress'>
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </Link>
    )

  return (
    <button
      className={`btn btn-light ${className ?? ''} ${isListScreen ? 'sapp-h-40' : 'sapp-h-45px'}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {title}
    </button>
  )
}

export default ButtonSecondary
