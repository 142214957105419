import { IDate, IMetadata, RobotMeta } from './common'

export interface IBlogCategory extends IDate {
  id: string
  name: string
  slug?: string
  describe?: string
  meta_title?: string
  meta_description?: string
  focus_keyword?: string
  robot_meta?: string
  canonical_url: string
  parent_id?: string
  parent?: {
    id: string
    name: string
  }
  deleted_at?: Date
}

export interface IBlogCategoryUpdate {
  name: string
  slug?: string
  describe?: string
  meta_title?: string
  meta_description?: string
  focus_keyword?: string
  robot_meta?: any
  canonical_url?: string
  parent_id?: string
}

export interface IBlogCategoryList {
  metadata: IMetadata
  blogCategories: IBlogCategoryListItem[]
}

export interface IBlogCategoryListItem extends IDate {
  id: string
  name: string
  slug?: string
  describe?: string
  parent?: {
    id: string
    name: string
  }
  blog_count: number
}
//  blog

export interface IBlogListItem extends IDate {
  id: string
  title: string
  status: string
  primary_shop_blog_category: {
    id: string
    name: string
  }
  shop_blog_author: {
    id: string
    name: string
  }
  shop_blog_categories: [
    {
      id: string
      name: string
    }
  ]
}

export interface IBlogList {
  blogs: IBlogListItem[]
  meta: IMetadata
}

export interface IBlog extends IDate {
  id: string
  deleted_at: Date | null
  title: string
  content: string
  thumbnail?: {
    ORIGIN: string
  }
  status: string
  blog_time: Date
  start_time: Date | null
  end_time: Date | null
  prefix_url: string
  suffix_url: string
  meta_title: string
  meta_description: string
  focus_keyword: string
  blog_slug: string
  robot_meta: string
  canonical_url: string
  primary_blog_category_id: string
  shop_blog_author?: {
    id: string
    name: string
  }
  shop_blog_tag?: {
    id: string
    name: string
  }
  shop_blog_categories: [
    {
      id: string
      name: string
    }
  ]
  primary_shop_blog_category?: {
    id: string
    name: string
  }
}

export interface IBlogUpdate extends IDate {
  title: string
  content: string
  thumbnail: any
  status: string
  blog_time: Date
  start_time: Date | null
  end_time: Date | null
  prefix_url: string
  suffix_url: string
  meta_title: string
  meta_description: string
  focus_keyword: string
  blog_slug: string
  robot_meta?: RobotMeta
  canonical_url: string
  primary_blog_category_id: string
  blog_author_id: string
  tag_id?: string
  blog_category_ids: string[]
}

export interface IInitialValue {
  id?: string
  title?: string
  category_id?: string
  author_id?: string
  tag_id?: string
  status?: string
  sortType?: string
  fromDate?: Date | string
  toDate?: Date | string
}

export interface IBlogFilter {
  title: string
  category_id: string
  author_id: string
  tag_id: string
  status: string
  sortType: string
  lastRegistry: any
}

export enum EBlogStatus {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISHED',
  TIMER = 'TIMER',
}
