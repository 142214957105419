import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { KTCardBody } from 'src/_metronic/helpers'
import { CoursesAPI } from 'src/apis/courses'
import LoadingTable from 'src/common/LoadingTable'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import SappTable from 'src/components/base/SappTable'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CourseType from 'src/components/courses/CourseType'
import ListFilterLayout from 'src/components/layout/listFilter'
import { CLASS_TYPE, FILTER_SELECTALL_SORTBY } from 'src/constants'
import { LANG_COURSES } from 'src/constants/lang'
import useSubjects from 'src/hooks/useSubjects'
import {
  EStatusCourse,
  IClassType,
  ICourseCategoies,
  ICourseList,
  ICourseType,
} from 'src/type/courses'
import { ISubject } from 'src/type/subject'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  selectCourse: any
  courseList: ICourseList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchCourseList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
  typeClass: string
  courseCategoryId?: string
  subject_id?: string
  program?: string
  subject?: string
}

// define headers
const headers = [
  {
    label: LANG_COURSES.code,
    className: 'min-w-180px',
  },
  {
    label: LANG_COURSES.courseName,
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Category',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Subject',
    className: 'min-w-250px pe-5',
  },
  {
    label: 'Type',
    className: 'min-w-100px pe-5',
  },
]
const TableCourses = ({
  selectCourse,
  courseList,
  loading,
  setLoading,
  fetchCourseList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  typeClass,
  courseCategoryId,
  subject_id,
  program,
  subject,
}: IProps) => {
  const isRevision = () => typeClass === CLASS_TYPE.REVISION.toUpperCase()
  const initialValues: any = {
    name: '',
    status: ['PUBLISH', 'LOCK'],
    sortType: '',
    fromDate: '',
    toDate: '',
  }
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [searchParams, setSearchParams] = useState<any>(initialValues)
  const validationSchema = z.object({
    name: z.string().optional(),
    course_category_ids: z.string().optional(),
    subject_id: z.string().optional(),
    status: z.any().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  const fieldNames = [
    'course_category_ids',
    'subject_id',
    'name',
    'status',
    'sortType',
    'fromDate',
    'toDate',
  ]

  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const cleanedParams = {
          status: [EStatusCourse.PUBLISH, EStatusCourse.LOCK],
        }
        const res = await CoursesAPI.getCategory({
          page_index: 1,
          page_size: 100,
          params: cleanedParams,
        })
        setCourseCategory(res?.data)
      } catch (error) {}
    }
    fetchCourseCategory()
  }, [])
  // Using validate for input
  const { control: controlStatus, setValue: setValueStatus } = useForm<any>({
    mode: 'onChange',
  })

  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      course_category_ids: isRevision() ? courseCategoryId : null,
      subject_id: isRevision() ? subject_id : null,
    },
  })

  useEffect(() => {
    courseList?.courses
      ?.map((course) => ({ label: course.status.toLocaleLowerCase(), value: course.status }))
      ?.forEach((option, index) => {
        setValueStatus(`status${index}`, option.value)
      })
  }, [setValueStatus, courseList?.courses])

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    getListCourse({ page_index, page_size })
  }

  const getListCourse = (
    { page_index, page_size }: { page_index: number; page_size: number },
    course_category_id?: string,
    subject_id?: string
  ) => {
    const dateInfoFromDate = searchParams.fromDate
    const dateInfoToDate = searchParams.toDate
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams.name,
        searchParams.course_category_ids,
        searchParams.subject_id,
        searchParams.status,
        typeClass === IClassType.TRIAL
          ? [ICourseType.TRIAL]
          : typeClass === IClassType.LESSON
          ? [ICourseType.NORMAL]
          : [ICourseType.PRACTICE],
        searchParams.sortType,
        searchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        searchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    if (course_category_id && subject_id && typeClass === IClassType.REVISION.toUpperCase()) {
      cleanedParams.course_category_ids = course_category_id
      cleanedParams.subject_id = subject_id
      fetchCourseList(page_index || 1, page_size || 10, cleanedParams)
    } else {
      fetchCourseList(page_index || 1, page_size || 10, cleanedParams)
    }
  }

  useEffect(() => {
    debounceSearchSubject({ course_category_ids: courseCategoryId })
    getListCourse({ page_index: 1, page_size: 10 }, courseCategoryId, subject_id)
    setLoading(true)
  }, [])

  const newCourseCategory = courseCategory?.course_categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }))
  const handleResetFilter = () => {
    setLoading(true)
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    interface ICleanedParams {
      status: string[]
      course_types: ICourseType[]
      course_category_ids?: string
      subject_id?: string
    }
    let cleanedParams: ICleanedParams = {
      status: ['PUBLISH', 'LOCK'],
      course_types:
        typeClass === IClassType.TRIAL
          ? [ICourseType.TRIAL]
          : typeClass === IClassType.LESSON
          ? [ICourseType.NORMAL]
          : [ICourseType.PRACTICE],
    }
    if (courseCategoryId && subject_id && typeClass === IClassType.REVISION.toUpperCase()) {
      cleanedParams.course_category_ids = courseCategoryId
      cleanedParams.subject_id = subject_id
    }
    setLoading(true)
    setSearchParams(initialValues)
    // setDateField('')
    fetchCourseList(1, 10, cleanedParams)
  }
  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('course_category_ids')),
        replaceValueAll(getValues('subject_id')),
        replaceValueAll(getValues('status')),
        typeClass === IClassType.TRIAL
          ? [ICourseType.TRIAL]
          : typeClass === IClassType.LESSON
          ? [ICourseType.NORMAL]
          : [ICourseType.PRACTICE],
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    setLoading(true)
    setSearchParams(cleanedParams)
    fetchCourseList(1, queryParams.page_size || 10, cleanedParams)
  }
  const { debounceSearchSubject, handleNextPageSubject, subjects, setSubjects, getSubjects } =
    useSubjects()

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('subject_id', '')
    if (watch('course_category_ids') !== 'all') {
      await getSubjects({ course_category_ids: watch('course_category_ids') })
    }
  }

  return (
    <div className='card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <ListFilterLayout>
            {/* begin:: Search */}
            <HookFormTextField
              control={control}
              name='name'
              placeholder='Search'
              defaultValue={queryParams?.name}
              onSubmit={onSubmit}
              isListScreen
            />
            {/* end:: Search */}
            <HookFormSelectAntd
              control={control}
              name='course_category_ids'
              placeholder={isRevision() ? program : 'Category'}
              defaultValue={queryParams.course_category_ids ?? ''}
              showSearch
              classNameHeight='sapp-h-40'
              onChange={handleCourseCategoryChange}
              disabled={isRevision()}
            >
              {newCourseCategory?.map((category) => (
                <Option key={category.value} value={category.value}>
                  {category.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              control={control}
              name='subject_id'
              placeholder={isRevision() ? subject : 'Subject'}
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchSubject(e)
              }}
              handleNextPage={(e: any) =>
                handleNextPageSubject({
                  name: e,
                  course_category_ids: watch('course_category_ids'),
                })
              }
              showSearch
              classNameHeight='sapp-h-40'
              loading={loading}
              defaultValue={queryParams?.subject_id ?? ''}
              disabled={isRevision()}
            >
              {subjectCourse?.map((subject: any) => (
                <Option key={subject.value} value={subject.value}>
                  {subject.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              size='large'
              defaultValue={queryParams?.sortType ?? ''}
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>
            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div className='flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={onSubmit}
            resetClick={handleResetFilter}
            disabled={loading}
          />
        </div>
      </div>
      <KTCardBody>
        <SappTable
          headers={headers}
          loading={loading}
          data={courseList?.courses}
          isCheckedAll={false}
          hasCheckAll={false}
          onChange={() => {
            toggleCheckAll(!isCheckedAll, true)
          }}
        >
          {loading ? (
            <>
              {Array(5).map((header, i) => (
                <LoadingTable key={header} headers={Array(5)} />
              ))}
            </>
          ) : (
            <>
              {courseList?.courses?.map((course) => {
                const isChecked = selectCourse[0]?.id.includes(course.id)

                return (
                  <tr key={course.id}>
                    <td>
                      <SAPPRadio
                        checkTarget='#kt_table_users .form-check-input'
                        checked={isChecked}
                        ktCheck={isChecked}
                        onChange={() => {
                          toggleCheck(course)
                        }}
                      />
                    </td>
                    <td className='text-start'>{course?.code ?? '-'}</td>
                    <td className='text-start sapp-text-primary fs-6 fw-semibold'>
                      {course?.name}
                    </td>
                    <td className='text-start'>{course?.course_categories?.[0]?.name ?? '-'}</td>
                    <td className='text-start'>{course?.subject.name ?? '-'}</td>
                    <td className='text-start'>
                      <CourseType type={course.course_type} />
                    </td>
                  </tr>
                )
              })}
            </>
          )}
        </SappTable>
      </KTCardBody>
      <PagiantionSAPP
        currentPage={courseList?.metadata?.page_index || 1}
        pageSize={courseList?.metadata?.page_size || 10}
        totalItems={courseList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableCourses
