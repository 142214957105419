import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Control, Controller, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import { checkTypeFile } from 'src/utils'
import { SAPPUploadField } from '.'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'

interface IProps {
  required?: boolean | undefined
  defaultValue?: string
  name: string
  control: Control<any>
  setValue: UseFormSetValue<any>
  multiple?: boolean
  accept?: string
  imagePreview: string
  guideline?: string[]
  maxFileSize?: number
  setError: UseFormSetError<any>
  uploadSuccess?: boolean
  setShowAvatarDefault?: Dispatch<SetStateAction<boolean>>
  setShowFrontDefault?: Dispatch<SetStateAction<boolean>>
  setShowBackDefault?: Dispatch<SetStateAction<boolean>>
  removeAvatar: string
  width?: string
  height?: string
  disabled?: boolean
  className?: string
  poster?: string
}

const SAPPHookUploadFile = ({
  required,
  defaultValue,
  name,
  control,
  setValue,
  multiple,
  accept,
  imagePreview,
  guideline,
  maxFileSize,
  setError,
  uploadSuccess,
  setShowAvatarDefault,
  setShowBackDefault,
  setShowFrontDefault,
  removeAvatar,
  width,
  height,
  disabled = false,
  className = '',
  poster,
}: IProps) => {
  const [preview, setPreview] = useState<string>()
  const handlePreview = (val: File) => {
    setPreview(URL.createObjectURL(val))
  }

  useEffect(() => {
    imagePreview && setPreview(imagePreview)

    return () => {
      setPreview('')
    }
  }, [imagePreview])

  const handleRemovePreview = () => {
    setPreview(removeAvatar ?? avatar)
    setValue(name, undefined)
    setShowAvatarDefault && setShowAvatarDefault(true)
    setShowFrontDefault && setShowFrontDefault(true)
    setShowBackDefault && setShowBackDefault(true)
  }

  useEffect(() => {
    if (!uploadSuccess) return

    setPreview(removeAvatar ?? avatar)
    setValue(name, undefined)
  }, [name, setValue, uploadSuccess])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowAvatarDefault && setShowAvatarDefault(true)
    setShowFrontDefault && setShowFrontDefault(true)
    setShowBackDefault && setShowBackDefault(true)

    if (!e.target.files) return
    const file = e.target.files[0]

    if (!file) {
      setError(name, {
        message: '',
      })
      return
    }

    const isValidFileType = checkTypeFile(file.type)

    if (!isValidFileType) {
      setValue(name, undefined)
      setError(name, {
        message: 'Not Supported!',
      })
      setPreview(avatar)
      return
    }

    setValue(name, file)

    if (handlePreview) {
      setError(name, {
        message: '',
      })
      handlePreview(file)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value }, fieldState: { error } }) => {
        return (
          <SAPPUploadField
            required={required}
            defaultValue={value ? undefined : defaultValue}
            onChange={handleChange}
            accept={accept}
            multiple={multiple}
            maxFileSize={maxFileSize}
            src={preview}
            onRemove={handleRemovePreview}
            errorMessage={error?.message}
            guideline={guideline}
            removeAvatar={removeAvatar}
            width={width}
            height={height}
            disabled={disabled}
            className={className}
            poster={poster}
          />
        )
      }}
    />
  )
}

export default SAPPHookUploadFile
