import { Drawer } from 'antd'
import { ReactNode, useEffect, useRef } from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import { useConfirm } from 'src/hooks/use-confirm'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import LoadingCard from '../my-profile/overview-profile/loading/LoadingCard'
import { Toaster } from 'react-hot-toast'

type Props = {
  open: boolean | undefined
  children: ReactNode
  title: string
  rootClassName?: string
  cancelButtonCaption?: string
  okButtonCaption?: string
  handleCancel?: () => void
  handleClose?: () => void
  handleSubmit: () => void
  loading?: boolean
  dialogClassName?: string
  toolbar?: ReactNode
  footer?: (data: {
    cancelButtonCaption?: string
    okButtonCaption?: string
    handleCancel?: () => void
    handleClose?: () => void
    handleSubmit: () => void
    loading?: boolean
  }) => ReactNode
  placement?: 'left' | 'right' | 'top' | 'bottom'
  width?: 'normal' | string
  confirmOnclose?: boolean | string[]
  body?: string
  classNameHeader?: string
  footerClassName?: string
  disabled?: boolean
  classNameSubmit?: string
  classNameCancel?: string
  classNameBody?: string
  showCancelBtn?: boolean
  showThirdBtn?: boolean
  classNameThird?: string
  thirdButtonCaption?: string
  thirdOnClick?: any
  thirdButtonLoading?: boolean
}

const SappDrawer = ({
  children,
  open,
  handleClose,
  title,
  rootClassName,
  handleCancel,
  handleSubmit,
  loading,
  cancelButtonCaption = 'Cancel',
  okButtonCaption = 'Submit',
  dialogClassName,
  toolbar,
  footer,
  placement = 'right',
  width = 'normal',
  confirmOnclose = false,
  body,
  classNameHeader,
  footerClassName,
  disabled,
  classNameSubmit,
  classNameCancel,
  classNameBody,
  showCancelBtn = true,
  showThirdBtn = false,
  classNameThird,
  thirdButtonCaption,
  thirdOnClick,
  thirdButtonLoading,
}: Props) => {
  const { confirm, contextHolder } = useConfirm()
  const getDrawerWidth = () => {
    if (width === 'normal') {
      if (window.innerWidth >= 1200) {
        return 550
      } else {
        return 350
      }
    }
    return width
  }
  const onClose = (action?: () => void) => {
    if (confirmOnclose) {
      let body = [DESCRIPTION_POPUPCONFIRM]
      if (Array.isArray(confirmOnclose)) {
        body = confirmOnclose
      }
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body,
        onClick: () => {
          action && action()
        },
      })
    } else {
      action && action()
    }
  }

  const drawerBodyRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const drawerBodyElement = drawerBodyRef.current
    if (drawerBodyElement) {
      const hasScrollBar = drawerBodyElement.scrollHeight > drawerBodyElement.clientHeight
      drawerBodyElement.classList.toggle('pe-4', hasScrollBar)
      drawerBodyElement.classList.toggle('pr-[6px]', !hasScrollBar)
    }
  }, [children, drawerBodyRef])

  return (
    <>
      {contextHolder}
      <Drawer
        placement={placement}
        closable={false}
        destroyOnClose={true}
        onClose={() => {
          onClose(handleClose)
        }}
        open={open}
        className={`${dialogClassName} mw-100`}
        rootClassName={rootClassName}
        autoFocus={true}
        contentWrapperStyle={{
          minWidth: getDrawerWidth(),
          maxWidth: '100%',
        }}
      >
        <Toaster
          toastOptions={{ className: 'sapp-mw-1000px custom-toast', style: { zIndex: 1000 } }}
        />
        <div
          className='card w-100 border-0 rounded-0 d-flex flex-column justify-content-between min-vh-100 vh-100'
          id='kt_drawer'
        >
          <div
            className={`card-header sapp-px-32px ${classNameHeader ?? ''}`}
            id='kt_drawer_header'
          >
            {body ? (
              <div className='d-grid'>
                <div className={`mb-0 align-items-end card-title`}>
                  <div className='d-flex justify-content-center flex-column'></div>
                  <div className='sapp-drawer-title'>{title}</div>
                </div>
                <div className='mt-2 sapp-text-drawer--description'>{body}</div>
              </div>
            ) : (
              <div className='card-title'>
                <div className='d-flex justify-content-center flex-column'></div>
                <div className='sapp-drawer-title '>{title}</div>
              </div>
            )}
            <div className='card-toolbar'>
              {toolbar && <div className='me-0'>{toolbar}</div>}
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                onClick={() => {
                  onClose(handleClose)
                }}
              >
                <i className='ki-outline ki-cross fs-1' />
              </div>
            </div>
          </div>
          <div
            className='card-body flex-1 overflow-hidden sapp-px-32px sapp-pt-12px sapp-scrollbar'
            id='kt_drawer_body'
          >
            <div
              ref={drawerBodyRef}
              className={`scroll-y me-n5 h-100 ${classNameBody ?? 'pe-5'}`}
              data-kt-scroll='true'
              data-kt-scroll-activate='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-dependencies='#kt_drawer_header, #kt_drawer_footer'
              data-kt-scroll-wrappers='#kt_drawer_body'
              data-kt-scroll-offset='0px'
            >
              {children}
            </div>
          </div>
          <div className={`card-footer ${footerClassName}`} id='kt_drawer_footer'>
            {footer ? (
              footer({
                cancelButtonCaption,
                okButtonCaption,
                handleCancel,
                handleClose: onClose,
                handleSubmit,
                loading,
              })
            ) : (
              <SAPPDialogButtonsCancelSubmit
                cancelClick={() => {
                  onClose(handleCancel || handleClose)
                }}
                cancelButtonCaption={cancelButtonCaption}
                okButtonCaption={okButtonCaption}
                okOnClick={handleSubmit}
                className='justify-content-end d-flex m-0'
                loading={loading}
                disabled={disabled}
                classNameCancel={classNameCancel}
                classNameSubmit={classNameSubmit}
                showCancelBtn={showCancelBtn}
                thirdOnClick={thirdOnClick}
                showThirdBtn={showThirdBtn}
                thirdButtonCaption={thirdButtonCaption}
                classNameThird={classNameThird}
                thirdButtonLoading={thirdButtonLoading}
              />
            )}
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default SappDrawer
