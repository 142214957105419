import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import { resetPassUser, UsersAPI } from 'src/apis/user'
import PopupConfirm from 'src/common/PopupConfirm'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'
import ModalResetPassword from 'src/components/user-list/components/user-edit-modal/ModalResetPassword'
import UserAction from 'src/components/user-management/UserAction'
import { PageLink, TEACHER_PROFILE } from 'src/constants'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'

type Props = {
  id: any
  status: 'BLOCKED'
  email: string
}

const TeacherActionsCell: FC<Props> = ({ id, status, email }) => {
  const [openReset, setOpenReset] = useState(false)
  const [openBlocked, setOpenBlocked] = useState(false)
  const { clearSelected } = useListView()
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const [openModalShowPass, setOpenModalShowPass] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [isActive, setIsActive] = useState(false)
  const queryClient = useQueryClient()
  const { query, refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const blockUser = useMutation(() => UsersAPI.blocked([id]), {
    onSuccess: () => {
      queryClient.invalidateQueries([`teachers-${query}`])
      clearSelected()
      toast.success('Blocked Successfully!')
      setOpenBlocked(false)
      cancel(true)
    },
    onError: () => {},
  })

  const handleShowPassword = async () => {
    await resetPassUser(id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setOpenReset(false)
        setOpenModalShowPass(true)
        setNewPassword(res?.data?.password)
      })
      .catch((err) => {})
  }

  return (
    <>
      <UserAction
        setOpenBlocked={setOpenBlocked}
        setOpenReset={setOpenReset}
        status={status}
        linkProfile={`${TEACHER_PROFILE}/${id}/overview`}
        setOpenEditEmail={setOpenEditEmail}
        linkEdit={`${TEACHER_PROFILE}/${id}/setting`}
      />
      <PopupConfirm
        open={openBlocked}
        setOpen={setOpenBlocked}
        onClick={async () => await blockUser.mutateAsync()}
      />
      <PopupConfirm
        open={openReset}
        setOpen={setOpenReset}
        onClick={handleShowPassword}
        body='Bạn có chắc chắn muốn đổi mật khẩu không?'
        okButtonCaption='Yes, reset!'
      />
      <ModalResetPassword
        open={openModalShowPass}
        setOpen={setOpenModalShowPass}
        newPassword={newPassword}
        content='Mật khẩu mới của giáo viên là:'
      />
      <ModalEditEmail
        id={id}
        open={openEditEmail}
        setOpen={setOpenEditEmail}
        isActive={isActive}
        setIsActive={setIsActive}
        currentEmail={email}
        refetch={cancel}
      />
    </>
  )
}

export { TeacherActionsCell }
