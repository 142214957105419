import { Select } from 'antd'
import clsx from 'clsx'
import _, { isEmpty } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody } from 'src/_metronic/helpers'
import { UsersAPI } from 'src/apis/user'
import LoadingTable from 'src/common/LoadingTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseDataPagination,
} from 'src/components/teacher-list/components/core/QueryResponseProvider'
import UserCell from 'src/components/user-management/UserCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import { MOCKUP_HEADER, STATUS_FORM, TEACHER_PROFILE } from 'src/constants'
import { CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR } from 'src/constants/permission'
import { TEACHING_STATUS } from 'src/constants/teacher'
import { useUserContext } from 'src/context/UserProvider'
import { processFields } from 'src/helper/joinFields'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, PROGRAM, Role } from 'src/type'
import { IPaginationUser } from 'src/type/students'
import { TeachableInstance } from 'src/type/teacher'
import { formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { usersColumns } from './columns/_columns'
import { TeacherActionsCell } from './columns/TeacherActionsCell'
import { programOrder, sectionOrder, subjectOrder } from 'src/constants/classes'
const { Option } = Select

const TeacherTable = ({
  currentPage,
  pageSize,
  fromDate,
  sortSex,
  sortStatus,
  textSearch,
  toDate,
  course_category_id,
  subject_id,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  sex,
}: IPaginationUser) => {
  const { isLoading, refetch } = useQueryResponse()
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { rows } = useTable({ columns, data })
  const pagination = useQueryResponseDataPagination()
  const { updateState } = useQueryRequest()
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()

  // TODO: reset lại table về mặc định nếu không call API
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(fromDate as Date)
  const dateQueryToDate = getDateInfo(toDate as Date)

  const existFilter =
    !isEmpty(sortSex) ||
    !isEmpty(textSearch) ||
    !isEmpty(sortStatus) ||
    !isEmpty(fromDate) ||
    !isEmpty(toDate) ||
    !isEmpty(sex) ||
    !isEmpty(course_category_id) ||
    !isEmpty(subject_id)

  // TODO: dùng để đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: textSearch ?? '',
      sex: sex ?? '',
      status: sortStatus ?? '',
      fromDate: formatDate(fromDate as Date) ?? '',
      toDate: formatDate(toDate as Date) ?? '',
      course_category_id: course_category_id ?? '',
      subject_id: subject_id ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  useEffect(() => {
    updateState({
      page_size: pageSize,
      page_index: currentPage,
      text: textSearch,
      sex: sortSex,
      status: sortStatus,
      fromDate: fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      toDate: toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      course_category_id: course_category_id,
      subject_id: subject_id,
    })
  }, [])

  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderEditStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderResetPassword = hasPermission(
    profileMe?.roles,
    TITLE_GR.PUT_RESET_PASSWORD_USER_GR
  )
  const allowRenderResetPasswordStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.RESET_PASSWORD_STAFF
  )
  const allowRenderBlock = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderBlockStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderEditEmail = hasPermission(profileMe?.roles, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR)
  const allowRenderEditEmailStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.CHANGE_EMAIL_STAFF
  )
  const allowRenderViewProfile = hasPermission(profileMe?.roles, TITLE_GR.VIEW_USER_GR)
  const allowRenderViewProfileStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.GET_STAFF)

  const allowRenderAction =
    allowRenderEdit ||
    allowRenderEditStaff ||
    allowRenderResetPassword ||
    allowRenderResetPasswordStaff ||
    allowRenderBlock ||
    allowRenderBlockStaff ||
    allowRenderEditEmail ||
    allowRenderEditEmailStaff ||
    allowRenderViewProfile ||
    allowRenderViewProfileStaff
  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })

  const changeStatusUser = (id: string, status: any) => {
    UsersAPI.updateUserStatus({ id, status })
      .then(() => toast.success('Update Successfully!'))
      .catch((error) => {})
  }

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: () =>
        changeStatusUser(
          id,
          EStatusUser.ACTIVE === value
            ? EStatusUser.ACTIVE
            : EStatusUser.INACTIVE === value
            ? EStatusUser.INACTIVE
            : EStatusUser.BLOCKED
        ),
      onClose: () => cancel(true),
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }

  useEffect(() => {
    rows
      ?.map((staff: any) => ({
        label: staff?.original?.status?.toLocaleLowerCase(),
        value: staff?.original?.status,
      }))
      ?.forEach((option, index) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, rows, changeStatusUser])

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    updateState({
      page_size: page_size,
      page_index: page_index,
      text: textSearch ?? '',
      sex: sex ?? '',
      status: sortStatus ?? '',
      fromDate: fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      toDate: toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      course_category_id: course_category_id ?? '',
      subject_id: subject_id ?? '',
    })
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  const getBadgeColor = (item: any) => {
    switch (item.toUpperCase()) {
      case PROGRAM.CFA:
        return 'green'
      case PROGRAM.ACCA:
        return 'danger'
      case PROGRAM.CMA:
        return 'success'

      default:
        return 'danger'
    }
  }

  const getPrefix = (item: string) =>
    item.startsWith('CFA-') ? 'CFA' : item.startsWith('CMA-') ? 'CMA' : 'ACCA'

  return (
    <KTCardBody className='py-4'>
      {contextHolder}
      <SappTable
        headers={[
          { label: 'code' },
          { label: 'user' },
          { label: 'username' },
          { label: 'phone' },
          { label: 'status' },
          { label: 'date' },
          { label: 'teaching status' },
          { label: 'program' },
          {
            label: 'subject',
          },
          { label: 'section' },
        ]}
        loading={isLoading}
        data={rows}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {isLoading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {rows?.map((user: any, index) => {
              const isChecked = checkedList.includes(user?.original?.id)
              const defaultContact = user?.original?.user_contacts.find(
                (contact: any) => contact.is_default === true
              )
              const teachableInstance = user?.original?.teacher_teachable_instances_mapping
              const program = processFields(teachableInstance, 'category', 'name')
              const subject = processFields(teachableInstance, 'subject', 'code')
              const groupedSubjects = programOrder
                .map((category) => subject.filter((item) => getPrefix(item) === category))
                .filter((group) => group.length > 0)

              const section: {
                code: string
                program: string
              }[] = teachableInstance?.flatMap((item: TeachableInstance) =>
                item?.feature_section_instances?.map((instance) => {
                  return {
                    code: instance?.code,
                    program: item.category.name,
                  }
                })
              )

              const groupedSections = programOrder
                .map((category) => section?.filter((item) => item.program === category))
                .filter((group) => group?.length > 0)

              return (
                <tr key={user?.original?.id ?? index}>
                  <td>
                    <SAPPCheckbox
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(user?.original?.id)
                      }}
                    />
                  </td>
                  <td className='min-w-150px'>
                    <UserCell data={user?.original?.key} />
                  </td>
                  <td className='min-w-275px'>
                    <UserInfoCell
                      user={user?.original}
                      linkViewProfile={`${TEACHER_PROFILE}/${user?.original.id}/overview`}
                    />
                  </td>
                  <td className='min-w-150px'>
                    <UserCell data={user?.original?.username} />
                  </td>
                  <td className='min-w-175px'>
                    <UserCell data={user?.original?.user_contacts?.[0]?.phone} />
                  </td>
                  <td className='min-w-175px'>
                    <HookFormSelectAntd
                      allowClear={false}
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{ maxWidth: 100 }}
                      placeholder='Status'
                      filterOption={true}
                      disabled={!allowRenderEdit}
                      onChange={(selectedValue: unknown) => {
                        return handleChange(
                          `status${index}`,
                          selectedValue as string,
                          user?.original?.id
                        )
                      }}
                    >
                      {STATUS_FORM.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </td>
                  <td className='min-w-250px'>
                    <DatetimeColumn
                      updated_at={user?.original?.updated_at}
                      created_at={user?.original?.created_at}
                    />
                  </td>
                  <td className='min-w-150px'>
                    <UserCell
                      data={
                        TEACHING_STATUS[
                          user?.original?.teacher_status as keyof typeof TEACHING_STATUS
                        ] || '-'
                      }
                    />
                  </td>
                  <td className={clsx(`min-w-200px`)}>
                    {program
                      .sort((a, b) => programOrder.indexOf(a) - programOrder.indexOf(b))
                      .map((item) => (
                        <span
                          className={`badge badge-light-${getBadgeColor(item)} fs-7 my-1 me-1`}
                          key={item}
                        >
                          {item}
                        </span>
                      ))}
                  </td>
                  <td className={`min-w-200px ${groupedSubjects.length > 1 && 'align-top'}`}>
                    {groupedSubjects.map((group, index) => (
                      <div key={programOrder[index]}>
                        {group
                          .sort((a, b) => subjectOrder.indexOf(a) - subjectOrder.indexOf(b))
                          .map((item) => (
                            <span
                              key={item}
                              className={`badge badge-light-${getBadgeColor(
                                item.substring(0, 3)
                              )} fs-7 my-1 me-1`}
                            >
                              {item.replaceAll('-', ' ')}
                            </span>
                          ))}
                      </div>
                    ))}
                  </td>
                  <td className={`min-w-250px ${groupedSubjects.length > 1 && 'align-top'}`}>
                    {groupedSections.map((group, index) => (
                      <div key={programOrder[index]} className=''>
                        {group
                          .sort(
                            (a, b) => sectionOrder.indexOf(a.code) - sectionOrder.indexOf(b.code)
                          )
                          .map((item, index) => (
                            <span
                              key={index}
                              className={`badge badge-light-${getBadgeColor(
                                item.program
                              )} fs-7 my-1 me-1`}
                            >
                              {item.code}
                            </span>
                          ))}
                      </div>
                    ))}
                  </td>
                  {allowRenderAction && (
                    <td className='text-center sapp-absolute-column'>
                      <TeacherActionsCell
                        id={user?.original?.id}
                        status={user?.original?.status}
                        email={defaultContact?.email}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <PagiantionSAPP
        currentPage={pagination?.data?.metadata?.page_index || 1}
        pageSize={pagination?.data?.metadata?.page_size || 10}
        totalItems={pagination?.data?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export { TeacherTable }
