import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

interface IProps {
  onSubmit: () => void
  onReset: () => void
  loading: boolean
  onCreate: () => void
  createTitle: string
  titleReset?: string
  titleSubmit?: string
  createIcon?: string
}

const ButtonGroupFilter = ({
  onSubmit,
  onReset,
  loading,
  onCreate,
  createTitle,
  titleReset = 'Reset',
  titleSubmit = 'Search',
  createIcon = 'plus',
}: IProps) => {
  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex'>
        <SAPPFIlterButton
          titleReset={titleReset}
          titleSubmit={titleSubmit}
          okClick={onSubmit}
          resetClick={onReset}
          disabled={loading}
          loading={loading}
        />
      </div>
      <div>
        <ButtonIconPrimary
          iconName={createIcon}
          title={createTitle}
          onClick={onCreate}
          size='small'
        />
      </div>
    </div>
  )
}

export default ButtonGroupFilter
