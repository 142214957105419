import { Progress, UploadFile } from 'antd'
import Upload, { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import React, { ReactElement, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import XLSXIcon from 'src/_metronic/assets/icons/xlsx-icon.svg'
import { fetchFormData, fetchFormJob, getBaseUrl, getJsonData } from 'src/services/request'
import './ModalImport.scss'
import ModalImportComplete from './ModalImportComplete'
import SappModal from '../SappModal'
import ButtonIconOnly from '../button/ButtonIconOnly'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'

interface IModalImportProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  icon?: string
  templateFile: string
  templateFileName: string
  accept?: string
  fileSupport?: string
  uploadUrl: string
  type?: 'STUDENT' | 'TEACHER' | 'STAFF' | 'CLASS' | 'CLASS_STUDENT' | 'TEST_PARTICIPANT' | 'exam'
  program?: string
  beforeUpload?: (file: RcFile, FileList: RcFile[]) => boolean
  refetch?: () => void
  getData?: (page_index?: number | undefined, page_size?: number | undefined) => void | undefined
}

type ProgressStatus = {
  success: string
  done: string
  error: string
  uploading: string
  removed: string
}

type ProgressStatusProp = 'success' | 'exception' | 'normal' | 'active' | undefined
const progressStatus: ProgressStatus = {
  success: 'success',
  done: 'success',
  error: 'exception',
  uploading: 'normal',
  removed: 'normal',
}
export interface ICompleteModal {
  id?: string
  open?: boolean
  errorRows: number
  totalRows: number
  fileName: string
  data?: {
    errors: {
      property: string
      errors?: [
        {
          code: string
          message: string
        }
      ]
    }[]
    rowIndex: number
  }[]
}
export const initCompleteModal = {
  open: false,
  errorRows: 0,
  totalRows: 0,
  fileName: '',
}
var hasFile = false
const ModalImport = ({
  open,
  setOpen,
  title,
  icon = XLSXIcon,
  templateFile,
  templateFileName,
  accept = '.csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
  fileSupport = 'CSV or XLSX',
  uploadUrl,
  type,
  beforeUpload,
  refetch,
  getData,
  program,
}: IModalImportProps) => {
  const [uploadFile, setUploadFile] = useState<UploadFile | undefined>()
  const [progress, setProgress] = useState<number>(0)
  const [completeModal, setCompleteModal] = useState<ICompleteModal>(initCompleteModal)

  const source = axios.CancelToken.source()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch && refetch()
    }
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleCancel = () => {
    source.cancel()
    hasFile = false
    setProgress(0)
    setOpen(false)
    setUploadFile(undefined)
  }

  const handleOpenCompleteModal = ({
    errorRows,
    totalRows,
    fileName,
    data,
    id,
  }: ICompleteModal) => {
    handleClose()
    setCompleteModal({ ...{ open: true, errorRows, totalRows, fileName, data, id } })
  }
  const handleCloseCompleteModal = () => {
    setCompleteModal({ ...initCompleteModal })
  }

  useEffect(() => {
    return () => {
      handleCancel()
    }
  }, [])

  const itemRender = (
    _originNode: ReactElement,
    _file: UploadFile,
    _fileList: UploadFile[],
    actions: { remove: () => void }
  ) => {
    const status = progressStatus[
      (uploadFile?.status as keyof ProgressStatus) ?? 'normal'
    ] as ProgressStatusProp

    let strokeColor
    if (status === 'success') {
      strokeColor = 'var(--bs-success)'
    } else if (status === 'exception') {
      strokeColor = 'var(--bs-danger)'
    } else {
      strokeColor = 'var(--bs-primary)'
    }

    return (
      <>
        <div className='sapp-upload-file-progress'>
          <div className='sapp-upload-file-progress_icon'>
            <img width={44} height={44} src={icon} alt='icon' />
          </div>

          <div className='w-100 sapp-upload-file-content'>
            <div className='sapp-upload-file-name'>{_file.name ?? _file.originFileObj?.name}</div>
            <div>
              <Progress percent={progress} status={'active'} strokeColor={strokeColor} />
            </div>
          </div>
          <ButtonIconOnly
            disabled={Math.floor(progress) > 0 && Math.floor(progress) < 100}
            iconName={'trash'}
            activeColor='danger'
            bg='none'
            onClick={() => {
              actions.remove()
              setProgress(0)
              setUploadFile(undefined)
              source.cancel()
              hasFile = false
            }}
          />
        </div>
      </>
    )
  }
  const handleUploadFile = () => {
    if (!uploadFile) {
      toast.error(`Before uploading, please choose the file in the format of the sample file.`)
      return
    }

    setProgress(0)
    setUploadFile({ ...uploadFile, status: 'uploading' })

    const fmData = new FormData()
    fmData.append('file', uploadFile.originFileObj as Blob)

    if (type === 'STUDENT' || type === 'TEACHER') {
      fmData.append('type', type)
    }

    if (type === 'exam' && program) {
      fmData.append('program', program as any)
    }

    fetchFormData({
      url: `${getBaseUrl()}/${uploadUrl}`,
      formData: fmData,
      onUploadProgress: (event: ProgressEvent) => {
        const percent = Math.floor((event.loaded / event.total) * 20)
        setProgress(percent)
      },
      source: source,
    })
      .then(async (response) => {
        hasFile = true
        const completed: any = await new Promise(async (resolve, reject) => {
          while (hasFile) {
            await new Promise((resolveDelay) => setTimeout(resolveDelay, 3000))
            try {
              const jobResponse = await fetchFormJob({
                url: 'import-logs',
                jobId: response?.data?.data?.logId,
              })
              const jobInfo = jobResponse?.data
              const message = jobInfo?.message ? JSON.parse(jobInfo?.message) : null
              const progressPct = message.detail?.progressPct
              if (progressPct) {
                setProgress(Math.floor(progressPct))
              }

              if (['SUCCESSFUL', 'COMPLETED', 'ERROR', 'FAILED'].includes(jobInfo?.status)) {
                const url = jobInfo?.files?.find(
                  (e: { file_type: string; file_url: string }) => e.file_type === 'REPORT'
                )?.file_url
                const data = await getJsonData({ url })
                const result = jobInfo?.result ? JSON.parse(jobInfo.result) : null

                resolve({
                  data,
                  totalErrorsRows: result.totalErrorsRows ?? 0,
                  totalRows: result.totalRows ?? 0,
                  id: response?.data?.data?.logId,
                })
                break
              }
            } catch (error) {}
          }
          reject()
        })
        if (!hasFile) {
          setProgress(0)
          return
        }

        setProgress(100)
        setUploadFile({ ...uploadFile, status: 'success' as any })

        setTimeout(() => {
          handleOpenCompleteModal({
            errorRows: completed.totalErrorsRows,
            totalRows: completed.totalRows,
            fileName: uploadFile.originFileObj?.name ?? '',
            data: completed?.data?.data,
            id: completed?.id,
          })
          cancel(true)
          getData && getData()
        }, 500)
      })
      .catch(() => {
        setUploadFile({ ...uploadFile, status: 'error' })
        setProgress(100)
      })
  }

  return (
    <div>
      <SappModal
        open={open}
        handleClose={handleCancel}
        title={title}
        handleCancel={handleCancel}
        handleSubmit={handleUploadFile}
        loading={progress > 0 && progress < 100}
        cancelButtonCaption='Cancel'
        okButtonCaption='Import'
        dialogClassName='mw-650px modal-dialog-centered'
        disableInnerScroll={true}
        confirmOnclose
      >
        <Dragger
          {...(uploadFile &&
            Math.floor(progress) > 0 &&
            Math.floor(progress) < 100 && { defaultFileList: [uploadFile] })}
          disabled={Math.floor(progress) > 0 && Math.floor(progress) < 100}
          beforeUpload={(rcFile: RcFile, FileList: RcFile[]) => {
            setProgress(0)
            let check = beforeUpload === undefined || beforeUpload(rcFile, FileList)

            const uploadFile: UploadFile = {
              ...rcFile,
              originFileObj: rcFile,
              status: 'uploading',
            }

            if (check) {
              setUploadFile(uploadFile)
            } else {
              return Upload.LIST_IGNORE
            }
            return false
          }}
          itemRender={itemRender}
          multiple={false}
          maxCount={1}
          accept={accept}
        >
          <div className='scroll-y px-10 px-lg-15 pt-10 pb-15'>
            <div>
              <p className=''>
                <img width={64} height={64} src={icon} alt='Icon' />
              </p>
              <p className=''>Drag & Drop your file here</p>
              <p className=''>or</p>
              <ButtonPrimary title='Browse' className='mb-4' size='small'></ButtonPrimary>
              <p className=''>File support: {fileSupport}</p>
            </div>
          </div>
        </Dragger>
        <div className='mt-6'>
          <p className='mb-0'>
            Tải file mẫu:{' '}
            <a href={templateFile} download>
              {templateFileName}
            </a>
          </p>
        </div>
      </SappModal>
      <ModalImportComplete
        type={type}
        completeModal={completeModal}
        handleClose={handleCloseCompleteModal}
      />
    </div>
  )
}
export default ModalImport
