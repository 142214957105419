import {fetchFormPutData, fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {IAuthor, IAuthorList, IAuthorUpdate} from 'src/type/shop/author'
import {IProductCategoryCreate} from 'src/type/shop/product'

export class AuthorAPI {
  static getAuthors({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IAuthorList>> {
    return fetcher(`/shop-blogs-authors?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static getAuthor(id: string): Promise<IResponse<IAuthor>> {
    return fetcher(`/shop-blogs-authors/${id}`)
  }

  static uploadAvatar = (data: {blog_author_id: string; file: File}) => {
    const formData = new FormData()
    formData.append('blog_author_id', data.blog_author_id)
    formData.append('file', data.file)
    return fetchFormPutData({url: `/shop-blogs-authors/avatar`, formData})
  }

  static createAuthor({data}: {data: IProductCategoryCreate}): Promise<IResponse<IAuthor>> {
    return fetcher('/shop-blogs-authors', {method: 'POST', data: data})
  }

  static editAuthor({id, data}: {id: string; data: IAuthorUpdate}): Promise<IResponse<IAuthor>> {
    return fetcher(`/shop-blogs-authors/${id}`, {method: 'PUT', data: data})
  }

  static deleteAuthor(id: string): Promise<IResponse<IAuthor>> {
    return fetcher(`/shop-blogs-authors/${id}`, {method: 'DELETE'})
  }

  static deleteBulkAuthor(shopBlogAuthorIds: string[]): Promise<IResponse<IAuthor>> {
    return fetcher('/shop-blogs-authors/bulk-delete', {
      method: 'POST',
      data: {shopBlogAuthorIds},
    })
  }
}
