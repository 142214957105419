import { format } from 'date-fns'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { FORMAT_DATETIME, MY_PROFILE, TEACHER_PROFILE } from 'src/constants'
import { programOrder, sectionOrder, subjectOrder } from 'src/constants/classes'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { TEACHING_STATUS } from 'src/constants/teacher'
import { useUserContext } from 'src/context/UserProvider'
import { processFields } from 'src/helper/joinFields'
import { PROGRAM, Role } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { TeachableInstance } from 'src/type/teacher'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const TeacherProfileDetail = ({ teacherDetail, loading, setTeacherDetail }: IProps) => {
  const { id } = useParams()
  const { getStudentDetail } = useUserContext()

  const getBadgeColor = (item: any) => {
    switch (item.toUpperCase()) {
      case PROGRAM.CFA:
        return 'green'
      case PROGRAM.ACCA:
        return 'danger'
      case PROGRAM.CMA:
        return 'success'

      default:
        return 'danger'
    }
  }
  const getPrefix = (item: string) =>
    item.startsWith('CFA-') ? 'CFA' : item.startsWith('CMA-') ? 'CMA' : 'ACCA'

  useEffect(() => {
    if (!id || loading) return

    getStudentDetail(id).then((res) => setTeacherDetail(res?.data))
  }, [id])

  const userContact = teacherDetail?.user_contacts?.find((user) => user.is_default)
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const teachableInstances = teacherDetail
    ? (teacherDetail?.teacher_teachable_instances_mapping as [])
    : []
  const userStaffInstances = teacherDetail ? (teacherDetail?.user_staff_instances as []) : []
  const program = processFields(teachableInstances, 'category', 'name')
  const subject = processFields(teachableInstances, 'subject', 'code')
  const groupedSubjects = program.map((category) =>
    subject.filter((item) => getPrefix(item) === category).filter((group) => group.length > 0)
  )

  const section: {
    code: string
    program: string
  }[] = _.uniq(
    teachableInstances?.flatMap((item: TeachableInstance) =>
      item?.feature_section_instances?.map((instance) => {
        return {
          code: instance?.code,
          program: item.category.name,
        }
      })
    )
  )
  const groupedSections = programOrder.map((program) =>
    section.filter((item) => item.program === program)
  )

  const personInCharge = processFields(userStaffInstances, 'staff', 'username')

  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Profile Details'
            titleButton={allowRender ? 'Edit Profile' : ''}
            link={`${TEACHER_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
          />

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={teacherDetail?.key} />
            <ItemProfile
              title={LANG_PLACEHOLDER.FULLNAME}
              body={teacherDetail?.detail?.full_name}
            />
            <ItemProfile title='Username' body={teacherDetail?.username} />
            <ItemProfile title='Email' body={teacherDetail?.detail?.email ?? userContact?.email} />
            <ItemProfile
              title='Phone Number'
              body={teacherDetail?.detail?.phone ?? userContact?.phone}
            />
            <ItemProfile title='Status' status={teacherDetail?.status} />
            <ItemProfile
              title='Teaching Status'
              body={
                TEACHING_STATUS[teacherDetail?.teacher_status as keyof typeof TEACHING_STATUS] ||
                '-'
              }
            />
            <ItemProfile
              title='Program'
              body={
                program.length > 0
                  ? program
                      .sort((a, b) => programOrder.indexOf(a) - programOrder.indexOf(b))
                      .map((item) => (
                        <span
                          className={`badge badge-light-${getBadgeColor(item)} fs-7 my-1 me-1`}
                          key={item}
                        >
                          {item}
                        </span>
                      ))
                  : '-'
              }
            />
            <ItemProfile
              title='Subject'
              body={
                subject.length > 0
                  ? groupedSubjects.map(
                      (group, index) =>
                        group.sort((a, b) => subjectOrder.indexOf(a) - subjectOrder.indexOf(b))
                          .length > 0 && (
                          <div key={programOrder[index]}>
                            {group.map((item) => (
                              <span
                                key={item}
                                className={`badge badge-light-${getBadgeColor(
                                  item.substring(0, 3)
                                )} fs-7 my-1 me-1`}
                              >
                                {item}
                              </span>
                            ))}
                          </div>
                        )
                    )
                  : '-'
              }
            />
            <ItemProfile
              title='Section'
              body={
                section.length > 0
                  ? groupedSections.map(
                      (group, index) =>
                        group.length > 0 && (
                          <div key={programOrder[index]} className=''>
                            {group
                              .sort(
                                (a, b) =>
                                  sectionOrder.indexOf(a.code) - sectionOrder.indexOf(b.code)
                              )

                              .map((item, index) => (
                                <span
                                  key={index}
                                  className={`badge badge-light-${getBadgeColor(
                                    item.program
                                  )} fs-7 my-1 me-1`}
                                >
                                  {item.code}
                                </span>
                              ))}
                          </div>
                        )
                    )
                  : '-'
              }
            />
            <ItemProfile
              title='Person in charge'
              body={
                personInCharge.length > 0
                  ? personInCharge.map((item) => (
                      <span className={`badge badge-light fs-7 my-1 me-1`} key={item}>
                        {item}
                      </span>
                    ))
                  : '-'
              }
            />
            <ItemProfile
              title='Gender'
              body={
                teacherDetail?.detail?.sex === 'MALE'
                  ? 'Male'
                  : teacherDetail?.detail?.sex === 'FEMALE'
                  ? 'Female'
                  : teacherDetail?.detail?.sex === 'OTHERS'
                  ? 'Others'
                  : '-'
              }
            />
            <ItemProfile title='Job Title' body={teacherDetail?.detail?.job_title} />
            <ItemProfile
              title='Facebook Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.facebook ?? '#'}
                >
                  {teacherDetail?.detail?.facebook || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='Youtube Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.youtube ?? '#'}
                >
                  {teacherDetail?.detail?.youtube || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='LinkedIn Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.linkedin ?? '#'}
                >
                  {teacherDetail?.detail?.linkedin || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='Describe'
              body={
                <div dangerouslySetInnerHTML={{ __html: teacherDetail?.detail?.describe ?? '' }} />
              }
            />
            <ItemProfile
              title='Updated At'
              body={
                teacherDetail?.updated_at
                  ? format(new Date(teacherDetail?.updated_at), FORMAT_DATETIME)
                  : ''
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default TeacherProfileDetail
