import React, { useState } from 'react'
import { ControllerRenderProps, FieldError } from 'react-hook-form'
import './Hookformtextfield.scss'
interface IProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  type?: 'number' | 'password' | 'email' | 'text'
  placeholder?: string
  className?: string
  value?: any
  defaultValue?: string
  disabled?: boolean
  label?: string
  labelClass?: string
  tooltipIcon?: string | undefined
  onChangeType?: () => void
  passwordVisible?: boolean
  allowShowPassword?: boolean
  error?: FieldError
  required?: boolean
  maxLength?: number
  groupText?: string
  guideline?: string[]
  field?: ControllerRenderProps<any, string>
  postFix?: any
  id?: string
  onPaste?: any
  preIcon?: any
  minNumber?: number
  maxNumber?: number
  requiredZero?: boolean
  isListScreen?: boolean
  autofocus?: boolean
  onFocus?: React.FocusEventHandler
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  otp?: { active: boolean; index: number }
}

const SAPPTextField = ({
  type,
  value,
  defaultValue,
  onChange,
  className,
  placeholder,
  disabled,
  allowShowPassword = type === 'password' && true,
  error,
  maxLength,
  groupText,
  field,
  postFix,
  id,
  onPaste,
  preIcon,
  minNumber = 0,
  maxNumber,
  requiredZero = false,
  isListScreen = false,
  autofocus = false,
  onFocus,
  onKeyDown,
  onInput,
}: IProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const toggleChangeType = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <div className={`${className ?? ''} position-relative w-100`}>
      <div className={`${groupText || postFix ? 'input-group flex-nowrap' : ''}`}>
        {groupText && (
          <div className='input-group-text sapp-input-group-text  justify-content-center'>
            {groupText}
          </div>
        )}
        {preIcon}
        <input
          {...field}
          autoFocus={autofocus}
          type={allowShowPassword && passwordVisible ? 'text' : type}
          value={value ?? ''}
          defaultValue={value ? defaultValue : undefined}
          onChange={onChange}
          className={`${className ?? ''} form-control bg-transparent ${
            isListScreen ? 'sapp-h-40' : 'sapp-h-45px'
          } ${groupText ? 'rounded-end input-group-box-postfix' : ''} ${preIcon ? 'ps-13' : ''} ${
            postFix ? `input-group-text-input-postfix` : ``
          }`}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          id={id}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (requiredZero && type === 'number') {
              if (parseInt(e.currentTarget.value, 10) === 0) {
                e.currentTarget.value = ''
              } else {
                e.currentTarget.value = e.currentTarget.value.replace(/[-eE]/g, '')
              }
            }
            if (onInput) {
              onInput(e)
            }
          }}
          onKeyDown={(e: any) => {
            // Lọc các ký tự "e", "E" hoặc số ấm trong number
            if ((e.keyCode === 69 || e.keyCode === 189) && type === 'number') {
              e.preventDefault()
              e.currentTarget.value = e.currentTarget.value.replace(/[-eE]/g, '')
            }
            if (onKeyDown) {
              onKeyDown(e)
            }
          }}
          min={`${minNumber}`}
          max={`${maxNumber}`}
          onPaste={onPaste}
          onFocus={onFocus}
        />
        {postFix && (
          <span
            className={`input-group-text sapp-input-group-text ps-1 pe-1 justify-content-center input-group-box-postfix ${
              isListScreen ? 'sapp-h-40' : 'sapp-h-45px'
            }`}
            id='basic-addon2'
          >
            {postFix}
          </span>
        )}
      </div>
      {allowShowPassword && (
        <span
          className={`btn__password-eye btn-icon ${error && 'd-none'}`}
          onClick={toggleChangeType}
        >
          {passwordVisible ? (
            <i className={`ki-duotone ki-eye-slash fs-1 ${passwordVisible ? 'visible' : ''}`}>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
              <span className='path4'></span>
            </i>
          ) : (
            <i className={`ki-duotone ki-eye fs-1 ${passwordVisible ? 'visible' : ''}`}>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
          )}
        </span>
      )}
    </div>
  )
}

export default SAPPTextField
