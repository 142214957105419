import { UseFormReturn, UseFormTrigger } from 'react-hook-form'
import image_default from 'src/_metronic/assets/images/shop/image_default.png'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { ACCEPT_UPLOAD_MIME_TYPE, DEFAULT_MAX_FILE_SIZE } from 'src/constants'
import { IStepProgress } from 'src/type'
import { IBlog, IBlogUpdate } from 'src/type/shop/blog'
import { IStep } from '../faqs/FaqDetail'

interface IProps extends IStepProgress {
  useFormProp: UseFormReturn<IBlogUpdate>
  blog?: IBlog
  onCancel: () => void
  trigger: UseFormTrigger<IBlogUpdate>
}

const CreateAndUpdatePost = ({ useFormProp, onCancel, setStep, step, trigger }: IProps) => {
  const { control, setValue, setError, getValues } = useFormProp

  const handleParseMedia = () => {
    if (getValues('thumbnail') && typeof getValues('thumbnail') !== 'string') {
      return URL.createObjectURL(getValues('thumbnail'))
    }
    return getValues('thumbnail')
  }

  const handleNextStep = async () => {
    const isValid = await trigger(['title', 'content', 'thumbnail'])
    if (!isValid) return

    setStep((prev: IStep[]) => {
      const indexItem = prev.findIndex((item) => item.status === 'current') + 1
      return prev.map((step: IStep, index: number) => ({
        ...step,
        status:
          index < indexItem
            ? 'active'
            : index === indexItem && index <= prev.length - 1
            ? 'current'
            : '',
      }))
    })
  }

  const handleToggle = async (): Promise<boolean> => {
    const isValid = await trigger(['title', 'content', 'thumbnail'])
    return isValid
  }

  return (
    <div className='form d-flex flex-column flex-lg-row'>
      <div className='d-flex justify-content-center'>
        <Processbar
          step={step}
          setNewStep={setStep}
          cancelButtonCaption='Cancel'
          showCancel
          onCancel={onCancel}
          onClick={handleNextStep}
          okButtonCaption='Next'
          className='mw-500px'
          canToggle={handleToggle}
        />
      </div>
      <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-8'>
        <div className='card card-flush'>
          <div className='card-header pt-8 align-items-start'>
            <SappLabel label='Blog Thumbnail' isLabel />
          </div>

          <div className='card-body text-center pt-0 sapp-card--body pb-10'>
            <SAPPHookUploadFile
              name='thumbnail'
              control={control}
              setValue={setValue}
              setError={setError}
              imagePreview={handleParseMedia() ?? image_default}
              accept={ACCEPT_UPLOAD_MIME_TYPE}
              maxFileSize={DEFAULT_MAX_FILE_SIZE}
              removeAvatar={image_default}
              guideline={[
                'Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted',
              ]}
              className='sapp-w-200px'
            />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-row-fluid'>
        <div className='tab-content'>
          <div className='tab-pane fade show active'>
            <div className='d-flex flex-column gap-7 gap-lg-10'>
              <div className='card card-flush pb-4 pt-10'>
                <div className='card-body pt-0'>
                  <h2 className='mb-10'>General</h2>
                  <div className='mb-10 fv-row'>
                    <HookFormTextField control={control} name='title' label='Title' required />
                  </div>
                  <div className='mb-10 fv-row'>
                    <HookFormEditor
                      height={600}
                      label='Content'
                      name='content'
                      control={control}
                      math={true}
                      className='w-100'
                      defaultValue={getValues('content')}
                      resourceLocation={RESOURCE_LOCATION.MEDIA}
                      object_id={undefined}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAndUpdatePost
