import React, { Dispatch, SetStateAction, useEffect } from 'react'
import SappDrawer from 'src/components/base/SappDrawer'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { ACCEPT_UPLOAD_MIME_TYPE, DEFAULT_MAX_FILE_SIZE, VALIDATION_FIELD } from 'src/constants'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import MenuTabsSection from 'src/pages/shop/blogs/MenuTabs'
import Advanced from 'src/pages/shop/blogs/Advanced'
import thumnail_courseinfo from 'src/_metronic/assets/images/course/thumbnail_course.png'
import SAPPHookUploadFile from '../../base/file/SAPPHookUploadFile'
import SappLabel from '../../base/label/SappLabel'
import toast from 'react-hot-toast'
import { IAuthor } from 'src/type/shop/author'
import { INewOrUpdatePopup, RobotMeta } from 'src/type/shop/common'
import { z } from 'zod'
import { metaValidationSchema } from 'src/utils/shop/validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { AuthorAPI } from 'src/apis/short-course/author'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import '../shop.scss'

interface IProps {
  open: boolean
  handleClose: () => void
  reloadPage: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  setOpenNewCategory: Dispatch<SetStateAction<INewOrUpdatePopup>>
  type?: 'create' | 'edit'
  author?: IAuthor
}

type ISubmittedData = {
  name: string
  avatar: any
  facebook_url: string
  youtube_url: string
  linkedin_url: string
  describe?: string
  meta_title?: string
  meta_description?: string
  focus_keyword?: string
  robot_meta?: RobotMeta
  canonical_url?: string
}

const CreateAndUpdateAuthor = ({
  open,
  reloadPage,
  handleClose,
  setLoading,
  setOpenNewCategory,
  type = 'create',
  author,
}: IProps) => {
  const initialValue = {
    name: '',
    describe: '',
    facebook_url: '',
    youtube_url: '',
    linkedin_url: '',
    focus_keyword: '',
    meta_title: '',
    meta_description: '',
    canonical_url: '',
  }
  /**
   * Validation cho các trường trong HookForm
   */
  const validationSchema = z.object({
    name: z.string().min(1, VALIDATION_FIELD),
    avatar: z.any(),
    facebook_url: z.string().nullable().optional(),
    youtube_url: z.string().nullable().optional(),
    linkedin_url: z.string().nullable().optional(),
    describe: z.string().nullable().optional(),
    meta_title: z.string().min(1, VALIDATION_FIELD),
    meta_description: z.string().min(1, VALIDATION_FIELD),
    focus_keyword: z.string().nullable().optional(),
    robot_meta: metaValidationSchema,
    canonical_url: z.string().nullable().optional(),
  })

  /**
   * Hàm dùng dùng khi khởi tạo form mới hoặc cập nhật lại data của form
   */
  const handleClearData = () => {
    if (type === 'edit' && author) {
      const robotMeta = author?.robot_meta ? JSON.parse(author?.robot_meta) : ''
      setValue('name', author?.name ?? '')
      setValue('avatar', author?.avatar?.ORIGIN)
      setValue('describe', author?.describe ?? '')
      setValue('facebook_url', author?.facebook_url ?? '')
      setValue('youtube_url', author?.youtube_url ?? '')
      setValue('linkedin_url', author?.linkedin_url ?? '')
      setValue('meta_title', author?.meta_title ?? '')
      setValue('meta_description', author?.meta_description ?? '')
      setValue('focus_keyword', author?.focus_keyword ?? '')
      setValue('canonical_url', author?.canonical_url ?? '')
      Object.keys(robotMeta).forEach((key: string) => {
        setValue(`robot_meta.${key as keyof RobotMeta}`, robotMeta[key])
      })
    } else {
      reset()
    }
  }

  /**
   * Handle khởi tạo Form khi mở popup
   * @param{author} - data của author nếu có
   * @param{open} - Để hanle việc clear Form khi form không có category data
   */
  useEffect(() => {
    handleClearData()
  }, [author, open])

  const useFormProp = useForm<ISubmittedData>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValue,
  })
  const { control, handleSubmit, reset, setValue, setError, getValues } = useFormProp

  /**
   * Handle submit action of form - xử lý tạo mới hoặc cập nhật
   * @param {ISubmittedData} data  - Data của form
   */
  const onSubmit = async (data: ISubmittedData) => {
    try {
      setLoading(true)
      if (type === 'edit' && author?.id) {
        typeof data.avatar === 'string' && delete data.avatar
        await AuthorAPI.editAuthor({ id: author.id, data })
        if (data?.avatar && typeof data?.avatar !== 'string') {
          await AuthorAPI.uploadAvatar({ blog_author_id: author.id, file: data.avatar })
        }
        toast.success('Updated an author successfully!')
      } else {
        const res = await AuthorAPI.createAuthor({ data })
        if (res.success && typeof data.avatar !== 'string') {
          await AuthorAPI.uploadAvatar({ blog_author_id: res.data.id, file: data.avatar })
        }
        toast.success('Created an author successfully!')
      }
      setOpenNewCategory({ isOpen: false, data: null, type: 'create' })
      reset()
      handleClearData()
      reloadPage()
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  return (
    <SappDrawer
      open={open}
      title='Create New Author'
      handleSubmit={handleSubmit(onSubmit)}
      width='50%'
      handleClose={handleClose}
      okButtonCaption='Save'
      rootClassName='create-shop-root'
      classNameHeader='create-shop-header'
    >
      <SappLabel label='Avatar' />
      <div className='mb-10'>
        <SAPPHookUploadFile
          name='avatar'
          control={control}
          setValue={setValue}
          setError={setError}
          imagePreview={author?.avatar?.ORIGIN ?? thumnail_courseinfo}
          accept={ACCEPT_UPLOAD_MIME_TYPE}
          maxFileSize={DEFAULT_MAX_FILE_SIZE}
          removeAvatar={thumnail_courseinfo}
          className='ms-1'
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='name' label='Name' required />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Describe'
          control={control}
          name='describe'
          math={true}
          height={500}
          defaultValue={getValues('describe')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='facebook_url' label='Facebook' />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='linkedin_url' label='LinkedIn' />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='youtube_url' label='Youtube' />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='meta_title' label='Meta Title' required />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Meta Description'
          control={control}
          name='meta_description'
          math={true}
          height={500}
          defaultValue={getValues('meta_description')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
          required
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField control={control} name='focus_keyword' label='Focus Keyword' />
      </div>
      <MenuTabsSection
        typeofSection={'Advanced'}
        SectionName={'Advanced'}
        tabs={[
          {
            id: 'tab1',
            title: 'Advanced',
            content: <Advanced useForm={useFormProp} />,
          },
        ]}
      />
    </SappDrawer>
  )
}

export default CreateAndUpdateAuthor
