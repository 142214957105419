import { Dropdown } from 'react-bootstrap'
import { UseFormReturn, UseFormTrigger } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import image_default from 'src/_metronic/assets/images/shop/image_default.png'
import video_default from 'src/_metronic/assets/images/shop/video_default.png'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import FAQsContent from 'src/components/shop/events/FAQsContent'
import GeneralContent from 'src/components/shop/events/GeneralContent'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  ACCEPT_UPLOAD_VIDEO_MINE_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  PageLink,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IStepProgress } from 'src/type'
import { IProductForm } from 'src/type/shop/product'
import { hanleTransferMedia } from 'src/utils/shop/validation'
import ProductContent from './ProductContent'
import ProductPricing from './ProductPricing'
import ProductRelated from './ProductRelated'

interface IProp extends IStepProgress {
  useFormProp: UseFormReturn<any>
  onNextStep: () => void
  trigger: UseFormTrigger<IProductForm>
}

const CreateAndUpdateContent = ({ useFormProp, onNextStep, step, setStep, trigger }: IProp) => {
  const { confirm, contextHolder } = useConfirm()
  const { control, setValue, setError, getValues } = useFormProp

  const navigate = useNavigate()
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(PageLink.PRODUCTS)
      },
    })
  }

  const handleToggle = async (): Promise<boolean> => {
    const isValid = await trigger([
      'title',
      'main_class_id',
      'trial_class_id',
      'origin_price',
      'thumbnail',
      'video_trailer',
    ])
    return isValid
  }

  return (
    <>
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <Processbar
          step={step}
          setNewStep={setStep}
          showCancel
          showButtonPrimary={false}
          onClick={onNextStep}
          onCancel={handleCancel}
          okButtonCaption='Next'
          canToggle={handleToggle}
        >
          <Dropdown.Item>Preview</Dropdown.Item>
          <Dropdown.Item onClick={onNextStep}>Next</Dropdown.Item>
        </Processbar>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-8'>
          <div className='card card-flush sapp-pt--image'>
            <div className='card-header pt-8 align-items-start'>
              <SappLabel label='Product Thumbnail' isLabel />
            </div>

            <div className='card-body text-center px-16 pb-10'>
              <SAPPHookUploadFile
                name='thumbnail'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={hanleTransferMedia(getValues('thumbnail')) ?? image_default}
                accept={ACCEPT_UPLOAD_MIME_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                removeAvatar={image_default}
                guideline={[
                  'Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted',
                ]}
                className='sapp-w-200px'
                required
              />
            </div>
          </div>

          <div className='card card-flush sapp-pt--image'>
            <div className='card-header pt-8 align-items-start'>
              <SappLabel label='Video Trailer' isLabel />
            </div>

            <div className='card-body text-center px-16 pb-10'>
              <SAPPHookUploadFile
                name='video_trailer'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={hanleTransferMedia(getValues('video_trailer'))}
                accept={ACCEPT_UPLOAD_VIDEO_MINE_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                removeAvatar={''}
                guideline={['Only *.mp4, *.mav and *.vid video files are accepted']}
                className='sapp-w-200px'
                poster={hanleTransferMedia(getValues('video_trailer')) ? undefined : video_default}
                required
              />
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-5'>
          <div className='tab-content'>
            <div className='tab-pane fade show active'>
              <div className='d-flex flex-column gap-7 gap-lg-10 sapp-mw-950px'>
                <GeneralContent useFormProp={useFormProp} />
                <ProductContent useFormProp={useFormProp} />
                <ProductRelated confirm={confirm} useFormProp={useFormProp} />
                <ProductPricing control={control} />
                <FAQsContent confirm={confirm} useFormProp={useFormProp} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateAndUpdateContent
