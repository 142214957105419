import { useLocation, useParams } from 'react-router-dom'
import { PageLink } from 'src/constants'
import { LANG_COURSES, LANG_SIDEBAR } from 'src/constants/lang'
import MenuLeft from './MenuLeft'
import MenuRight from './MenuRight'
import { TITLE_OPTIONS_CLASS } from 'src/constants/classes'
import { useUserContext } from 'src/context/UserProvider'
import {
  CODE_ADMIN,
  ID_SIDE_BAR,
  TITLE_CERTIFICATE_GR,
  TITLE_CLASS_GR,
  TITLE_COURSE_GR,
  TITLE_EXAM_GR,
  TITLE_GR,
  TITLE_GROUPS_GR,
  TITLE_LOGS_GR,
  TITLE_MARKETING_GR,
  TITLE_NOTIFICATIONS_GR,
  TITLE_QUESTIONS_GR,
  TITLE_QUIZ_GR,
  TITLE_RESOURCES_GR,
  TITLE_SECTION_QUIZ_GR,
  TITLE_STAFF_GR,
  TITLE_STORY_GR,
  TITLE_TOPIC_GR,
} from 'src/constants/permission'
import { PROGRAM, Role } from 'src/type'
import { getExamListLinks } from 'src/constants/exam'

interface PermissionCheck {
  [permissionTitle: string]: boolean
}
// Exams
// const examMenus = [
//   ...Object.values(PROGRAM).map((program) => `${PageLink.EXAMS}/${program.toLowerCase()}`),
//   // '/import-history/exam',
// ]
const Sidebar = () => {
  const location = useLocation()
  const { id } = useParams()
  const { profileMe } = useUserContext()

  // Exams
  const examMenus =
    [
      ...Object.values(PROGRAM).map((program) => `${PageLink.EXAMS}/${program}`),
      '/import-history/exam',
    ].includes(location.pathname) ||
    location.pathname.split('/exam/')[0] === '/import-history/detail'

  const examChildrens = [
    ...getExamListLinks(location),
    {
      id: 'exam-import-log',
      name: 'Report Exam',
      link: PageLink.IMPORT_LOG + '/exam',
      active:
        location.pathname === '/import-history/exam' ||
        location.pathname.split('/exam/')[0] === '/import-history/detail',
    },
  ]

  const menuLMS = [
    {
      id: ID_SIDE_BAR.DASHBOARD,
      name: LANG_SIDEBAR.dashboard,
      link: PageLink.DASHBOARD,
      active: location.pathname === PageLink.DASHBOARD,
      icon: 'ki-outline ki-home-2',
    },
    {
      id: ID_SIDE_BAR.CLASSES,
      name: 'Classes',
      icon: 'ki-outline ki-book-open',
      showMenu:
        [
          PageLink.CLASSES,
          PageLink.CREATE_CLASSES,
          '/import-history/CLASS',
          '/logs/CLASS_STUDENT',
        ].includes(location.pathname) ||
        location.pathname.split('/CLASS_STUDENT/')[0] === '/logs/detail' ||
        location.pathname.split('/CLASS/')[0] === '/import-history/detail',
      children: [
        {
          id: ID_SIDE_BAR.LIST_CLASS,
          name: TITLE_OPTIONS_CLASS.listClasses,
          link: PageLink.CLASSES,
          active: location.pathname === PageLink.CLASSES,
        },
        {
          id: ID_SIDE_BAR.CREATE_CLASS,
          name: 'Create Class',
          link: PageLink.CREATE_CLASSES,
          active: location.pathname === PageLink.CREATE_CLASSES,
        },
        {
          id: ID_SIDE_BAR.REPORT_CLASS,
          name: 'Class Report',
          link: PageLink.IMPORT_LOG + '/CLASS',
          active:
            location.pathname === '/import-history/CLASS' ||
            location.pathname.split('/CLASS/')[0] === '/import-history/detail',
        },
        {
          id: ID_SIDE_BAR.REPORT_STUDENT,
          name: 'Student Report',
          link: '/logs/CLASS_STUDENT',
          active:
            location.pathname === '/logs/CLASS_STUDENT' ||
            location.pathname.split('/CLASS_STUDENT/')[0] === '/logs/detail',
        },
      ],
    },
    {
      id: ID_SIDE_BAR.COURSES,
      name: 'Courses',
      icon: 'ki-outline ki-book',
      showMenu: [PageLink.COURSE_CATEGORY, PageLink.COURSES, PageLink.COURSE_SUBJECT].includes(
        location.pathname
      ),
      children: [
        {
          id: ID_SIDE_BAR.COURSES_LIST,
          name: LANG_SIDEBAR.courseList,
          link: PageLink.COURSES,
          active: location.pathname === PageLink.COURSES,
        },
        {
          id: ID_SIDE_BAR.COURSE_CATEGORY,
          name: 'Course Category',
          link: PageLink.COURSE_CATEGORY,
          active: location.pathname === PageLink.COURSE_CATEGORY,
        },
        {
          id: ID_SIDE_BAR.COURSE_SUBJECT,
          name: 'Subject List',
          link: PageLink.COURSE_SUBJECT,
          active: location.pathname === PageLink.COURSE_SUBJECT,
        },
      ],
    },
    {
      id: ID_SIDE_BAR.CLASSROOM,
      name: 'Class Room',
      icon: 'ki-outline ki-book',
      showMenu:
        [PageLink.CLASSROOM_AREA, PageLink.CLASSROOM_ROOM].includes(location.pathname) ||
        location.pathname.includes(PageLink.CLASSROOM_AREA_DETAIL) ||
        location.pathname.includes(PageLink.CLASSROOM_ROOM_DETAIL),
      children: [
        {
          id: ID_SIDE_BAR.COURSES_LIST,
          name: LANG_SIDEBAR.facilityList,
          link: PageLink.CLASSROOM_AREA,
          active:
            location.pathname === PageLink.CLASSROOM_AREA ||
            location.pathname.includes(PageLink.CLASSROOM_AREA_DETAIL),
        },
        {
          id: ID_SIDE_BAR.COURSES_LIST,
          name: LANG_SIDEBAR.roomList,
          link: PageLink.CLASSROOM_ROOM,
          active:
            location.pathname === PageLink.CLASSROOM_ROOM ||
            location.pathname.includes(PageLink.CLASSROOM_ROOM_DETAIL),
        },
      ],
    },
    {
      id: 'exams',
      name: 'Exams',
      icon: 'ki-outline ki-note-2',
      showMenu: examMenus,
      children: examChildrens,
    },
    // {
    //   id: 6,
    //   name: 'Resource Bank',
    //   link: PageLink.RESOURCE_BANK,
    //   active: location.pathname === PageLink.RESOURCE_BANK,
    //   icon: 'ki-outline ki-briefcase',
    // },
    {
      id: ID_SIDE_BAR.QUESTION_BANK,
      name: LANG_SIDEBAR.questionBank,
      icon: 'ki-outline ki-question-2 fs-2',
      showMenu:
        [
          PageLink.STORY,
          PageLink.TOPICS,
          PageLink.TOPIC,
          PageLink.CREATE_STORY,
          PageLink.QUESTION_LIST,
        ].includes(location.pathname) ||
        location.pathname.split('/case-sudy')[0] === '/case-study' ||
        location.pathname.split(`/${id}` && '')[0] === '/case-study' ||
        location.pathname.includes(PageLink.TOPIC),
      children: [
        {
          id: ID_SIDE_BAR.TOPIC_LIST,
          name: LANG_SIDEBAR.topicList,
          link: PageLink.TOPICS,
          active:
            location.pathname === PageLink.TOPICS ||
            location.pathname === PageLink.TOPIC ||
            location.pathname.includes(PageLink.TOPIC),
        },
        {
          id: ID_SIDE_BAR.QUESTION_LIST,
          name: LANG_SIDEBAR.questionList,
          link: PageLink.QUESTION_LIST,
          active:
            location.pathname === PageLink.QUESTION_LIST ||
            location.pathname === PageLink.QUESTION_LIST,
        },
        {
          id: ID_SIDE_BAR.CASE_STUDY,
          name: 'Case Study',
          link: PageLink.STORY,
          active:
            location.pathname === PageLink.STORY ||
            location.pathname === PageLink.CREATE_STORY ||
            location.pathname.split('/case-sudy')[0] === '' ||
            location.pathname.split(`/${id}`)[0] === PageLink.STORY,
        },
      ],
    },
    {
      id: ID_SIDE_BAR.ENTRANCE_TEST,
      name: 'Entrance Tests',
      icon: 'ki-outline ki-exit-right fs-2',
      showMenu: [
        PageLink.ENTRANCE_TEST_LIST,
        PageLink.PARTICIPANTS_LIST,
        PageLink.PARTICIPANT_LOG_FILE,
      ].includes(location.pathname),
      children: [
        {
          id: ID_SIDE_BAR.ENTRANCE_TEST_LIST,
          name: 'Entrance Tests',
          link: PageLink.ENTRANCE_TEST_LIST,
          active: location.pathname === PageLink.ENTRANCE_TEST_LIST,
        },
        {
          id: ID_SIDE_BAR.PARTICIPANT_LIST,
          name: 'Participants',
          link: PageLink.PARTICIPANTS_LIST,
          active: location.pathname === PageLink.PARTICIPANTS_LIST,
        },
        {
          id: ID_SIDE_BAR.REPORT_PARTICIPANT,
          name: 'Participant Report',
          link: '/logs/TEST_PARTICIPANT',
          active:
            location.pathname === '/logs/TEST_PARTICIPANT' ||
            location.pathname.split('/TEST_PARTICIPANT/')[0] === '/logs/detail',
        },
      ],
    },
    {
      id: ID_SIDE_BAR.EVENT_TEST,
      name: 'Event Test',
      icon: 'ki-outline ki-calendar-8 fs-2',
      showMenu: [
        PageLink.EVENT_TEST,
        PageLink.CREATE_EVENT_TEST,
        PageLink.EVENT_PARTICIPANT_LIST,
      ].includes(location.pathname),
      children: [
        {
          id: ID_SIDE_BAR.EVENT_TEST_LIST,
          name: LANG_SIDEBAR.eventTest,
          link: PageLink.EVENT_TEST,
          active: location.pathname === PageLink.EVENT_TEST,
        },
        {
          id: ID_SIDE_BAR.CREATE_EVENT_TEST,
          name: LANG_SIDEBAR.createEvent,
          link: `${PageLink.CREATE_EVENT_TEST}/undefined`,
          active: location.pathname === PageLink.CREATE_EVENT_TEST,
        },
        {
          id: ID_SIDE_BAR.ALL_PARTICIPANT,
          name: LANG_SIDEBAR.allParticipant,
          link: PageLink.EVENT_PARTICIPANT_LIST,
          active: location.pathname === PageLink.EVENT_PARTICIPANT_LIST,
        },
      ],
    },
    {
      id: ID_SIDE_BAR.RESOURCE_MANAGER,
      name: 'Resource Manager',
      icon: 'ki-outline ki-folder',
      showMenu: [PageLink.RESOURCE_MANAGER, PageLink.RESOURCE_MANAGER].includes(location.pathname),
      active: location.pathname === PageLink.RESOURCE_MANAGER,
      link: PageLink.RESOURCE_MANAGER,
      //  children: [
      //    {
      //      id: 36,
      //      name: 'Folder',
      //      link: PageLink.RESOURCE_MANAGER,
      //      active: location.pathname === PageLink.RESOURCE_MANAGER,
      //    },
      //  ],
    },
    {
      id: ID_SIDE_BAR.NOTIFICATIONS,
      name: 'Notifications',
      icon: 'ki-outline ki-notification',
      showMenu: [
        PageLink.SEND_NOTIFICATIONS,
        PageLink.NOTIFICATIONS,
        PageLink.LIST_GROUP,
        PageLink.MAIL_LOGS,
      ].includes(location.pathname),
      children: [
        {
          id: ID_SIDE_BAR.LIST_NOTIFICATIONS,
          name: LANG_SIDEBAR.listNotifications,
          link: PageLink.NOTIFICATIONS,
          active: location.pathname === PageLink.NOTIFICATIONS,
        },
        {
          id: ID_SIDE_BAR.CREATE_NOTIFICATION,
          name: 'Create Notification/Email',
          link: PageLink.SEND_NOTIFICATIONS,
          active: location.pathname === PageLink.SEND_NOTIFICATIONS,
        },
        {
          id: ID_SIDE_BAR.LIST_GROUP,
          name: LANG_SIDEBAR.listGroups,
          link: PageLink.LIST_GROUP,
          active: location.pathname === PageLink.LIST_GROUP,
        },
        {
          id: ID_SIDE_BAR.CREATE_GROUP,
          name: 'Create Group',
          link: PageLink.CREATE_GROUP,
          active: location.pathname === PageLink.CREATE_GROUP,
        },
        {
          id: ID_SIDE_BAR.MAIL_LOGS,
          name: 'Mail Logs',
          link: PageLink.MAIL_LOGS,
          active: location.pathname === PageLink.MAIL_LOGS,
        },
      ],
    },
    {
      id: ID_SIDE_BAR.SUPPORTER_GROUP,
      name: 'Comments',
      icon: 'ki-outline ki-document',
      showMenu: [PageLink.CREATE_SUPORTER_GROUP, PageLink.LIST_SUPPORTER_GROUP].includes(
        location.pathname
      ),
      children: [
        {
          id: ID_SIDE_BAR.LIST_SUPPORTER_GROUP,
          name: 'Supporter Groups',
          link: PageLink.LIST_SUPPORTER_GROUP,
          active: location.pathname === PageLink.LIST_SUPPORTER_GROUP,
        },
        {
          id: ID_SIDE_BAR.CREATE_SUPPORTER_GROUP,
          name: 'Create Supporter Group',
          link: PageLink.CREATE_SUPORTER_GROUP,
          active: location.pathname === PageLink.CREATE_SUPORTER_GROUP,
        },
      ],
    },
    {
      id: ID_SIDE_BAR.STAFFS,
      name: 'Staffs',
      icon: 'ki-outline ki-profile-circle',
      showMenu:
        [PageLink.STAFFS, PageLink.CREATE_STAFF].includes(location.pathname) ||
        [PageLink.IMPORT_LOG + '/STAFF', PageLink.IMPORT_LOG + '/detail/STAFF'].some((e) =>
          location.pathname.startsWith(e)
        ) ||
        location.pathname.split('/profile')[0] === '/staff' ||
        location.pathname.split(id ?? '')[0] === '/staffs/update/',
      children: [
        {
          id: ID_SIDE_BAR.LIST_STAFF,
          name: 'Staff List',
          link: PageLink.STAFFS,
          active:
            location.pathname === PageLink.STAFFS ||
            location.pathname.split('/profile')[0] === '/staff' ||
            location.pathname.split(id ?? '')[0] === '/staffs/update/',
        },
        {
          id: ID_SIDE_BAR.CREATE_STAFF,
          name: 'Create Staff',
          link: PageLink.CREATE_STAFF,
          active: location.pathname === PageLink.CREATE_STAFF,
        },
        {
          id: ID_SIDE_BAR.IMPORT_REPORT,
          name: 'Import Report',
          link: PageLink.IMPORT_LOG + '/STAFF',
          active: [PageLink.IMPORT_LOG + '/STAFF', PageLink.IMPORT_LOG + '/detail/STAFF'].some(
            (e) => location.pathname.startsWith(e)
          ),
        },
      ],
    },
    {
      id: ID_SIDE_BAR.STUDENT,
      name: 'Students',
      icon: 'ki-outline ki-teacher',
      showMenu:
        [PageLink.STUDENTS, PageLink.CREATE_STUDENTS].includes(location.pathname) ||
        [PageLink.IMPORT_LOG + '/STUDENT', PageLink.IMPORT_LOG + '/detail/STUDENT'].some((e) =>
          location.pathname.startsWith(e)
        ) ||
        location.pathname.split('/profile')[0] === '/student' ||
        location.pathname.split(id ?? '')[0] === '/students/update/',
      children: [
        {
          id: ID_SIDE_BAR.LIST_STUDENTS,
          name: 'Student List',
          link: PageLink.STUDENTS,
          active:
            location.pathname === PageLink.STUDENTS ||
            location.pathname.split('/profile')[0] === '/student' ||
            location.pathname.split(id ?? '')[0] === '/students/update/',
        },
        {
          id: ID_SIDE_BAR.CREATE_STUDENTS,
          name: 'Create Student',
          link: PageLink.CREATE_STUDENTS,
          active: location.pathname === PageLink.CREATE_STUDENTS,
        },
        {
          id: ID_SIDE_BAR.IMPORT_REPORTS_STUDENT,
          name: 'Import Report',
          link: PageLink.IMPORT_LOG + '/STUDENT',
          active: [PageLink.IMPORT_LOG + '/STUDENT', PageLink.IMPORT_LOG + '/detail/STUDENT'].some(
            (e) => location.pathname.startsWith(e)
          ),
        },
      ],
    },
    {
      id: ID_SIDE_BAR.TEACHERS,
      name: 'Teachers',
      icon: 'ki-outline ki-user',
      showMenu:
        [PageLink.TEACHERS, PageLink.CREATE_TEACHERS].includes(location.pathname) ||
        [PageLink.IMPORT_LOG + '/TEACHER', PageLink.IMPORT_LOG + '/detail/TEACHER'].some((e) =>
          location.pathname.startsWith(e)
        ) ||
        location.pathname.split('/profile')[0] === '/teacher' ||
        location.pathname.split(id ?? '')[0] === '/teachers/update/',
      children: [
        {
          id: ID_SIDE_BAR.LIST_TEACHERS,
          name: 'Teacher List',
          link: PageLink.TEACHERS,
          active:
            location.pathname === PageLink.TEACHERS ||
            location.pathname.split('/profile')[0] === '/teacher' ||
            location.pathname.split(id ?? '')[0] === '/teachers/update/',
        },
        {
          id: ID_SIDE_BAR.CREATE_TEACHERS,
          name: 'Create Teacher',
          link: PageLink.CREATE_TEACHERS,
          active: location.pathname === PageLink.CREATE_TEACHERS,
        },
        {
          id: ID_SIDE_BAR.IMPORT_REPORT_TEACHER,
          name: 'Import Report',
          link: PageLink.IMPORT_LOG + '/TEACHER',
          active: [PageLink.IMPORT_LOG + '/TEACHER', PageLink.IMPORT_LOG + '/detail/TEACHER'].some(
            (e) => location.pathname.startsWith(e)
          ),
        },
      ],
    },
    {
      id: ID_SIDE_BAR.CERTIFICATIONS,
      name: 'Certificates',
      icon: 'ki-outline ki-award',
      showMenu: [PageLink.CERTIFICATES].includes(location.pathname),
      active: location.pathname === PageLink.CERTIFICATES,
      link: PageLink.CERTIFICATES,
    },
  ]

  const menuAdmin = [
    {
      id: ID_SIDE_BAR.ROLES,
      name: 'Roles',
      icon: 'ki-outline ki-key',
      showMenu: [PageLink.ROLES, PageLink.PERMISSIONS].includes(location.pathname),
      children: [
        {
          id: ID_SIDE_BAR.ROLE,
          name: 'Roles List',
          link: PageLink.ROLES,
          active: location.pathname === PageLink.ROLES,
        },
        // {
        //   id: ID_SIDE_BAR.PERMISSION,
        //   name: 'Permissions',
        //   link: PageLink.PERMISSIONS,
        //   active: location.pathname === PageLink.PERMISSIONS,
        // },
      ],
    },
    {
      id: ID_SIDE_BAR.SETTING,
      name: 'Setting',
      link: PageLink.SETTING,
      active: location.pathname === PageLink.SETTING,
      icon: 'ki-outline ki-setting-2',
    },
    {
      id: ID_SIDE_BAR.VIDEO,
      name: 'Video',
      link: PageLink.VIDEO,
      active: location.pathname === PageLink.VIDEO,
      icon: 'ki-outline ki-update-folder',
    },
    {
      id: ID_SIDE_BAR.SYNC_HUBSPOT,
      name: 'Sync Hubspot',
      link: PageLink.SYNC_HUBSPOT,
      active: location.pathname === PageLink.SYNC_HUBSPOT,
      icon: 'ki-outline ki-technology-2',
    },
    {
      id: ID_SIDE_BAR.CACHE,
      name: 'Cache',
      link: PageLink.CACHE,
      active: location.pathname === PageLink.CACHE,
      icon: 'ki-outline ki-clipboard',
    },
  ]

  const menuMarketing = [
    {
      id: ID_SIDE_BAR.MARKETING,
      name: 'Marketing',
      icon: 'ki-outline ki-soft-3 fs-2',
      showMenu: [PageLink.CHECK_DUPLICATE_CONTACT].includes(location.pathname),
      children: [
        {
          id: ID_SIDE_BAR.CHECK_DUPLICATE_CONTACT,
          name: 'Check Duplicate Contacts',
          link: PageLink.CHECK_DUPLICATE_CONTACT,
          active: location.pathname === PageLink.CHECK_DUPLICATE_CONTACT,
        },
      ],
    },
  ]

  const activeAdmin = [
    PageLink.ADD_ROLES,
    PageLink.UPDATE_ROLES,
    PageLink.ROLES,
    PageLink.PERMISSIONS,
    PageLink.SETTING,
    PageLink.VIDEO,
    PageLink.SYNC_HUBSPOT,
    PageLink.CACHE,
  ].includes(location.pathname)

  const activeMarketing = [PageLink.CHECK_DUPLICATE_CONTACT].includes(location.pathname)

  const rolesUser = profileMe?.roles
  // Hàm util để kiểm tra quyền truy cập
  const checkPermission = (permissions: any, title: any) =>
    rolesUser?.some((role: Role) => role.permissions?.includes(title))

  const permissionsToCheck = {
    allowRenderClassList: TITLE_CLASS_GR.VIEW_LIST_CLASS,
    allowRenderClassCreate: TITLE_CLASS_GR.CREATE_CLASS,
    allowRenderClassEdit: TITLE_CLASS_GR.EDIT_CLASS,
    allowRenderReportClass: TITLE_CLASS_GR.IMPORT_CLASS,
    allowRenderRemoveStudentInClass: TITLE_CLASS_GR.REMOVE_STUDENT_IN_CLASS,
    allowRenderCreateStudentInClass: TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS,
    allowRenderImportClass: TITLE_CLASS_GR.IMPORT_CLASS,
    allowRenderEditDuration: TITLE_CLASS_GR.EDIT_DURATION,
    allowRenderReportStudent: TITLE_CLASS_GR.IMPORT_STUDENT_IN_CLASS,
    allowRenderEditCourseContent: TITLE_CLASS_GR.EDIT_COURSE_CONTENT_CLASS,
    allowRenderEditCourseContentOfStudent: TITLE_CLASS_GR.EDIT_COURSE_CONTENT_OF_STUDENT_IN_CLASS,

    allowRenderListCourse: TITLE_COURSE_GR.VIEW_COURSE,
    allowRenderCreateCourse: TITLE_COURSE_GR.CREATE_COURSE,
    allowRenderEditCourse: TITLE_COURSE_GR.EDIT_COURSE,

    allowRenderTopicList: TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC,
    allowRenderCreateTopic: TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC,
    allowRenderEditTopic: TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC,
    allowRenderRemoveTopic: TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC,

    allowRenderCaseStudyList: TITLE_STORY_GR.VIEW_LIST_STORY,
    allowRenderCreateCaseStudy: TITLE_STORY_GR.CREATE_STORY,
    allowRenderRemoveCaseStudy: TITLE_STORY_GR.REMOVE_STORY,
    allowRenderEditCaseStudy: TITLE_STORY_GR.EDIT_STORY,

    allowRenderEntranceTest: TITLE_QUIZ_GR.VIEW_LIST_QUIZ,
    allowRenderCreateEntranceTest: TITLE_QUIZ_GR.CREATE_QUIZ,
    allowRenderEditEntranceTest: TITLE_QUIZ_GR.EDIT_QUIZ,
    allowRenderRemoveEntranceTest: TITLE_QUIZ_GR.REMOVE_QUIZ,
    allowRenderParticipantList: TITLE_QUIZ_GR.VIEW_PARTICIPANT_LIST,
    allowRenderPublicScoreDetail: TITLE_QUIZ_GR.PUBLIC_SCORE_DETAIL,

    allowRenderGetSectionQuiz: TITLE_SECTION_QUIZ_GR.GET_SECTION_QUIZ,
    allowRenderCreateSectionQuiz: TITLE_SECTION_QUIZ_GR.CREATE_SECTION_QUIZ,
    allowRenderEditSectionQuiz: TITLE_SECTION_QUIZ_GR.EDIT_SECTION_QUIZ,
    allowRenderRemoveSectionQuiz: TITLE_SECTION_QUIZ_GR.REMOVE_SECTION_QUIZ,

    allowRenderResourcesList: TITLE_RESOURCES_GR.GET_LIST_RESOURCES,
    allowRenderDownloadResources: TITLE_RESOURCES_GR.DOWNLOAD_RESOURCES,
    allowRenderViewUpload: TITLE_RESOURCES_GR.VIEW_UPLOAD_FOLDER_RESOURCES,
    allowRenderCreateResources: TITLE_RESOURCES_GR.CREATE_RESOURCES,
    allowRenderUpdateResources: TITLE_RESOURCES_GR.UPDATE_RESOURCES,
    allowRenderRemoveResources: TITLE_RESOURCES_GR.REMOVE_RESOURCES,

    allowRenderCreateNotification: TITLE_NOTIFICATIONS_GR.CREATE_NOTIFICATION,
    allowRenderNotificationList: TITLE_NOTIFICATIONS_GR.VIEW_LIST,
    allowRenderEditNotification: TITLE_NOTIFICATIONS_GR.EDIT_NOTIFICATION,
    allowRenderMailLog: TITLE_NOTIFICATIONS_GR.VIEW_MAIL_LOG,

    allowRenderGroupList: TITLE_GROUPS_GR.VIEW_GROUP,
    allowRenderCreateGroup: TITLE_GROUPS_GR.CREATE_GROUP,
    allowRenderEditGroup: TITLE_GROUPS_GR.EDIT_GROUP,
    allowRenderRemoveGroup: TITLE_GROUPS_GR.REMOVE_GROUP,

    allowRenderStaffList: TITLE_STAFF_GR.GET_STAFF,
    allowRenderCreateStaffs: TITLE_STAFF_GR.CREATE_STAFF,
    allowRenderEditStaffs: TITLE_STAFF_GR.EDIT_STAFF,
    allowRenderChangeEmailStaffs: TITLE_STAFF_GR.CHANGE_EMAIL_STAFF,
    allowRenderResetPasswordStaffs: TITLE_STAFF_GR.RESET_PASSWORD_STAFF,
    allowRenderExportStaffs: TITLE_STAFF_GR.GET_EXPORT_STAFF,
    allowRenderImportStaffs: TITLE_STAFF_GR.CREATE_STAFF,
    allowRenderAssignPermissionStaff: TITLE_STAFF_GR.GET_ASSIGN_PERMISSION_STAFF,

    allowRenderCertificate: TITLE_CERTIFICATE_GR.VIEW_CERTIFICATE,
    allowRenderCreateCertificate: TITLE_CERTIFICATE_GR.CREATE_CERTIFICATE,
    allowRenderRemoveCertificate: TITLE_CERTIFICATE_GR.REMOVE_CERTIFICATE,
    allowRenderDuplicateCertificate: TITLE_CERTIFICATE_GR.DUPLICATE_CERTIFICATE,
    allowRenderEditCertificate: TITLE_CERTIFICATE_GR.EDIT_CERTIFICATE,

    allowRender: TITLE_GR.VIEW_USER_GR,
    allowRenderCreate: TITLE_GR.CREATE_USER_GR,
    allowRenderRemove: TITLE_GR.REMOVE_USER_GR,
    allowRenderImport: TITLE_GR.CREATE_USER_GR,
    allowRenderEdit: TITLE_GR.EDIT_USER_GR,
    allowRenderGetDevices: TITLE_GR.GET_SECURITY_USER_GR,
    allowRenderRemoveDevices: TITLE_GR.REMOVE_DEVICE_USER_GR,
    allowRenderSecurityDevices: TITLE_GR.REMOVE_SECURITY_USER_GR,
    allowRenderMakeContactDF: TITLE_GR.MAKE_CONTACT_DEFAULT_GR,
    allowRenderGetActivities: TITLE_GR.GET_ACTIVITIES_USER_GR,
    allowRenderChangeEmail: TITLE_GR.PUT_CHANGE_EMAIL_USER_GR,
    allowRenderResetPassword: TITLE_GR.PUT_RESET_PASSWORD_USER_GR,
    allowRenderGetExportData: TITLE_GR.GET_EXPORT_DATA_USER_GR,

    allowRenderImportReport: TITLE_LOGS_GR.VIEW_LIST_IMPORT_LOGS,
    allowRenderImportLogs: TITLE_LOGS_GR.VIEW_LIST_IMPORT_LOGS,
    allowRenderCheckDuplicateContact: TITLE_MARKETING_GR.CHECK_DUPLICATE_CONTACT,

    allowRenderQuestion: TITLE_QUESTIONS_GR.VIEW_QUESTION,
    allowRenderCreateQuestion: TITLE_QUESTIONS_GR.CREATE_QUESTION,
    allowRenderEditQuestion: TITLE_QUESTIONS_GR.EDIT_QUESTION,
    allowRenderRemoveQuestion: TITLE_QUESTIONS_GR.REMOVE_QUESTION,

    allowRenderGetExam: TITLE_EXAM_GR.GET_EXAMS,
    allowRenderCreateExam: TITLE_EXAM_GR.CREATE_EXAM,
    allowRenderEditExam: TITLE_EXAM_GR.EDIT_EXAM,
    allowRenderRemoveExam: TITLE_EXAM_GR.REMOVE_EXAM,
  } // Title trên side bar dùng để phân quyền

  const allowRender: Record<string, boolean | undefined> = Object.fromEntries(
    Object.entries(permissionsToCheck).map(([key, title]: [string, string]) => [
      key,
      checkPermission(rolesUser, title),
    ])
  )
  const adminPermission = rolesUser?.some((role: Role) => role.code === CODE_ADMIN.SUPER_ADMIN)

  const allowRenderOptimized = { ...allowRender, adminPermission }

  const showMenuExam =
    allowRender?.allowRenderGetExam ||
    allowRender?.allowRenderCreateExam ||
    allowRender?.allowRenderEditExam ||
    allowRender?.allowRenderRemoveExam

  const filterMenuMarketing = menuMarketing
    .map((item) => {
      if (!allowRenderOptimized.adminPermission) {
        switch (item.id) {
          case ID_SIDE_BAR.MARKETING:
            if (allowRender.allowRenderCheckDuplicateContact) {
              return {
                ...item,
              }
            }
            break
          default:
            return null
        }
      } else {
        return item
      }
    })
    .filter(Boolean)

  const filteredMenuLMS = menuLMS
    .map((item) => {
      if (!allowRenderOptimized.adminPermission) {
        switch (item.id) {
          case ID_SIDE_BAR.STUDENT:
            if (
              !allowRenderOptimized.adminPermission &&
              (allowRender.allowRender ||
                allowRender.allowRenderCreate ||
                allowRender.allowRenderRemove ||
                allowRender.allowRenderImport ||
                allowRender.allowRenderEdit ||
                allowRender.allowRenderGetDevices ||
                allowRender.allowRenderRemoveDevices ||
                allowRender.allowRenderMakeContactDF ||
                allowRender.allowRenderGetActivities ||
                allowRender.allowRenderChangeEmail ||
                allowRender.allowRenderResetPassword ||
                allowRender.allowRenderGetExportData)
            ) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.CREATE_STUDENTS:
                          return allowRender.allowRenderCreate ? childItem : null
                        case ID_SIDE_BAR.LIST_STUDENTS:
                          return allowRender.allowRender ||
                            allowRender.allowRenderCreate ||
                            allowRender.allowRenderRemove ||
                            allowRender.allowRenderImport ||
                            allowRender.allowRenderEdit ||
                            allowRender.allowRenderGetDevices ||
                            allowRender.allowRenderRemoveDevices ||
                            allowRender.allowRenderSecurityDevices ||
                            allowRender.allowRenderMakeContactDF ||
                            allowRender.allowRenderGetActivities ||
                            allowRender.allowRenderChangeEmail ||
                            allowRender.allowRenderResetPassword ||
                            allowRender.allowRenderGetExportData
                            ? childItem
                            : null
                        case ID_SIDE_BAR.IMPORT_REPORTS_STUDENT:
                          return allowRender.allowRenderCreate ? childItem : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break
          case ID_SIDE_BAR.TEACHERS:
            if (
              !allowRenderOptimized.adminPermission &&
              (allowRender.allowRender ||
                allowRender.allowRenderCreate ||
                allowRender.allowRenderRemove ||
                allowRender.allowRenderImport ||
                allowRender.allowRenderEdit ||
                allowRender.allowRenderGetDevices ||
                allowRender.allowRenderRemoveDevices ||
                allowRender.allowRenderMakeContactDF ||
                allowRender.allowRenderGetActivities ||
                allowRender.allowRenderChangeEmail ||
                allowRender.allowRenderResetPassword ||
                allowRender.allowRenderGetExportData)
            ) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.CREATE_STUDENTS:
                          return allowRender.allowRenderCreate ? childItem : null
                        case ID_SIDE_BAR.CREATE_TEACHERS:
                          return allowRender.allowRenderCreate ? childItem : null
                        case ID_SIDE_BAR.LIST_STUDENTS:
                          return allowRender.allowRender ||
                            allowRender.allowRenderCreate ||
                            allowRender.allowRenderRemove ||
                            allowRender.allowRenderImport ||
                            allowRender.allowRenderEdit ||
                            allowRender.allowRenderGetDevices ||
                            allowRender.allowRenderRemoveDevices ||
                            allowRender.allowRenderSecurityDevices ||
                            allowRender.allowRenderMakeContactDF ||
                            allowRender.allowRenderGetActivities ||
                            allowRender.allowRenderChangeEmail ||
                            allowRender.allowRenderResetPassword ||
                            allowRender.allowRenderGetExportData
                            ? childItem
                            : null
                        case ID_SIDE_BAR.LIST_TEACHERS:
                          return allowRender.allowRender ||
                            allowRender.allowRenderCreate ||
                            allowRender.allowRenderRemove ||
                            allowRender.allowRenderImport ||
                            allowRender.allowRenderEdit ||
                            allowRender.allowRenderGetDevices ||
                            allowRender.allowRenderRemoveDevices ||
                            allowRender.allowRenderSecurityDevices ||
                            allowRender.allowRenderMakeContactDF ||
                            allowRender.allowRenderGetActivities ||
                            allowRender.allowRenderChangeEmail ||
                            allowRender.allowRenderResetPassword ||
                            allowRender.allowRenderGetExportData
                            ? childItem
                            : null
                        case ID_SIDE_BAR.IMPORT_REPORT_TEACHER:
                          return allowRender.allowRenderCreate ? childItem : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break
          case ID_SIDE_BAR.CLASSES:
            if (
              !allowRender.adminPermission &&
              (allowRender.allowRenderClassList ||
                allowRender.allowRenderClassCreate ||
                allowRender.allowRenderReportClass ||
                allowRender.allowRenderReportStudent ||
                allowRender.allowRenderClassEdit ||
                allowRender.allowRenderReportClass ||
                allowRender.allowRenderRemoveStudentInClass ||
                allowRender.allowRenderCreateStudentInClass ||
                allowRender.allowRenderImportClass ||
                allowRender.allowRenderEditDuration ||
                allowRender.allowRenderReportStudent ||
                allowRender.allowRenderEditCourseContentOfStudent ||
                allowRender.allowRenderEditCourseContent)
            ) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.CREATE_CLASS:
                          return allowRender.allowRenderClassCreate ? childItem : null
                        case ID_SIDE_BAR.LIST_CLASS:
                          return allowRender.allowRenderClassList ||
                            allowRender.allowRenderClassCreate ||
                            allowRender.allowRenderReportClass ||
                            allowRender.allowRenderReportStudent ||
                            allowRender.allowRenderClassEdit ||
                            allowRender.allowRenderReportClass ||
                            allowRender.allowRenderRemoveStudentInClass ||
                            allowRender.allowRenderCreateStudentInClass ||
                            allowRender.allowRenderImportClass ||
                            allowRender.allowRenderEditDuration ||
                            allowRender.allowRenderReportStudent ||
                            allowRender.allowRenderEditCourseContent ||
                            allowRender.allowRenderEditCourseContentOfStudent
                            ? childItem
                            : null
                        case ID_SIDE_BAR.REPORT_CLASS:
                          return allowRender.allowRenderReportClass ? childItem : null
                        case ID_SIDE_BAR.REPORT_STUDENT:
                          return allowRender.allowRenderReportStudent ? childItem : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break
          case ID_SIDE_BAR.COURSES:
            return {
              ...item,
              children:
                item.children
                  ?.map((childItem) => {
                    switch (childItem.id) {
                      case ID_SIDE_BAR.COURSE_CATEGORY:
                        return childItem
                      case ID_SIDE_BAR.COURSES_LIST:
                        return allowRender.allowRenderListCourse ||
                          allowRender.allowRenderCreateCourse ||
                          allowRender.allowRenderEditCourse
                          ? childItem
                          : null
                      case ID_SIDE_BAR.COURSE_SUBJECT:
                        return childItem
                      default:
                        return null
                    }
                  })
                  .filter(Boolean) || [],
            }

          case ID_SIDE_BAR.EXAM:
            if (showMenuExam) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.ID_CFA_LIST:
                          return showMenuExam ? childItem : null
                        case ID_SIDE_BAR.ID_ACCA_LIST:
                          return showMenuExam ? childItem : null
                        case ID_SIDE_BAR.ID_CMA_LIST:
                          return showMenuExam ? childItem : null
                        case ID_SIDE_BAR.ID_EXAM_IMPORT_LOG:
                          return showMenuExam ? childItem : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break

          case ID_SIDE_BAR.QUESTION_BANK:
            if (
              allowRender.allowRenderTopicList ||
              allowRender.allowRenderCaseStudyList ||
              allowRender.allowRenderCreateCaseStudy ||
              allowRender.allowRenderRemoveCaseStudy ||
              allowRender.allowRenderEditCaseStudy ||
              allowRender.allowRenderEditTopic ||
              allowRender.allowRenderRemoveTopic ||
              allowRender.allowRenderCreateTopic
            ) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.CASE_STUDY:
                          return allowRender.allowRenderCaseStudyList ||
                            allowRender.allowRenderCreateCaseStudy ||
                            allowRender.allowRenderRemoveCaseStudy ||
                            allowRender.allowRenderEditCaseStudy
                            ? childItem
                            : null
                        case ID_SIDE_BAR.TOPIC_LIST:
                          return allowRender.allowRenderTopicList ||
                            allowRender.allowRenderEditTopic ||
                            allowRender.allowRenderRemoveTopic ||
                            allowRender.allowRenderCreateTopic
                            ? childItem
                            : null

                        case ID_SIDE_BAR.QUESTION_LIST:
                          return allowRender.allowRenderQuestion ||
                            allowRender.allowRenderCreateQuestion ||
                            allowRender.allowRenderEditQuestion ||
                            allowRender.allowRenderRemoveQuestion ||
                            allowRender.allowRenderTopicList ||
                            allowRender.allowRenderCreateTopic ||
                            allowRender.allowRenderEditTopic ||
                            allowRender.allowRenderTopicList ||
                            allowRender.allowRenderRemoveTopic
                            ? childItem
                            : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break
          case ID_SIDE_BAR.ENTRANCE_TEST:
            if (
              allowRender.allowRenderEntranceTest ||
              allowRender.allowRenderCreateEntranceTest ||
              allowRender.allowRenderEditEntranceTest ||
              allowRender.allowRenderRemoveEntranceTest ||
              allowRender.allowRenderParticipantList ||
              allowRender.allowRenderGetSectionQuiz ||
              allowRender.allowRenderCreateSectionQuiz ||
              allowRender.allowRenderEditSectionQuiz ||
              allowRender.allowRenderRemoveSectionQuiz ||
              allowRender.allowRenderPublicScoreDetail
            ) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.ENTRANCE_TEST_LIST:
                          return allowRender.allowRenderEntranceTest ||
                            allowRender.allowRenderCreateEntranceTest ||
                            allowRender.allowRenderEditEntranceTest ||
                            allowRender.allowRenderRemoveEntranceTest ||
                            allowRender.allowRenderGetSectionQuiz ||
                            allowRender.allowRenderCreateSectionQuiz ||
                            allowRender.allowRenderEditSectionQuiz ||
                            allowRender.allowRenderRemoveSectionQuiz ||
                            allowRender.allowRenderPublicScoreDetail
                            ? childItem
                            : null
                        case ID_SIDE_BAR.PARTICIPANT_LIST:
                          return allowRender.allowRenderParticipantList ||
                            allowRender.allowRenderPublicScoreDetail
                            ? childItem
                            : null
                        case ID_SIDE_BAR.REPORT_PARTICIPANT:
                          return allowRender.allowRenderCreateEntranceTest ||
                            allowRender.allowRenderEditEntranceTest
                            ? childItem
                            : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break

          case ID_SIDE_BAR.EVENT_TEST:
            return {
              ...item,
              children:
                item.children
                  ?.map((childItem) => {
                    switch (childItem.id) {
                      case ID_SIDE_BAR.EVENT_TEST_LIST:
                        return childItem
                      case ID_SIDE_BAR.CREATE_EVENT_TEST:
                        return childItem
                      case ID_SIDE_BAR.ALL_PARTICIPANT:
                        return childItem
                      default:
                        return null
                    }
                  })
                  .filter(Boolean) || [],
            }

          case ID_SIDE_BAR.RESOURCE_MANAGER:
            if (
              allowRender.allowRenderResourcesList ||
              allowRender.allowRenderDownloadResources ||
              allowRender.allowRenderViewUpload ||
              allowRender.allowRenderCreateResources ||
              allowRender.allowRenderUpdateResources ||
              allowRender.allowRenderRemoveResources
            ) {
              return {
                ...item,
              }
            }
            break
          case ID_SIDE_BAR.NOTIFICATIONS:
            if (
              allowRender.allowRenderCreateNotification ||
              allowRender.allowRenderNotificationList ||
              allowRender.allowRenderGroupList ||
              allowRender.allowRenderEditNotification ||
              allowRender.allowRenderCreateGroup ||
              allowRender.allowRenderEditGroup ||
              allowRender.allowRenderRemoveGroup ||
              allowRender.allowRenderMailLog
            ) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.CREATE_NOTIFICATION:
                          return allowRender.allowRenderCreateNotification ? childItem : null
                        case ID_SIDE_BAR.LIST_NOTIFICATIONS:
                          return allowRender.allowRenderNotificationList ||
                            allowRender.allowRenderNotificationList ||
                            allowRender.allowRenderEditNotification ||
                            allowRender.allowRenderCreateNotification
                            ? childItem
                            : null
                        case ID_SIDE_BAR.LIST_GROUP:
                          return allowRender.allowRenderGroupList ||
                            allowRender.allowRenderCreateGroup ||
                            allowRender.allowRenderEditGroup ||
                            allowRender.allowRenderRemoveGroup
                            ? childItem
                            : null
                        case ID_SIDE_BAR.CREATE_GROUP:
                          return allowRender.allowRenderCreateGroup ? childItem : null
                        case ID_SIDE_BAR.MAIL_LOGS:
                          return allowRender.allowRenderMailLog ? childItem : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break

          case ID_SIDE_BAR.SUPPORTER_GROUP:
            return {
              ...item,
              children:
                item.children
                  ?.map((childItem) => {
                    switch (childItem.id) {
                      case ID_SIDE_BAR.LIST_SUPPORTER_GROUP:
                        return childItem
                      case ID_SIDE_BAR.CREATE_SUPPORTER_GROUP:
                        return childItem
                      default:
                        return null
                    }
                  })
                  .filter(Boolean) || [],
            }

          case ID_SIDE_BAR.STAFFS:
            if (
              allowRender.allowRenderStaffList ||
              allowRender.allowRenderCreateStaffs ||
              allowRender.allowRenderEditStaffs ||
              allowRender.allowRenderChangeEmailStaffs ||
              allowRender.allowRenderResetPasswordStaffs ||
              allowRender.allowRenderExportStaffs ||
              allowRender.allowRenderImportStaffs
            ) {
              return {
                ...item,
                children:
                  item.children
                    ?.map((childItem) => {
                      switch (childItem.id) {
                        case ID_SIDE_BAR.LIST_STAFF:
                          return allowRender.allowRenderStaffList ||
                            allowRender.allowRenderCreateStaffs ||
                            allowRender.allowRenderChangeEmailStaffs ||
                            allowRender.allowRenderEditStaffs ||
                            allowRender.allowRenderResetPasswordStaffs ||
                            allowRender.allowRenderExportStaffs ||
                            allowRender.allowRenderImportStaffs
                            ? childItem
                            : null
                        case ID_SIDE_BAR.CREATE_STAFF:
                          return allowRender.allowRenderCreateStaffs ? childItem : null
                        case ID_SIDE_BAR.IMPORT_REPORT:
                          return allowRender.allowRenderCreateStaffs ? childItem : null
                        default:
                          return null
                      }
                    })
                    .filter(Boolean) || [],
              }
            }
            break
          case ID_SIDE_BAR.CERTIFICATIONS:
            if (
              allowRender.allowRenderCertificate ||
              allowRender.allowRenderCreateCertificate ||
              allowRender.allowRenderRemoveCertificate ||
              allowRender.allowRenderDuplicateCertificate ||
              allowRender.allowRenderEditCertificate
            ) {
              return {
                ...item,
              }
            }
            break
          case ID_SIDE_BAR.MARKETING:
            if (allowRender.allowRenderCheckDuplicateContact) {
              return {
                ...item,
              }
            }
            break
          default:
            return null
        }
      } else {
        return item
      }
    })
    .filter(Boolean)

  // * Menu admin chỉ dành riêng cho admin
  const menuAdminfilter = menuAdmin.filter((item) => adminPermission && item)

  const staffLinks = [
    PageLink.STAFFS,
    PageLink.CREATE_STAFF,
    PageLink.IMPORT_LOG + '/STAFF',
    PageLink.IMPORT_LOG + '/detail/STAFF',
  ]

  const studentLinks = [
    PageLink.STUDENTS,
    PageLink.CREATE_STUDENTS,
    PageLink.IMPORT_LOG + '/STUDENT',
    PageLink.IMPORT_LOG + '/detail/STUDENT',
  ]

  const teacherLinks = [
    PageLink.TEACHERS,
    PageLink.CREATE_TEACHERS,
    PageLink.IMPORT_LOG + '/TEACHER',
    PageLink.IMPORT_LOG + '/detail/TEACHER',
  ]

  const isPathInLinks = (pathname: string, links: Array<any>) =>
    links.includes(pathname) || links.some((e) => pathname.startsWith(e))

  //TODO: active menu user
  const isActiveStaff =
    isPathInLinks(location.pathname, staffLinks) ||
    location.pathname.split('/profile')[0] === '/staff' ||
    location.pathname.split(id ?? '')[0] === '/staffs/update/'
  const isActiveStudent =
    isPathInLinks(location.pathname, studentLinks) ||
    location.pathname.split('/profile')[0] === '/student' ||
    location.pathname.split(id ?? '')[0] === '/students/update/'
  const isActiveTeacher =
    isPathInLinks(location.pathname, teacherLinks) ||
    location.pathname.split('/profile')[0] === '/teacher' ||
    location.pathname.split(id ?? '')[0] === '/teachers/update/'
  const isActiveUser = isActiveStaff || isActiveStudent || isActiveTeacher

  //TODO: active menu shop
  const isActiveShop = [
    PageLink.LIST_BLOGS,
    PageLink.BLOG_CATEGORIES,
    PageLink.TAGS,
    PageLink.AUTHORS,
    PageLink.SHOP_EVENT,
    PageLink.EVENT_CATEGORIES,
    PageLink.PRODUCTS,
    PageLink.PRODUCTS_EVENT,
  ].includes(location.pathname)

  //TODO: active menu shop
  const isActiveFAQs = [PageLink.SHOP_FAQS, PageLink.FAQS_CATEGORY].includes(location.pathname)
  const isActiveAdmin = [
    PageLink.ADD_ROLES,
    PageLink.UPDATE_ROLES,
    PageLink.ROLES,
    PageLink.PERMISSIONS,
    PageLink.SETTING,
  ].includes(location.pathname)

  const menuShop = [
    {
      id: 32,
      name: 'Blogs',
      icon: 'ki-outline ki-message-edit',
      showMenu: [PageLink.LIST_BLOGS].includes(location.pathname),
      children: [
        {
          id: 33,
          name: 'List Blogs',
          link: PageLink.LIST_BLOGS,
          active: location.pathname === PageLink.LIST_BLOGS,
        },
        {
          id: 34,
          name: 'New Post',
          link: `${PageLink.NEW_POST}/undefined`,
          active: location.pathname === PageLink.NEW_POST,
        },
      ],
    },
    {
      id: 35,
      name: 'Blog Categories',
      icon: 'ki-outline ki-category',
      showMenu: [PageLink.BLOG_CATEGORIES].includes(location.pathname),
      children: [
        {
          id: 36,
          name: 'List Categories',
          link: PageLink.BLOG_CATEGORIES,
          active: location.pathname === PageLink.BLOG_CATEGORIES,
        },
      ],
    },
    {
      id: 38,
      name: 'Tags',
      icon: 'ki-outline ki-price-tag',
      showMenu: [PageLink.TAGS].includes(location.pathname),
      children: [
        {
          id: 39,
          name: 'List Tags',
          link: PageLink.TAGS,
          active: location.pathname === PageLink.TAGS,
        },
      ],
    },
    {
      id: 40,
      name: 'Authors',
      icon: 'ki-outline ki-user-edit',
      showMenu: [PageLink.AUTHORS].includes(location.pathname),
      children: [
        {
          id: 41,
          name: 'List Authors',
          link: PageLink.AUTHORS,
          active: location.pathname === PageLink.AUTHORS,
        },
      ],
    },
    {
      id: 42,
      name: 'Events',
      icon: 'ki-outline ki-calendar-8',
      showMenu: [PageLink.SHOP_EVENT].includes(location.pathname),
      children: [
        {
          id: 43,
          name: 'List Events',
          link: PageLink.SHOP_EVENT,
          active: location.pathname === PageLink.SHOP_EVENT,
        },
        {
          id: 46,
          name: 'New Event',
          link: `${PageLink.SHOP_NEW_EVENT}/undefined`,
          active: location.pathname === PageLink.SHOP_NEW_EVENT,
        },
      ],
    },
    {
      id: 44,
      name: 'Event Categories',
      icon: 'ki-outline ki-calendar-tick',
      showMenu: [PageLink.EVENT_CATEGORIES].includes(location.pathname),
      children: [
        {
          id: 45,
          name: 'List Categories',
          link: PageLink.EVENT_CATEGORIES,
          active: location.pathname === PageLink.EVENT_CATEGORIES,
        },
      ],
    },
    {
      id: 48,
      name: 'Product',
      icon: 'ki-outline ki-calendar-tick',
      showMenu: [PageLink.PRODUCTS].includes(location.pathname),
      children: [
        {
          id: 49,
          name: 'List Products',
          link: PageLink.PRODUCTS,
          active: location.pathname === PageLink.PRODUCTS,
        },
        {
          id: 50,
          name: 'New Product',
          link: `${PageLink.PRODUCT_DETAIL}/undefined`,
          active: location.pathname === PageLink.PRODUCT_DETAIL,
        },
      ],
    },
    {
      id: 50,
      name: 'Product Event',
      icon: 'ki-outline ki-calendar-tick',
      showMenu: [PageLink.PRODUCTS_EVENT].includes(location.pathname),
      children: [
        {
          id: 49,
          name: 'List Products',
          link: PageLink.PRODUCTS_EVENT,
          active: location.pathname === PageLink.PRODUCTS_EVENT,
        },
      ],
    },
  ]

  const menuFAQs = [
    {
      id: 32,
      name: 'FAQs',
      icon: 'ki-outline ki-message-question',
      showMenu: [PageLink.SHOP_FAQS].includes(location.pathname),
      children: [
        {
          id: 33,
          name: 'List FAQs',
          link: PageLink.SHOP_FAQS,
          active: location.pathname === PageLink.SHOP_FAQS,
        },
      ],
    },
    {
      id: 60,
      name: 'Category',
      icon: 'ki-outline ki-category',
      showMenu: [PageLink.FAQS_CATEGORY].includes(location.pathname),
      children: [
        {
          id: 61,
          name: 'List Category',
          link: PageLink.FAQS_CATEGORY,
          active: location.pathname === PageLink.FAQS_CATEGORY,
        },
      ],
    },
  ]
  //TODO: menu của user
  const menuUser = [
    {
      id: 16,
      name: 'Staffs',
      icon: 'ki-outline ki-profile-circle',
      showMenu:
        [PageLink.STAFFS, PageLink.CREATE_STAFF].includes(location.pathname) ||
        [PageLink.IMPORT_LOG + '/STAFF', PageLink.IMPORT_LOG + '/detail/STAFF'].some((e) =>
          location.pathname.startsWith(e)
        ) ||
        location.pathname.split('/profile')[0] === '/staff' ||
        location.pathname.split(id ?? '')[0] === '/staffs/update/',
      children: [
        {
          id: ID_SIDE_BAR.LIST_STAFF,
          name: 'List Staffs',
          link: PageLink.STAFFS,
          active:
            location.pathname === PageLink.STAFFS ||
            location.pathname.split('/profile')[0] === '/staff' ||
            location.pathname.split(id ?? '')[0] === '/staffs/update/',
        },
        {
          id: ID_SIDE_BAR.CREATE_STAFF,
          name: 'Create Staffs',
          link: PageLink.CREATE_STAFF,
          active: location.pathname === PageLink.CREATE_STAFF,
        },
        {
          id: ID_SIDE_BAR.IMPORT_REPORT,
          name: 'Import Report',
          link: PageLink.IMPORT_LOG + '/STAFF',
          active: [PageLink.IMPORT_LOG + '/STAFF', PageLink.IMPORT_LOG + '/detail/STAFF'].some(
            (e) => location.pathname.startsWith(e)
          ),
        },
      ],
    },
    {
      id: ID_SIDE_BAR.STUDENT,
      name: 'Students',
      icon: 'ki-outline ki-teacher',
      showMenu:
        [PageLink.STUDENTS, PageLink.CREATE_STUDENTS].includes(location.pathname) ||
        [PageLink.IMPORT_LOG + '/STUDENT', PageLink.IMPORT_LOG + '/detail/STUDENT'].some((e) =>
          location.pathname.startsWith(e)
        ) ||
        location.pathname.split('/profile')[0] === '/student' ||
        location.pathname.split(id ?? '')[0] === '/students/update/',
      children: [
        {
          id: ID_SIDE_BAR.LIST_STUDENTS,
          name: 'List Students',
          link: PageLink.STUDENTS,
          active:
            location.pathname === PageLink.STUDENTS ||
            location.pathname.split('/profile')[0] === '/student' ||
            location.pathname.split(id ?? '')[0] === '/students/update/',
        },
        {
          id: ID_SIDE_BAR.CREATE_STUDENTS,
          name: 'Create Students',
          link: PageLink.CREATE_STUDENTS,
          active: location.pathname === PageLink.CREATE_STUDENTS,
        },
        {
          id: ID_SIDE_BAR.IMPORT_REPORTS_STUDENT,
          name: 'Import Report',
          link: PageLink.IMPORT_LOG + '/STUDENT',
          active: [PageLink.IMPORT_LOG + '/STUDENT', PageLink.IMPORT_LOG + '/detail/STUDENT'].some(
            (e) => location.pathname.startsWith(e)
          ),
        },
      ],
    },
    {
      id: ID_SIDE_BAR.TEACHERS,
      name: 'Teachers',
      icon: 'ki-outline ki-user',
      showMenu:
        [PageLink.TEACHERS, PageLink.CREATE_TEACHERS].includes(location.pathname) ||
        [PageLink.IMPORT_LOG + '/TEACHER', PageLink.IMPORT_LOG + '/detail/TEACHER'].some((e) =>
          location.pathname.startsWith(e)
        ) ||
        location.pathname.split('/profile')[0] === '/teacher' ||
        location.pathname.split(id ?? '')[0] === '/teachers/update/',
      children: [
        {
          id: ID_SIDE_BAR.LIST_TEACHERS,
          name: 'List Teachers',
          link: PageLink.TEACHERS,
          active:
            location.pathname === PageLink.TEACHERS ||
            location.pathname.split('/profile')[0] === '/teacher' ||
            location.pathname.split(id ?? '')[0] === '/teachers/update/',
        },
        {
          id: ID_SIDE_BAR.CREATE_TEACHERS,
          name: 'Create Teachers',
          link: PageLink.CREATE_TEACHERS,
          active: location.pathname === PageLink.CREATE_TEACHERS,
        },
        {
          id: ID_SIDE_BAR.IMPORT_REPORT_TEACHER,
          name: 'Import Report',
          link: PageLink.IMPORT_LOG + '/TEACHER',
          active: [PageLink.IMPORT_LOG + '/TEACHER', PageLink.IMPORT_LOG + '/detail/TEACHER'].some(
            (e) => location.pathname.startsWith(e)
          ),
        },
      ],
    },
  ]

  return (
    <>
      <MenuLeft
        menuLMS={filteredMenuLMS}
        menuAdmin={menuAdminfilter}
        menuMarketing={filterMenuMarketing}
        activeAdmin={activeAdmin}
        activeMarketing={activeMarketing}
        menuShop={menuShop}
        menuFAQs={menuFAQs}
        isActiveShop={isActiveShop}
        isActiveFAQs={isActiveFAQs}
        isActiveAdmin={isActiveAdmin}
      />
      <MenuRight
        menuLMS={filteredMenuLMS}
        menuAdmin={menuAdminfilter}
        activeAdmin={activeAdmin}
        menuMarketing={filterMenuMarketing}
        activeMarketing={activeMarketing}
        isActiveShop={isActiveShop}
        menuShop={menuShop}
        menuFAQs={menuFAQs}
      />
    </>
  )
}

export default Sidebar
