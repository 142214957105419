import { fetcher } from 'src/services/request'

export class CommonAPI {
  static getTags({ apiEndpoint, params }: { apiEndpoint: string; params: Object }) {
    return fetcher(apiEndpoint, {
      params,
    })
  }

  static getProvince(pageIndex: number, pageSize: number, params?: Object) {
    return fetcher(`address/provinces?page_index=${pageIndex}&page_size=${pageSize}`, {
      params,
    })
  }

  static getDistrict(pageIndex: number, pageSize: number, params?: Object) {
    return fetcher(`address/district?page_index=${pageIndex}&page_size=${pageSize}`, {
      params,
    })
  }

  static getWards(pageIndex: number, pageSize: number, params?: Object) {
    return fetcher(`address/ward?page_index=${pageIndex}&page_size=${pageSize}`, {
      params,
    })
  }
}
