import {Dispatch, SetStateAction, useEffect} from 'react'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import SappTable from 'src/components/base/SappTable'
import {KTCardBody} from 'src/_metronic/helpers'
import SappTooltip from 'src/common/SappTooltip'
import {ITagList} from 'src/type/shop/tag'
import {INewOrUpdatePopup} from 'src/type/shop/common'
import {TagAPI} from 'src/apis/short-course/tags'
import toast from 'react-hot-toast'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  htmlToRaw,
} from 'src/utils'
import {useNavigate} from 'react-router-dom'
import PagiantionSAPP from '../base/pagination/PagiantionSAPP'
import ShopActionBox from '../shop/shared/ShopActionBox'

// define headers
const headers = [
  {
    label: 'name',
    className: 'min-w-175px',
  },
  {
    label: 'Descriptions',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Slug',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Count',
    className: 'min-w-75px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

interface IQueryPrams {
  name: string | null
  sort_type: string | null
  fromDate: Date | string
  toDate: Date | string
}

interface IProps {
  tagList: ITagList | undefined
  setTagList: Dispatch<SetStateAction<ITagList | undefined>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  loadData: (page_index: number, page_size: number, object?: Object) => void
  queryParam: IQueryPrams
  getParams: (
    name: string,
    sort_type: string,
    fromDate?: Date | string,
    toDate?: Date | string
  ) => Object
  reloadPage: () => void
  setOpenNewOrUpdate: Dispatch<SetStateAction<INewOrUpdatePopup>>
}

const TableTags = ({
  tagList,
  setTagList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  loadData,
  queryParam,
  getParams,
  reloadPage,
  setOpenNewOrUpdate,
}: IProps) => {
  const navigate = useNavigate()

  /**
   * Một hàm dùng để change params trên router
   */
  const filterParamsPagination = () => {
    const dateQueryFromDate =
      queryParam?.fromDate instanceof Date ? getDateInfo(queryParam.fromDate) : null
    const dateQueryToDate =
      queryParam?.toDate instanceof Date ? getDateInfo(queryParam.toDate) : null
    return cleanParamsAPI(
      getParams(
        queryParam.name ?? '',
        queryParam.sort_type ?? '',
        queryParam.fromDate && dateQueryFromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        queryParam.toDate && dateQueryToDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : ''
      )
    )
  }

  /**
   * Call API get tags
   */
  useEffect(() => {
    setLoading(true)
    loadData(1, 10, cleanedParams)
  }, [])

  /**
   * Một hàm dùng để đổi params trên router
   * @param {number} currentPage  - trang page hiện tại
   * @param {number} size  - lượng bản ghi của page
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      name: queryParam.name ?? '',
      sort_type: queryParam.sort_type ?? '',
      fromDate: queryParam.fromDate instanceof Date ? formatDate(queryParam.fromDate) : '',
      toDate: queryParam.toDate instanceof Date ? formatDate(queryParam.toDate) : '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  /**
   * Call API khi change pagination
   * @param {number} page_index  - trang page hiện tại
   * @param {number} page_size  - lượng bản ghi của page
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    loadData(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  /**
   * handle open Update popup
   * @param {string} id  - id của tag muốn chỉnh sửa
   */
  const handleOpenUpdate = async (id: string) => {
    try {
      const res = await TagAPI.getTag(id)
      setOpenNewOrUpdate({type: 'edit', data: res.data, isOpen: true})
    } catch (error) {
      // do nothing
    }
  }

  /**
   * handle to delete tag
   * @param {string} id  - id của tag muốn xoá
   */
  const deleteTag = async (id: string) => {
    try {
      await TagAPI.deleteTag(id)
      toast.success('Tag deleted successfully!')
      reloadPage()
    } catch (error: any) {
      // do nothing
    }
  }
  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        data={tagList?.blogTags}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {tagList?.blogTags?.map((data, index) => {
          const isChecked = checkedList.includes(data.id)

          return (
            <tr key={index}>
              <td>
                <SAPPCheckbox
                  checkTarget='#kt_table_users .form-check-input'
                  checked={isChecked}
                  onChange={() => {
                    toggleCheck(data.id)
                  }}
                />
              </td>
              <td className='text-start'>
                <div
                  onClick={() => {
                    handleOpenUpdate(data.id)
                  }}
                >
                  <SappTooltip title={data?.name} link='' max_length={80} />
                </div>
              </td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>
                  {data.describe ? htmlToRaw(data.describe) : ''}
                </div>
              </td>
              <td className='text-start'>{data?.slug}</td>
              <td className='text-start'>{data?.blog_count}</td>
              <td className='text-start'>
                <ShopActionBox
                  id={data.id}
                  setLoading={setLoading}
                  reloadPage={reloadPage}
                  onDelete={deleteTag}
                  onEdit={handleOpenUpdate}
                />
              </td>
            </tr>
          )
        })}
      </SappTable>
      <PagiantionSAPP
        currentPage={tagList?.metadata?.page_index || 1}
        pageSize={tagList?.metadata?.page_size || 10}
        totalItems={tagList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableTags
