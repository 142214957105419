import { Dispatch, SetStateAction, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { KTCardBody } from 'src/_metronic/helpers'
import { ShopCategoryAPI } from 'src/apis/short-course/blogs/category'
import SappTooltip from 'src/common/SappTooltip'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import { IConfirm } from 'src/hooks/use-confirm'
import { IBlogCategoryList } from 'src/type/shop/blog'
import { INewOrUpdatePopup } from 'src/type/shop/common'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  htmlToRaw,
} from 'src/utils'
import ShopActionBox from '../shared/ShopActionBox'

interface IQueryPrams {
  name: string | null
  sort_type: string | null
  fromDate: Date | null
  toDate: Date | null
  page_index: number | null
  page_size: number | null
}

interface IProps {
  loading: boolean
  blogCategories: IBlogCategoryList | undefined
  fetchShopCategory: (page_index: number, page_size: number, params?: Object) => void
  queryParams: IQueryPrams
  getParams: (
    name: string,
    sort_type: string,
    fromDate: Date | string,
    toDate: Date | string,
    dateField: string
  ) => {
    name?: string
    sort_type?: string
    fromDate?: Date | string
    toDAte?: Date | string
    dateField?: string
  }
  checkedList: string[]
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, all: boolean) => void
  isCheckedAll: boolean
  filterParams: { [key: string]: any }
  setLoading: Dispatch<SetStateAction<boolean>>
  confirm: (confirm: IConfirm) => void
  setOpenPopupDelete: Dispatch<SetStateAction<boolean>>
  setOpenNewOrUpdate: Dispatch<SetStateAction<INewOrUpdatePopup>>
  reloadPage: () => void
}

// define headers
const headers = [
  {
    label: 'name',
    className: 'min-w-175px',
  },
  {
    label: 'Parent',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Descriptions',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Slug',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Count',
    className: 'min-w-75px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

const TableCategory = ({
  fetchShopCategory,
  loading,
  blogCategories,
  queryParams,
  getParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  filterParams,
  setOpenNewOrUpdate,
  setLoading,
  reloadPage,
}: IProps) => {
  const navigate = useNavigate()
  // biến này check điều kiện xem có filter không?
  const showQueryParams =
    queryParams.name || queryParams.sort_type || queryParams.fromDate || queryParams.toDate

  // logic call API khi lần đầu render component
  useEffect(() => {
    fetchShopCategory(queryParams?.page_index || 1, queryParams.page_size || 10, filterParams)
  }, [])

  // Hàm để thay đổi params trên URL khi thực hiện phân trang hoặc tìm kiếm
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: queryParams.name ?? '',
      sort_type: queryParams.sort_type ?? '',
      fromDate: queryParams?.fromDate ? formatDate(queryParams?.fromDate) : '',
      toDate: queryParams?.toDate ? formatDate(queryParams.toDate) : '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  // Hàm để tạo params cho việc gọi API khi thực hiện phân trang hoặc tìm kiếm
  const filterParamsPagination = () => {
    // Lấy thông tin ngày, tháng, năm từ params gửi cho người khác
    const dateQueryFromDate = queryParams.fromDate ? getDateInfo(queryParams?.fromDate) : null
    const dateQueryToDate = queryParams?.toDate ? getDateInfo(queryParams?.toDate) : null
    return cleanParamsAPI(
      getParams(
        queryParams.name ? queryParams.name.trim() : '',
        queryParams.sort_type ?? '',
        queryParams.fromDate && dateQueryFromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        queryParams.toDate && dateQueryToDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showQueryParams ? 'updated_at' : 'created_at'
      )
    )
  }

  // Hàm xử lý sự kiện khi thay đổi phân trang
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchShopCategory(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  // Hàm xóa một category
  const deleteCategory = async (id: string) => {
    const res = await ShopCategoryAPI.delete(id)
    toast.success('Categories deleted successfully!')
    if (res.success) {
      reloadPage()
    }
  }

  /**
   * handle open Update popup
   * @param {string} id  - id của productCategory muốn chỉnh sửa
   */
  const handleOpenUpdate = async (id: string) => {
    try {
      const res = await ShopCategoryAPI.getBlogCategory(id)
      setOpenNewOrUpdate({ type: 'edit', data: res.data, isOpen: true })
    } catch (error) {
      // do nothing
    }
  }

  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        data={blogCategories?.blogCategories}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {blogCategories?.blogCategories?.map((data) => {
          const isChecked = checkedList.includes(data.id)
          return (
            <tr key={data?.id}>
              <td>
                <SAPPCheckbox
                  checkTarget='#kt_table_users .form-check-input'
                  checked={isChecked}
                  onChange={() => toggleCheck(data.id)}
                />
              </td>
              <td className='text-start'>
                <div onClick={() => handleOpenUpdate(data.id)}>
                  <SappTooltip title={data?.name ?? '-'} link='' max_length={80} />
                </div>
              </td>
              <td className='text-start'>{data?.parent?.name ?? '-'}</td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>
                  {data?.describe ? htmlToRaw(data?.describe) : ''}
                </div>
              </td>
              <td className='text-start'>{data?.slug}</td>
              <td className='text-start'>{data?.blog_count}</td>
              <td className='text-start'>
                <ShopActionBox
                  id={data?.id}
                  setLoading={setLoading}
                  reloadPage={reloadPage}
                  onDelete={deleteCategory}
                  onEdit={handleOpenUpdate}
                />
              </td>
            </tr>
          )
        })}
      </SappTable>
      <PagiantionSAPP
        currentPage={blogCategories?.metadata?.page_index || 1}
        pageSize={blogCategories?.metadata?.page_size || 10}
        totalItems={blogCategories?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableCategory
