/* eslint-disable react-hooks/rules-of-hooks */
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { ResourceAPI } from 'src/apis/resource-bank'
import { ResourcesAPI } from 'src/apis/resources'
import LoadingTable from 'src/common/LoadingTable'
import SappTooltip from 'src/common/SappTooltip'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {
  MOCKUP_HEADER,
  VALIDATE_FIELD_MAX_LENGTH,
  VALIDATE_FOLDER_NAME,
  VALIDATION_FIELD,
} from 'src/constants'
import { CODE_ADMIN, TITLE_RESOURCES_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { IResource, IResourceList, RESOURCE_STATUS_LABEL } from 'src/type/resource'
import { displayBytes } from 'src/utils'
import { z } from 'zod'
import MoveToFolderModal from './MoveToFolderModal'
import ResourceAction from './ResourceAction'
interface IProps {
  loading: boolean
  resourceList: IResourceList | undefined
  isCheckedAll: boolean
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  newFolder: boolean
  handleCloseCreateFolder: () => void
  checkedList: string[]
  toggleCheck: (id: string) => void
  confirm: any
  deleteResource: (id: string[]) => void
  handlePaginationChange: (page_index: number, page_size: number) => void
  setLoading: Dispatch<SetStateAction<boolean>>
  setNewFolder: Dispatch<SetStateAction<boolean>>
  goToPage: any
  resourceId: string
  setResourceId: Dispatch<SetStateAction<string>>
  handleEditResourceVideo: (resource: IResource) => void
  fileBreadcrumb?: {
    name: string
    id: string
    parent_id: string
  }[]
  fetchGetResource: () => Promise<void>
  suffix_types: string
}

const TableResource = ({
  loading,
  resourceList,
  isCheckedAll,
  toggleCheckAll,
  newFolder,
  handleCloseCreateFolder,
  checkedList,
  toggleCheck,
  confirm,
  deleteResource,
  handlePaginationChange,
  // setLoading,
  goToPage,
  setNewFolder,
  resourceId,
  setResourceId,
  handleEditResourceVideo,
  fileBreadcrumb,
  fetchGetResource,
  suffix_types,
}: IProps) => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const [openChoiceFolder, setOpenChoiceFolder] = useState<{
    open: boolean
    id: string
    parentId?: string
  }>()

  const parent_id = searchParams.get('parent_id') as string

  // Validate for input
  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD })
      .max(256, VALIDATE_FIELD_MAX_LENGTH('Folder Name', 256))
      .regex(/^[^\\/:*?"<>|]+$/, { message: VALIDATE_FOLDER_NAME }),
  })
  // Using validate for input
  const { control, handleSubmit, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  useEffect(() => {
    if (!resourceId) {
      setValue('name', '')
    }
  }, [resourceId])

  // TODO: hàm submit khi tạo folder
  const onSubmit = async (data: any) => {
    let location = ''

    if (Array.isArray(fileBreadcrumb)) {
      location = fileBreadcrumb.map((e) => e.name)?.join('/')
    }
    try {
      await ResourceAPI.createFolder({
        name: data.name?.trim(),
        parent_id,
        location,
      }).then(async () => {
        handleOpenForm(false)
        reset()
        setResourceId('')
        await goToPage({})
        fetchGetResource()
      })
    } catch (error) {}
  }
  const [editingItemId, setEditingItemId] = React.useState(null)

  const handleOpenForm = (status: boolean) => {
    reset({ keepIsValid: false })
    setNewFolder(status)
  }

  const handleEdit = (itemId: any) => {
    setEditingItemId(itemId)
  }

  const handleDownload = async (resources: IResource[]) => {
    const files = resources?.map((e) => ({
      name: e.name || '',
      file_key: e.file_key || '',
    }))
    try {
      await ResourcesAPI.downloadFile({ files })
    } catch (error) {}
  }
  const handleMoveToFolder = async (resource: IResource) => {
    const currentResource = await ResourceAPI.getResourceDetail(resource.id)
    if (currentResource?.data?.id) {
      setOpenChoiceFolder({
        open: true,
        id: currentResource.data.id,
        parentId: currentResource.data.parent_id,
      })
    }
  }

  const handleSaveChoiceFolder = async (resourcesParent: IResource[], resourceId?: string) => {
    if (!resourceId) {
      return
    }

    try {
      await ResourceAPI.updateResource(
        undefined,
        resourceId,
        resourcesParent?.[0]?.id || null,
        resourcesParent?.[0]?.name + '/' || '',
        'BOTH'
      )
      setOpenChoiceFolder(undefined)
      goToPage({})
    } catch (error) {}
  }
  const handleCloseChoiceFolder = async () => {
    setOpenChoiceFolder(undefined)
  }

  const handleClose = () => {
    reset({ keepIsValid: false })
    handleCloseCreateFolder()
  }

  const onInlineEdit = async (data: any, i: number, resourceId: string) => {
    const name = data.name?.trim()
    try {
      await ResourceAPI.updateResource(name, resourceId, parent_id)
      handleCloseCreateFolder()
      goToPage({})
    } catch (error: any) {
    } finally {
      fetchGetResource()
    }
  }

  const handleNavigateIntoFolder = (resource: IResource) => {
    goToPage({
      parent_id: resource?.id,
      page_index: 1,
      page_size: 10,
      search_key: '',
      name: resource?.name,
    })
  }

  //TODO: reset hết checkbox khi thay đổi parent_id
  useEffect(() => {
    toggleCheckAll(false)
  }, [parent_id])
  const { profileMe } = useUserContext()
  const allowRenderEditResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.UPDATE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDownloadResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.DOWNLOAD_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderRemoveResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.REMOVE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowAction =
    allowRenderEditResources || allowRenderDownloadResources || allowRenderRemoveResources

  const isSearchAll = suffix_types === undefined || suffix_types === ''

  return (
    <>
      <SappTable
        headers={[
          {
            label: 'NAME',
          },
          {
            label: 'STATUS',
            className: 'mw-50px',
          },
          {
            label: 'SIZE',
            className: 'mw-50px',
          },
          {
            label: isSearchAll ? 'LOCATION' : 'LAST MODIFIED',
            className: 'mw-150px w-150px',
          },
          {
            label: '',
          },
        ]}
        loading={loading}
        data={resourceList?.resources}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((header, i) => (
              <LoadingTable key={header} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {newFolder && isEmpty(resourceId) && (
              <tr>
                <td></td>
                <td className='fv-row fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container'>
                  <div className='d-flex align-items-start'>
                    <span className='mt-4'>
                      <KTIcon
                        iconName='folder'
                        iconType='outline'
                        className='sapp-fs-2.2rem text-primary me-4'
                      />
                    </span>

                    <div className='me-3 mw-250px'>
                      <HookFormTextField
                        control={control}
                        name='name'
                        placeholder='Enter the folder name'
                        className='mw-250px min-w-250px'
                        defaultValue=''
                      />
                    </div>

                    <button
                      className='w-45px h-45px btn btn-icon btn-light-primary me-3'
                      onClick={handleSubmit(onSubmit)}
                    >
                      <span className='indicator-label'>
                        <i className='ki-outline ki-check fs-1'></i>
                      </span>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </button>

                    <button
                      className='w-45px h-45px btn btn-icon btn-light-danger'
                      onClick={handleClose}
                    >
                      <span className='indicator-label'>
                        <i className='ki-outline ki-cross fs-1'></i>
                      </span>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </button>
                  </div>
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </td>
                <td></td>
                <td></td>
              </tr>
            )}
            {resourceList?.resources?.map((resource, index: number) => {
              const isChecked = checkedList.includes(resource.id)
              return (
                <tr key={resource?.id}>
                  <td className='align-top'>
                    <div className='h-45px d-flex align-items-center'>
                      <SAPPCheckbox
                        checked={isChecked}
                        ktCheck={isChecked}
                        onChange={() => {
                          toggleCheck(resource.id)
                        }}
                      />
                    </div>
                  </td>

                  <td className='text-start min-w-150px'>
                    {editingItemId === resource.id && !isEmpty(resourceId) && newFolder ? (
                      <>
                        <div className='d-flex align-items-start'>
                          <span className='icon-wrapper'>
                            {resource.resource_type === 'FOLDER' ? (
                              <div className='mt-3'>
                                <KTIcon
                                  iconName='folder'
                                  iconType='outline'
                                  className='sapp-fs-2.2rem text-primary me-4'
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </span>
                          <div className='me-3 mw-250px'>
                            <HookFormTextField
                              control={control}
                              name={`name`}
                              className='mw-250px min-w-250px'
                              defaultValue={resource.name}
                            />
                          </div>

                          <button
                            className='w-45px h-45px btn btn-icon btn-light-primary me-3'
                            onClick={handleSubmit((e) => onInlineEdit(e, index, resource.id))}
                          >
                            <span className='indicator-label'>
                              <i className='ki-outline ki-check fs-1'></i>
                            </span>
                            <span className='indicator-progress'>
                              <span className='spinner-border spinner-border-sm align-middle'></span>
                            </span>
                          </button>

                          <button
                            className='w-45px h-45px btn btn-icon btn-light-danger'
                            onClick={handleClose}
                          >
                            <span className='indicator-label'>
                              <i className='ki-outline ki-cross fs-1'></i>
                            </span>
                            <span className='indicator-progress'>
                              <span className='spinner-border spinner-border-sm align-middle'></span>
                            </span>
                          </button>
                        </div>
                        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                      </>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <span className='icon-wrapper'>
                          {resource.resource_type === 'FOLDER' ? (
                            <KTIcon
                              iconName='folder'
                              iconType='outline'
                              className='sapp-fs-2.2rem text-primary me-4'
                            />
                          ) : (
                            <></>
                          )}
                        </span>
                        <span
                          className={`text-gray-800 sapp-text-truncate-3 ${
                            resource.suffix_type && resource.suffix_type === 'FOLDER'
                              ? 'text-hover-primary sapp-cursor-pointer'
                              : ''
                          }`}
                          onClick={() => {
                            if (resource.suffix_type && resource.suffix_type === 'FOLDER') {
                              handleNavigateIntoFolder(resource)
                            }
                          }}
                        >
                          <SappTooltip title={resource?.name as string} />
                        </span>
                      </div>
                    )}
                  </td>
                  <td className='text-start w-150px text-nowrap'>
                    {RESOURCE_STATUS_LABEL.find((item) => item.value === resource?.status)?.label}
                  </td>
                  <td className='text-start w-150px text-nowrap'>{displayBytes(resource?.size)}</td>
                  <td className='text-start min-w-100px'>
                    {resource.suffix_type === 'FOLDER' ? (
                      <div>-</div>
                    ) : isSearchAll ? (
                      <SappTooltip title={resource?.location as string} max_length={20} />
                    ) : (
                      <DatetimeColumn
                        created_at={resource?.created_at}
                        updated_at={resource?.updated_at}
                      />
                    )}
                  </td>
                  {allowAction && (
                    <td className='text-end w-35px'>
                      <ResourceAction
                        resource={resource}
                        confirm={confirm}
                        deleteResource={deleteResource}
                        setResourceId={setResourceId}
                        setNewFolder={handleOpenForm}
                        handleEdit={handleEdit}
                        handleDownload={handleDownload}
                        handleMoveToFolder={handleMoveToFolder}
                        handleEditResourceVideo={handleEditResourceVideo}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={resourceList?.meta?.page_index || 1}
        pageSize={resourceList?.meta?.page_size || 10}
        totalItems={resourceList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
      <MoveToFolderModal
        handleCloseChoiceFolder={handleCloseChoiceFolder}
        handleSaveChoiceFolder={handleSaveChoiceFolder}
        openChoiceFolder={openChoiceFolder}
      ></MoveToFolderModal>
    </>
  )
}

export default TableResource
