import React, { Dispatch, SetStateAction, useEffect } from 'react'
import SappDrawer from 'src/components/base/SappDrawer'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { ACCEPT_UPLOAD_MIME_TYPE, DEFAULT_MAX_FILE_SIZE, VALIDATION_FIELD } from 'src/constants'
import thumnail_courseinfo from 'src/_metronic/assets/images/course/thumbnail_course.png'
import SappLabel from 'src/components/base/label/SappLabel'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import { ISponsor } from 'src/type/shop/sponsor'
import { ShopSponsorAPI } from 'src/apis/short-course/sponsor'
import toast from 'react-hot-toast'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useParams } from 'react-router-dom'

interface IProps {
  open: boolean
  type: 'edit' | 'create'
  data?: ISponsor
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  onCancel: () => void
  onClose: () => void
}

interface IForm {
  name: string
  avatar: any
  shop_event_id: string
}

const CreateAndUpdateSponsor = ({
  open,
  type,
  data,
  loading,
  setLoading,
  onCancel,
  onClose,
}: IProps) => {
  const { id } = useParams()
  /**
   * Validation cho các trường trong HookForm
   */
  const validationSchema = z.object({
    name: z.string().min(1, VALIDATION_FIELD),
    avatar: z.any(),
    shop_event_id: z.string().optional(),
  })

  /**
   * Hàm dùng dùng khi khởi tạo form mới hoặc cập nhật lại data của form
   */
  const handleClearData = () => {
    if (type === 'edit' && data) {
      setValue('name', data?.name)
      setValue('avatar', data?.avatar?.ORIGIN)
      setValue('shop_event_id', data?.shop_event_id)
    } else {
      reset()
    }
  }

  /**
   * Handle khởi tạo Form khi mở popup
   * @param{categoryData} - data của category nếu có
   * @param{open} - Để hanle việc clear Form khi form không có category data
   */
  useEffect(() => {
    handleClearData()
  }, [open])

  const useFormProp = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const { control, watch, handleSubmit, reset, setValue, setError } = useFormProp

  /**
   * Handle submit action of form - xử lý tạo mới hoặc cập nhật
   * @param {ISubmittedData} data  - Data của form
   */
  const onSubmit = async (sponsorData: IForm) => {
    try {
      setLoading(true)
      if (!id) return
      sponsorData.shop_event_id = id
      if (type === 'edit' && data) {
        await ShopSponsorAPI.updateSponsor({ id: data?.id, data: { name: sponsorData.name } })
        toast.success('Updated speaker successfully!')
      } else {
        const res = await ShopSponsorAPI.createSponsor({ data: sponsorData })
        if (sponsorData?.avatar && typeof sponsorData?.avatar !== 'string' && res.data.id) {
          await ShopSponsorAPI.uploadAvatar({ id: res.data.id, file: sponsorData.avatar })
        }
        toast.success('Created speaker successfully!')
      }
      reset()
      handleClearData()
      onClose()
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }
  const avatar = watch('avatar')

  return (
    <SappDrawer
      open={open}
      title='Add Sponsor'
      handleSubmit={handleSubmit(onSubmit)}
      width='50%'
      handleClose={onCancel}
      okButtonCaption='Save'
    >
      <SappLabel label='Avatar' />
      <div className='mb-10'>
        <SAPPHookUploadFile
          name='avatar'
          control={control}
          setValue={setValue}
          setError={setError}
          imagePreview={avatar ?? thumnail_courseinfo}
          accept={ACCEPT_UPLOAD_MIME_TYPE}
          maxFileSize={DEFAULT_MAX_FILE_SIZE}
          removeAvatar={thumnail_courseinfo}
          className='ms-1'
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField
          control={control}
          name='name'
          label='Name'
          required
          className='sapp-h-45px'
        />
      </div>
    </SappDrawer>
  )
}

export default CreateAndUpdateSponsor
