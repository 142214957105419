import { VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import { z } from 'zod'

export const topicValidationSchema = z.object({
  name: z
    .string({ required_error: VALIDATION_FIELD })
    .trim()
    .min(1, VALIDATION_FIELD)
    .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
  description: z.string().optional(),
  files: z.array(z.any().optional()).default([]),
})
