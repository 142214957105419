import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import SappTooltip from 'src/common/SappTooltip'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {IQuestionListItem, QuestionList} from 'src/type/shop/faqs'
import {INameAndId} from 'src/type/shop/common'

const headers = [
  {
    label: 'Question',
    className: 'min-w-200px',
  },
  {
    label: 'Ticket',
    className: 'min-w-300px',
  },
  {
    label: 'Category',
    className: 'min-w-100px',
  },
]

interface IProps {
  initValue?: INameAndId[]
  questionList?: QuestionList
  setQuestionList: Dispatch<SetStateAction<any>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  loadData: (page_index: number, page_size: number, object?: Object) => void
  getParams: (
    title: string,
    category_id: string,
    faq_id: string,
    sortType: string,
    fromDate?: Date | string,
    toDate?: Date | string
  ) => Object
  setDefaultChecked: (items: INameAndId[]) => void
}

const TableFAQs = ({
  initValue,
  questionList,
  setQuestionList,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  loadData,
  getParams,
  setDefaultChecked,
}: IProps) => {
  /**
   * Call API get Categories
   */
  useEffect(() => {
    setLoading(true)
    loadData(1, 10)
    if (initValue?.length) {
      setDefaultChecked(initValue)
    }
  }, [])

  /**
   * Call API khi change pagination
   * @param {number} page_index  - trang page hiện tại
   * @param {number} page_size  - lượng bản ghi của page
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    loadData(page_index, page_size)
  }

  return (
    <div className='card-body pt-7'>
      <SappTable
        headers={headers}
        loading={loading}
        data={questionList?.questions}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <LoadingTable headers={[...headers, 1]} />
        ) : (
          <>
            {questionList?.questions.map((data: IQuestionListItem, index: number) => {
              const isChecked = checkedList.includes(data.id)
              return (
                <tr key={data.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(data.id)
                      }}
                    />
                  </td>
                  <td className='text-start'>
                    <SappTooltip title={data.title} />
                  </td>
                  <td className='text-start'>{data.shop_faqs[0]?.title ?? '-'}</td>
                  <td className='text-start'>
                    {data.shop_faqs[0]?.shop_faq_category?.title ?? '-'}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={questionList?.meta?.page_index || 1}
        pageSize={questionList?.meta?.page_size || 10}
        totalItems={questionList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}
export default TableFAQs
