/* eslint-disable react/jsx-no-duplicate-props */
import { zodResolver } from '@hookform/resolvers/zod'
import { isUndefined } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers/components/KTIcon'
import { EntranceApi } from 'src/apis/entrance-test'
import PopupConfirm from 'src/common/PopupConfirm'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { LIST_TYPE } from 'src/components/courses/shared/Interfaces'
import { TYPE_OF_TEST } from 'src/components/courses/shared/consts'
import { IQuestion } from 'src/components/question-bank/shared/interfaces'
import {
  PageLink,
  VALIDATE_FIELD_MAX_LENGTH,
  VALIDATE_NUMBER,
  VALIDATE_PASS_NOTE,
  VALIDATE_PASS_POINT,
  VALIDATE_SUBJECT_MAX,
  VALIDATE_SUBJECT_MIN,
  VALIDATION_FIELD,
} from 'src/constants'
import { TITLE_OPTIONS_ENTRANCE } from 'src/constants/entrancetest'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IETest, Section } from 'src/type/entrancetest'
import { z } from 'zod'
import QuestionList from './QuestionList'
import MenuTabsSection from './create/MenuTabsSection'
import PopupConfirmCategory from './create/PopupCategory'
import ChooseQuestionsModal from './create/QuestionListComponent'
import InformationTab from './informationTab'

const ListSection = ({
  listInfo,
  setStep,
  step,
  handleGotoStep,
}: {
  listInfo: IETest | undefined
  setStep?: any
  step?: any
  handleGotoStep: (id: number) => void
}) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [categoryState, setCategorySate] = useState<any>()
  const [previousCategoryId, setPreviousCategoryId] = useState<any>()
  const {
    getValues,
    // setError,
    formState: { errors },
  } = useForm()
  const [sections, setSections] = useState<Section[]>([])
  const [openChooseQuestion, setOpenChooseQuestion] = useState<any>()
  const [listSectionSetting, setListSectionSetting] = useState<any[]>([])
  const [quizId, setQuizId] = useState<any>('')
  const [index, setIndex] = useState<number>(-1)
  const [categorySections, setCategorySections] = useState<[]>([])
  const [sectionQuestions, setSectionQuestions] = useState<IQuestion[]>([])
  const [editMode, setEditMode] = useState<boolean>(true)
  const [isAddSection, setAddSection] = useState<boolean>(true)
  // const [isLocked, setIsLocked] = useState<boolean>(false)
  // const [isActive, setIsActive] = useState<boolean>(false)
  const [isOnUsed, setIsOnUsed] = useState<boolean>(false)
  const [testInfo, setTestInfo] = useState<any>()
  const [categoryData, setCategoryData] = useState<any>('')
  const [detailProgram, setDetailProgram] = useState<any>()

  const validationInformationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, { message: VALIDATION_FIELD })
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
    course_category_id: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, { message: VALIDATION_FIELD }),
    subject: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
      .transform((val) => parseInt(val))
      .refine((val) => val >= 1, { message: VALIDATE_SUBJECT_MIN })
      .refine((val) => val <= 50, { message: VALIDATE_SUBJECT_MAX })
      .or(z.number().int(VALIDATE_NUMBER).min(1).max(50)),
    hour: z
      .string({ required_error: VALIDATION_FIELD })
      .refine(
        (val) => {
          if (!getValues('minutes')) {
            return true
          }
          return false
        },
        { message: VALIDATION_FIELD }
      )
      .optional(),
    minutes: z
      .string({ required_error: VALIDATION_FIELD })
      .transform((val) => parseInt(val))
      .refine(
        (val) => {
          if (!getValues('hour') && val < 0) {
            if (!val) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        },
        { message: VALIDATION_FIELD }
      )
      .optional(),
  })

  const validationSectionSchema = z.object({
    section: z.array(
      z
        .object({
          name: z
            .string({ required_error: VALIDATION_FIELD })
            .trim()
            .min(1, VALIDATION_FIELD)
            .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
          pass_point: z
            .string({ required_error: VALIDATION_FIELD })
            .regex(/^[0-9]*$/, VALIDATE_NUMBER)
            .transform((val) => parseInt(val))
            .refine((val) => val >= 1, { message: VALIDATE_PASS_POINT })
            .refine((val) => val <= 100, { message: VALIDATE_PASS_POINT })
            .or(z.number().int(VALIDATE_NUMBER).min(1).max(100)),
          pass_note: z
            .string({ required_error: VALIDATION_FIELD })
            .min(1, { message: VALIDATE_PASS_NOTE }),
          failure_note: z
            .string({ required_error: VALIDATION_FIELD })
            .min(1, { message: VALIDATE_PASS_NOTE }),
          question_category_id: z.string({ required_error: VALIDATION_FIELD }).min(1),
        })
        .optional()
    ),
  })

  const {
    handleSubmit: handleSubmitInformation,
    setValue: setValueInformation,
    setError,
  } = useForm({
    resolver: zodResolver(validationInformationSchema),
  })

  const {
    handleSubmit: handleSubmitSection,
    control: controlSection,
    setValue: setValueSection,
    getValues: getValue,
    reset: resetSection,
  } = useForm({
    resolver: zodResolver(validationSectionSchema),
  })
  const displayValidationErrors = (validationErrors: any) => {
    Object.keys(validationErrors).forEach((fieldName) => {
      const errors = validationErrors[fieldName]
      errors.forEach((error: any) => {
        toast.error(`${fieldName}: ${error}`)
      })
    })
  }
  const addQuestioned = useRef<{
    multiple: boolean
    constructed: boolean
  }>({ multiple: false, constructed: false })

  const fetchListSection = async (id: string) => {
    try {
      if (id) {
        const response = await EntranceApi.getListSection(id)
        setSections(response.data)
      }
    } catch (error) {}
  }
  const fetchListSectionSetting = async (id: string) => {
    try {
      if (id) {
        const response = await EntranceApi.getListSettingSection(id)
        setListSectionSetting(response.data)
      }
    } catch (error) {}
  }

  const fetchCategory = async (page_index: number, page_size: number) => {
    try {
      const response = await EntranceApi.getCategory({
        page_index: page_index,
        page_size: page_size,
      })
      // const _categoryTest = response.data
      const newData = response?.data?.course_categories || []
      setCategoryData((prevState: { course_categories: any; metadata: any }) => ({
        ...prevState,
        metadata: response?.data?.metadata || prevState.metadata,
        course_categories: prevState.course_categories
          ? [...prevState.course_categories, ...newData]
          : newData,
      }))
    } catch (error) {}
  }
  // const handleNextPage = (e: any) => {
  //   const total_pages = categoryData.metadata?.total_pages
  //   const page_index = categoryData.metadata?.page_index
  //   const page_size = categoryData.metadata?.page_size
  //   if (total_pages && page_index && page_index < total_pages) {
  //     fetchCategory(page_index + 1, page_size)
  //   }
  // }
  // const debouncedHandleNextPage = debounce(handleNextPage, 200)
  const fetchQuestionCategory = async () => {
    try {
      const response = await EntranceApi.getQuestionCategory({ page_index: 1, page_size: 10 })
      const _categorySections = response.data
      setCategorySections(_categorySections.data)
    } catch (error) {}
  }

  const onChangeCategory = (
    resetValue: () => void,
    value: any,
    isCreated: boolean,
    index: number
  ) => {
    const section = sections.find((_, idx: number) => idx === index)
    if (section?.questions && section?.questions?.length > 0 && isCreated) {
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body: ['Danh sách câu hỏi đã chọn sẽ bị xóa, bạn có chắc chắn muốn thay đổi không?'],
        onClick: async () => {
          setCategorySate(value)
          setSectionQuestions([])
          setSections((prev) => {
            prev[index].questions = []
            return prev
          })
        },
        onClose: async () => {
          resetValue()
        },
      })
    } else {
      setCategorySate(value)
    }
  }
  const newCategory = useMemo(
    () =>
      categoryData?.course_categories?.map((category: any) => ({
        label: category.name,
        value: category.id,
      })),
    [categoryData]
  )

  // const [listInfo, setListInfo] = useState<IETest>()

  const fetchDataEdit = async (ids: string) => {
    const response = await EntranceApi.editEntranceTest(ids)
    const data = response.data
    const totalMinutes = parseInt(data.quiz_timed, 10)
    const hours = Math.floor(totalMinutes / 60)
    const mins = totalMinutes % 60
    handleInformationForm({
      name: data.name,
      courseCategoryId: data?.course_category?.name || data.course_category_id,
      subject: data.subject,
      hour: hours.toString(),
      minutes: mins.toString(),
    })
    setTestInfo({
      name: data.name,
      courseCategoryId: data?.course_category?.name || data.course_category_id,
      subject: data.subject,
      hour: hours.toString(),
      minutes: mins.toString(),
    })
    setDetailProgram(data.course_category)
  }
  const { id: ids } = useParams()

  useEffect(() => {
    if (ids && !isUndefined(listInfo)) {
      const fetchData = async (ids: string) => {
        // const response = await EntranceApi.editEntranceTest(ids)
        // const data = response.data
        // setListInfo(data)

        setIsOnUsed(listInfo?.is_quiz_used)
        // setIsActive(listInfo.status === 'ACTIVE')
        const totalMinutes = parseInt(listInfo.quiz_timed, 10)
        const hours = Math.floor(totalMinutes / 60)
        const mins = totalMinutes % 60
        handleInformationForm({
          name: listInfo.name,
          courseCategoryId: listInfo?.course_category?.name || listInfo.course_category_id,
          subject: listInfo.subject,
          hour: hours.toString(),
          minutes: mins.toString(),
        })
        setTestInfo({
          name: listInfo.name,
          courseCategoryId: listInfo?.course_category?.name || listInfo.course_category_id,
          subject: listInfo.subject,
          hour: hours.toString(),
          minutes: mins.toString(),
        })
        setDetailProgram(listInfo.course_category)
      }
      fetchData(ids)
      fetchListSection(ids)
      fetchListSectionSetting(ids)
      setEditMode(false)
    } else {
      fetchListSectionSetting(ids as any)
      fetchListSection(ids as any)
    }
  }, [ids, listInfo])

  useEffect(() => {
    fetchCategory(1, 10)
    fetchQuestionCategory()
    setQuizId(ids)
  }, [ids])
  const [openPopupChageSubject, setOpenPopupChageSubject] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const handleChooseQuestions = (type: LIST_TYPE, sectionId: any) => {
    if (categoryState === '' || undefined) {
      setOpen(true)
      return
    }
    if (getValues('type') === TYPE_OF_TEST.mix.value) {
      if (type === 'multiple' && !Number(getValues('number_of_multiple_choice_questions'))) {
        setError('number_of_multiple_choice_questions', { message: VALIDATION_FIELD })
        addQuestioned.current = { ...addQuestioned.current, multiple: true }
        return
      }
      if (type === 'constructed' && !Number(getValues('number_of_essay_questions'))) {
        setError('number_of_essay_questions', { message: VALIDATION_FIELD })
        addQuestioned.current = { ...addQuestioned.current, constructed: true }
        return
      }
    }

    setOpenChooseQuestion({ status: true, type: type, sectionId: sectionId })
  }

  const [showLabel, setShowLabel] = useState(false)

  const handleToggleLabel = () => {
    setShowLabel(!showLabel)
  }
  const handleCancelUpdateSection = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: async () => {
        resetSection()
        setIndex(-1)
        await fetchListSection(ids as any)
        setIsEdit(!isEdit)
        setShowLabel(false)
      },
    })
  }

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditorNotPass, setFileEditorNotPass] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFilesNotPass =
    fileEditorNotPass?.map((data) => {
      return { id: data?.id }
    }) || []

  const submitSection = async (item: any) => {
    try {
      const data = sections.find((e, idx) => idx === index)
      const section = item
      const passPoints = Number(section.pass_point)
      const questionList = sectionQuestions.length > 0 ? sectionQuestions : data?.questions
      let reg = {
        name: section.name,
        question_category_id: section.question_category_id,
        failure_note: section.failure_note,
        pass_note: section.pass_note,
        pass_point: passPoints,
        quiz_id: ids,
        text_editor_files: textEditorFiles.concat(textEditorFilesNotPass),
      }
      const request = {
        ...reg,
        questions: questionList ? questionList.map((item) => ({ id: item.id })) : [],
      }

      setLoading(true)
      if (!!data?.id) {
        const res = await EntranceApi.EditSection(data.id, { data: request })
        setIndex(-1)
        setIsEdit(false)
        if (res) {
          fetchListSection(ids as any)
          fetchListSectionSetting(ids as any)
          toast.success('Test saved successfully!')
          setLoading(false)
          setIsEdit(false)
        } else {
          toast.error('Test saved failed!')
        }
      } else {
        const res = await EntranceApi.addSectionEntranceTest({ data: request })
        if (res) {
          toast.success('Test saved successfully!')
          fetchListSection(ids as any)
          fetchListSectionSetting(ids as any)
          setIsEdit(false)
          setFileEditor([])
          setFileEditorNotPass([])
        } else {
          toast.error('Test saved failed!')
          fetchListSection(ids as any)
          fetchListSectionSetting(ids as any)
        }
        setIndex(-1)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    const hour = isNaN(data.hour) ? 0 : Number(data.hour || 0)
    const minutes = isNaN(data.minutes) ? 0 : Number(data.minutes || 0)

    if (!data.hour && !data.minutes) {
      setError('minutes', { message: VALIDATION_FIELD })
      setLoading(false)
      return
    }
    const subject =
      data.subject !== undefined && data.subject !== null ? data.subject.toString() : ''
    const totalMinutes = hour * 60 + minutes
    const getCourseCategoryId =
      detailProgram?.name.trim() === data?.course_category_id.trim()
        ? detailProgram?.id
        : data?.course_category_id
    let request: any = {
      name: data.name,
      course_category_id: getCourseCategoryId,
      subject: subject,
      quiz_timed: totalMinutes,
      description: '',
      quiz_question_mode: 'ALL',
      is_published: true,
      is_public: true,
      password: '',
      is_graded: true,
      grading_method: 'AUTO',
      multiple_choice_multiplier: 100,
      essay_multiplier: 0,
      required_percent_score: 0,
      minimum_score: 0,
      is_limited: true,
      limit_count: 1,
      is_tutor: false,
      setting: {},
      quiz_question_type: 'MULTIPLE_CHOICE',
      quiz_type: 'ENTRANCE_TEST',
      grading_preference: 'AFTER_ALL_QUESTIONS',
      quiz_for: 'ENTRANCE_TEST',
    }
    try {
      if (editMode && !ids) {
        const res = await EntranceApi.createEntranceTest({ data: { ...request } })
        if (res) {
          setEditMode(false)
          navigate(`/entrance-test/list-entrance-test/edit/${res.data.id}`)
          setQuizId(res.data.id ?? '')
          toast.success('Test created successfully!')
        }
      } else if (editMode && ids) {
        const updatedRequest = { ...request }
        delete updatedRequest.status
        const res = await EntranceApi.updateEntranceTest(ids, { data: { ...updatedRequest } })
        if (res) {
          navigate(`/entrance-test/list-entrance-test/edit/${res.data.id}`)
          setQuizId(res.data.id ?? '')
          setEditMode(false)
          fetchDataEdit(ids)
          toast.success('Test saved successfully!')
        }
      }
    } catch (error: any) {
      displayValidationErrors(error.errors)
    } finally {
      setLoading(false)
    }
  }
  const createNewSection = (): Section => ({
    questions: [],
    course_category_id: '',
    name: '',
    pass_note: '',
    failure_note: '',
    pass_point: '',
    question_category_id: '',
  })

  const handleAddNewSection = () => {
    const newSection = createNewSection()
    setIndex(sections.length)
    onSetQuestions([])
    setSections((prevSections) => {
      const newSections = [...prevSections, newSection]
      const length = newSections.length
      setIndex(length - 1)
      return newSections
    })
  }

  const HandleEditSections = (indexSection: any) => {
    setIndex(indexSection)
  }
  const onSetQuestions = (data: any) => {
    const _sections = sections.map((e, idx) => {
      if (idx === index) {
        return { ...e, questions: data }
      }
      return e
    })
    setSections(_sections)
    setSectionQuestions(data)
    setPreviousCategoryId(categoryState)
  }
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const onEditSection = (e: number) => {
    setIsEdit(true)
    setIndex(e)
  }
  // const onEditSetting = (e: number) => {
  //   setIsEdit(true)
  //   setIndexSetting(e)
  // }
  const handleDeleteSection = async (id: any) => {
    try {
      const response = await EntranceApi.DeleteSection(id)
      if (response.success) {
        toast.success('Successfully deleted section')
        setSections(sections.filter((e) => e.id !== id))
        resetSection()
        fetchListSectionSetting(quizId)
        setAddSection(true)
        setIsEdit(false)
      }
    } catch (error) {
      console.error('Error handling delete section:', error)
    }
  }

  const handleInformationForm = (data: any) => {
    setValueInformation('name', data?.name)
    setValueInformation('course_category_id', data?.courseCategoryId)
    setValueInformation('subject', data?.subject)
    setValueInformation('hour', data?.hour)
    setValueInformation('minutes', data?.minutes)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(`${PageLink.ENTRANCE_TEST_LIST}`)
      },
    })
  }

  return (
    <>
      <Processbar
        step={step}
        setNewStep={setStep}
        onCancel={handleCancel}
        onClick={() => handleGotoStep(2)}
        cancelButtonCaption={TITLE_OPTIONS_ENTRANCE.listEntrance}
        className='sapp-mw-700px'
      />
      <div className='px-6'>
        {contextHolder}

        {sections.map((section, idx) => {
          return (
            <div key={section.id} className='border-solid mt-2'>
              <Row>
                <Col md={12} className='mb-8'>
                  <div className='rounded'>
                    <MenuTabsSection
                      index={idx}
                      toggleLabelCallback={handleToggleLabel}
                      confirm={confirm}
                      typeofSection={section.name}
                      SectionName={section?.question_category?.name}
                      onDeleteSection={handleDeleteSection}
                      setEditAble={onEditSection}
                      isEdit={index !== idx}
                      id={sections[idx]?.id}
                      isOnUsed={isOnUsed}
                      setAddSection={setAddSection}
                      setCategorySate={setCategorySate}
                      categoryState={sections[idx]?.question_category?.id}
                      isQuestion={sections[idx]?.questions?.length > 0}
                      setPopup={setOpenPopup}
                      section={sections[idx]}
                      setValue={setValueSection}
                      setPreviousCategoryId={setPreviousCategoryId}
                      postionIndex={(sections?.length || 0) - 1}
                      deleteSection={listInfo?.state?.condition?.can_delete_entrance_test_sections}
                      tabs={[
                        {
                          id: 'tab1',
                          title: 'Section Info',
                          content: (
                            <InformationTab
                              error={errors[''] as any}
                              control={controlSection}
                              index={idx}
                              postionIndex={index}
                              categorySections={categorySections}
                              section={sections[idx]}
                              setValue={setValueSection}
                              getValue={getValue}
                              onChangeCategory={onChangeCategory}
                              setFileEditor={setFileEditor}
                              setFileEditorNotPass={setFileEditorNotPass}
                              id={sections[idx]?.id}
                              listInfo={listInfo?.state?.condition}
                            ></InformationTab>
                          ),
                        },
                        {
                          id: 'tab2',
                          title: 'List Question',
                          content: (
                            <QuestionList
                              maxQuestionsNumber={'NO_MAX'}
                              error={errors[''] as any}
                              questions={section?.questions}
                              setQuestions={(data) => onSetQuestions(data)}
                              handleChooseQuestions={() => handleChooseQuestions('all', section.id)}
                              confirm={confirm}
                              categoryState={categoryState}
                              section={sections[idx]}
                              isOnUsed={isOnUsed}
                              isEdit={isEdit}
                              sectionDisable={index !== idx}
                              index={idx}
                              previousCategoryId={previousCategoryId ?? ''}
                              setOpenPopup={setOpenPopup}
                              listInfo={listInfo?.state?.condition}
                            ></QuestionList>
                          ),
                        },
                      ]}
                      loading={loading}
                    >
                      {index === idx && (
                        <div
                          className='d-flex border-0 justify-content-end card-footer sapp-pt-24px'
                          style={{ marginLeft: '10px' }}
                        >
                          {index === idx && (
                            <SAPPDialogButtonsCancelSubmit
                              type='button'
                              cancelClick={handleCancelUpdateSection}
                              cancelButtonCaption='Cancel'
                              okButtonCaption='Save'
                              okOnClick={handleSubmitSection((e) => {
                                submitSection(e.section[idx])
                              })}
                              className='justify-content-between d-flex'
                              loading={loading}
                            />
                          )}
                        </div>
                      )}
                    </MenuTabsSection>
                  </div>
                </Col>
              </Row>
            </div>
          )
        })}

        {isAddSection &&
          ids &&
          !isEdit &&
          !isOnUsed &&
          listInfo?.state?.condition?.can_add_entrance_test_sections && (
            <div className='sapp-pb-24px'>
              <ButtonIcon
                title='Add New Sections'
                className='sapp-h-45px d-flex justify-content-start align-items-center w-100'
                customButton='btn-outline sapp-text--label p-0 position-relative sapp-fs-14 fw-semibold'
                type='button'
                onClick={() => {
                  handleAddNewSection()
                  HandleEditSections(index)
                  setIsEdit(true)
                  setCategorySate('')
                  setPreviousCategoryId('')
                }}
                // isHoverCustom={false}
              >
                <KTIcon iconName='plus' className='fs-4 sapp-text--label ps-5 pe-1' />
              </ButtonIcon>
            </div>
          )}

        <ChooseQuestionsModal
          questions={sections[index]?.questions || []}
          maxQuestionsNumber={'NO_MAX'}
          setQuestions={onSetQuestions}
          open={openChooseQuestion?.status}
          setOpen={setOpenChooseQuestion}
          type={openChooseQuestion?.type as LIST_TYPE | boolean}
          editing={openChooseQuestion?.sectionId}
          categoryState={categoryState}
          quizId={quizId}
        />

        <PopupConfirmCategory
          okButtonCaption='Yes'
          cancelButtonCaption='No'
          open={open}
          setOpen={setOpen}
          onClick={() => {
            setOpen(false)
          }}
          body='Bạn cần lựa chọn Question category trước khi thêm câu hỏi vào Entrance Test'
        />

        <PopupConfirmCategory
          okButtonCaption='Yes'
          cancelButtonCaption='No'
          open={openPopup}
          setOpen={setOpenPopup}
          onClick={() => {
            setOpenPopup(false)
          }}
          body='Bạn cần xóa các câu hỏi trước khi thay đổi lựa chọn Question Category.'
        />

        <PopupConfirm
          okButtonCaption='Yes'
          cancelButtonCaption='No'
          open={openPopupChageSubject}
          setOpen={setOpenPopupChageSubject}
          onClick={() => {
            handleSubmitInformation((e) => {
              onSubmit(e)
              setAddSection(true)
            })()
            setOpenPopupChageSubject(false)
          }}
          onClose={async () => {
            ids && (await fetchListSection(ids))
            testInfo && handleInformationForm(testInfo)
          }}
          body='Please confirm that all Entrance tests having the same program will be deactivated'
        />
      </div>
    </>
  )
}

export default ListSection
