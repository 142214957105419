/* eslint-disable @typescript-eslint/no-unused-vars */
import { Select } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { ShopCategoryAPI } from 'src/apis/short-course/blogs/category'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import SAPPListGroup from 'src/common/SAPPListGroup'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import NewCategory from 'src/components/shop/category/NewCategory'
import TableCategory from 'src/components/shop/category/TableCategory'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { ITabs } from 'src/type'
import { IBlogCategoryList } from 'src/type/shop/blog'
import { INewOrUpdatePopup } from 'src/type/shop/common'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: '',
    title: 'Shop',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Blog',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Categories',
  },
]

const fieldNames = ['name', 'fromDate', 'toDate', 'sort_type']
const initialValues: any = { name: '', fromDate: '', toDate: '', sort_type: '' }

const ListCategories = () => {
  // Trạng thái để kiểm soát việc hiển thị hoặc ẩn form tạo mới category
  const [openNewOrUpdate, setOpenNewOrUpdate] = useState<INewOrUpdatePopup>({
    isOpen: false,
    data: null,
    type: 'create',
  })
  // Hook confirm để xác nhận các hành động của người dùng
  const { confirm, contextHolder } = useConfirm()
  // Trạng thái để lưu danh sách các category của blog
  const [blogCategories, setBlogCategories] = useState<IBlogCategoryList>()
  // Trạng thái để kiểm soát việc hiển thị biểu tượng loading khi tải dữ liệu
  const [loading, setLoading] = useState(false)
  // Hook useLocation để lấy thông tin về query params từ URL
  const { search } = useLocation()
  // Hook useNavigate để điều hướng trang khi cần thiết
  const navigate = useNavigate()
  // Trạng thái để kiểm soát việc hiển thị hoặc ẩn popup xác nhận xóa
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  // Hook useChecked để quản lý trạng thái của checkbox trong danh sách category
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked(
    blogCategories?.blogCategories
  )
  // Lấy thông tin query params từ URL
  const searchParams = new URLSearchParams(search)
  // Tạo object queryParams từ query params để sử dụng trong trang
  const queryParams = {
    name: searchParams.get('name') ?? '',
    sort_type: searchParams.get('sort_type') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }
  const location = useLocation()

  // Hàm để tạo object chứa các tham số cần thiết cho việc gọi API
  const getParams = (
    name: string,
    sort_type: string,
    fromDate: any,
    toDate: any,
    dateField: string
  ) => ({
    name,
    sort_type,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })

  // Hook useForm để quản lý trạng thái và xác thực của các input trong form
  const { control, getValues, reset, setValue } = useForm()

  // Hàm để xử lý hành động hủy bỏ
  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => setOpenNewOrUpdate({ isOpen: false, data: null, type: 'create' }),
    })
  }

  // Kiểm tra xem có thông tin từ ngày và đến ngày không
  const checkFromDateToDate = queryParams.name || queryParams.fromDate || queryParams.toDate

  // Hàm để lấy dữ liệu category từ API
  const fetchShopCategory = async (currentPage: number, pageSize: number, params?: Object) => {
    setLoading(true)
    try {
      const res = await ShopCategoryAPI.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setBlogCategories(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  /**
   * Xử lý thay đổi các tham số trên URL khi thực hiện phân trang hoặc tìm kiếm.
   * @param {number} currentPage - Trang hiện tại.
   * @param {number} size - Kích thước trang.
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name') ?? '',
      sort_type: getValues('sort_type') ?? '',
      fromDate: formatDate(getValues('lastRegistry')?.fromDate) ?? '',
      toDate: formatDate(getValues('lastRegistry')?.toDate) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  // Hàm để xử lý khi gửi form
  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('lastRegistry')?.fromDate)
    const dateInfoToDate = getDateInfo(getValues('lastRegistry')?.toDate)
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name')?.trimStart()?.trimEnd() ?? '',
        getValues('sort_type'),
        getValues('lastRegistry')?.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('lastRegistry')?.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )

    setLoading(true)

    fetchShopCategory(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
    toggleCheckAll(false)
  }

  //biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.sort_type ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      checkFromDateToDate ? 'updated_at' : 'created_at'
    )
  )

  // Hàm này để reset filter
  const handleReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    toggleCheckAll(false)
    setLoading(true)
    navigate(PageLink.BLOG_CATEGORIES)
    fetchShopCategory(1, 10)
  }

  // Hàm để xóa nhiều categories
  const deleteCategories = async (id: string[]) => {
    const res = await ShopCategoryAPI.deleteCategories(id)
    if (res.success) {
      fetchShopCategory(queryParams.page_index || 1, queryParams.page_size || 10, filterParams)
      toast.success('Category deleted successfully!')
    }
    setOpenPopupDelete(false)
    toggleCheckAll(false)
  }

  // Hiệu ứng side effect lấy data từ params
  useEffect(() => {
    setValue('lastRegistry', {
      fromDate: queryParams?.fromDate ? dayjs(queryParams?.fromDate)?.toDate() : null,
      toDate: queryParams?.toDate ? dayjs(queryParams.toDate)?.toDate() : null,
    })
  }, [setValue, queryParams])

  const fetchPage = (refresh: boolean = true) => {
    const params = new URLSearchParams(location.search)
    const page_index = params.get('page_index') && refresh ? Number(params.get('page_index')) : 1
    const page_size = params.get('page_size') && refresh ? Number(params.get('page_size')) : 10
    const name = params.get('name')
    fetchShopCategory(page_index, page_size, { name })
  }

  return (
    <PageLayouts pageTitle='Categories' breadcrumbs={breadcrumbs}>
      {contextHolder}
      <div className='card'>
        {/* start:: filter */}
        <div className='px-10 border-0 pt-10'>
          <ListFilterLayout>
            {/* Category */}
            <div className='card-title justify-content-center mb-0 mx-0'>
              <HookFormTextField
                control={control}
                name='name'
                placeholder='Search category'
                isListScreen
                defaultValue={queryParams?.name}
              />
            </div>

            {/* Sort By */}
            <HookFormSelectAntd
              control={control}
              name='sort_type'
              placeholder='Sort By'
              showSearch
              classNameHeight='sapp-h-40'
              defaultValue={queryParams?.sort_type}
            >
              {FILTER_SELECTALL_SORTBY.map((sortType) => (
                <Option key={sortType.value} value={sortType.value}>
                  {sortType.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            {/* Date */}
            <RangeDateTimePicker
              control={control}
              name='lastRegistry'
              allowClear={true}
              placeholder={['From date', 'To date']}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-6 px-10'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleReset}
              disabled={loading}
              loading={loading}
            />
          </div>

          <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
            <div className='card-toolbar justify-content-end d-flex my-0 gap-2'>
              {/* start:: button open modal */}
              {checkedList.length > 0 && (
                <SAPPListGroup
                  selected={checkedList}
                  deleteItems={() => deleteCategories(checkedList)}
                  openPopup={openPopupDelete}
                  setOpenPopup={setOpenPopupDelete}
                  title='Delete Selected'
                  body='Bạn có chắc chắn muốn xóa không?'
                  okButtonCaption='Yes'
                  cancelButtonCaption='No'
                />
              )}
              <ButtonIcon
                title='New Category'
                onClick={() => setOpenNewOrUpdate({ isOpen: true, data: null, type: 'create' })}
                isListScreen
              >
                <KTIcon iconName='plus' className='fs-2' />
              </ButtonIcon>

              {/* end:: button open modal */}
            </div>
          </div>
        </div>

        {/* end:: filter */}
        <NewCategory
          setLoading={setLoading}
          setOpenNewCategory={setOpenNewOrUpdate}
          reloadPage={fetchPage}
          open={openNewOrUpdate?.isOpen}
          handleClose={handleClose}
          categoryData={openNewOrUpdate.data}
          type={openNewOrUpdate.type}
        />
        {/* start:: table */}
        <TableCategory
          loading={loading}
          setLoading={setLoading}
          blogCategories={blogCategories}
          fetchShopCategory={fetchShopCategory}
          queryParams={queryParams}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
          filterParams={filterParams}
          confirm={confirm}
          reloadPage={fetchPage}
          setOpenPopupDelete={setOpenPopupDelete}
          setOpenNewOrUpdate={setOpenNewOrUpdate}
        />
        {/* end:: table */}
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ListCategories)
