import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import './HookFormRadioGroup.scss'
import { Skeleton } from 'antd'

interface IHookFormRadioGroupProps {
  name: string
  control: Control<any>
  defaultValue?: unknown
  options: Array<{ label?: string; value: string | boolean; description?: string }>
  direction?: 'horizontal' | 'vertical'
  separator?: boolean
  justify?: 'between' | 'start' | 'center' | 'end'
  gap?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  labelClass?: string
  labelClassChecked?: string
  disabled?: boolean
  itemWidth?: any
  className?: string
  loading?: boolean
  classNameForm?: string
  isShowInput?: boolean
}

const HookFormRadioGroup = ({
  name,
  control,
  defaultValue,
  options,
  direction,
  separator,
  gap,
  onChange,
  justify = 'between',
  labelClass = '',
  labelClassChecked = '',
  disabled,
  itemWidth,
  className,
  loading,
}: IHookFormRadioGroupProps) => {
  const count_items = options?.length - 1
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {loading ? (
              <Skeleton.Button active block className='sapp-h-45px' />
            ) : (
              <div
                className={
                  'sapp-hook-form-radio-group ' +
                  (direction === 'horizontal'
                    ? `_horizontal d-flex justify-content-${justify} flex-wrap ${
                        gap ? 'gap-' + gap : ''
                      }`
                    : `_vertical d-flex flex-column ${gap ? 'gap-' + gap : ''}`)
                }
              >
                {options.map((option, index) => (
                  <div
                    key={option.label || index}
                    style={{ width: itemWidth, minWidth: 'fit-content' }}
                  >
                    <div className='d-flex fv-row'>
                      <label className='form-check form-check-solid sapp-form-check-custom ps-9'>
                        <input
                          {...field}
                          disabled={disabled}
                          onChange={(e) => {
                            onChange && onChange(e)
                            field.onChange(e.target.value)
                          }}
                          className={`form-check-input bg-gray-300 me-2 ${className ?? ''}`}
                          type='radio'
                          key={option.label}
                          value={option.value.toString()}
                          checked={field.value === option.value.toString()}
                        />
                        <span className='form-check-label ps-1 d-block'>
                          <div
                            className={`${
                              option.value === field.value
                                ? `sapp-text-primary ${
                                    labelClassChecked ? labelClassChecked : 'fw-semibold fs-6'
                                  }`
                                : `sapp-text-primary ${
                                    labelClass ? labelClass : 'fw-semibold fs-6'
                                  }`
                            }`}
                          >
                            {option.label}
                          </div>
                          {option.description && (
                            <div className='text-gray-500'>{option.description}</div>
                          )}
                        </span>
                      </label>
                    </div>
                    {index !== count_items && (separator === undefined || separator === true) && (
                      <div className='separator separator-dashed my-5'></div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        )
      }}
    />
  )
}

export default HookFormRadioGroup
